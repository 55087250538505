import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';//importar las variables globales del usuario

function RouterView(props) {
    const { RutaHead} = props;
    //REDUX
    const user = useSelector((state) => state.user);
    return (
        <div className='p-3 din-nav-router'>
            <nav className="custom-breadcrumb" aria-label="breadcrumb"> {/* Aplica la clase de estilo */}
                <ol className="breadcrumb" style={{fontSize:'15px'}}>
                    <li className="breadcrumb-item din-route-link">
                        <i className="fa-solid fa-house"></i>&nbsp;&nbsp;
                        {user.idRol === 2 ?
                            <a href="/#MovimientosList">INICIO</a>
                        :
                            <a href="/#AlquileresList">INICIO</a>
                        }
                    </li>
                    {RutaHead.map((item, index) => (
                        <li key={index} className={`breadcrumb-item din-route-link ${index === RutaHead.length-1  ? 'active' : ''}`} aria-current="page">
                            {index === RutaHead.length - 1 ? (
                                item.name.toUpperCase()
                            ) : (
                                <Link to={item.ruta} style={{textDecoration:'none'}}>
                                    {item.name.toUpperCase()}
                                </Link>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );
}

export default RouterView