import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, forwardRef } from 'react';
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { DetalleModal } from '../../componentes/detalle/DetalleModal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function ListadoView(props) {
  const { url, cabeceraTable, cuerpoTable, tabla, idprimary, detalle,nombre_controller_modal,idprimaryModal } = props;
  // Filtro de busqueda
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({});
  const filteredItems = cuerpoTable.filter((item) => {
    return cabeceraTable.some((header) => {
      const value = item[header.bdname];
      const searchTermLower = searchTerm.toLowerCase();
  
      // Verifica si hay una opción seleccionada en el ComboBox
      if (Object.keys(filterOptions).length > 0) {
        return Object.keys(filterOptions).every((fieldName) => {
          const selectedValue = filterOptions[fieldName];
          const itemValue = item[fieldName];
          return (
            selectedValue === "" || selectedValue === itemValue
          );
        }) && value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTermLower);
      } else {
        return (
          value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTermLower)
        );
      }
    });
  });
  // Cantidad de registros
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  // Paginacion
  const [currentPage, setCurrentPage] = useState(1);

  // Calcula el índice de inicio y fin para mostrar los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredItems.slice(startIndex, endIndex);
  // Calcula la cantidad total de páginas
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  // Maneja el cambio de página
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  // Exportar a Excel
  const handleExportToExcel = () => {
    // Crear un array de objetos que contenga los datos que deseas exportar
    const dataToExport = currentItems.map((item) => {
      const rowData = cabeceraTable.map((header) => item[header.bdname]);
      return rowData;
    });
    // Crear una hoja de cálculo de Excel
    const ws = XLSX.utils.aoa_to_sheet([cabeceraTable.map((header) => header.name), ...dataToExport]);
    // Crear un libro de Excel
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tabla');
    // Guardar el archivo Excel
    const excelFileName = `${tabla}Report.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };
  // ====================== MODAL ELIMINAR ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const handleShow = (itemId) => {
    setSelectedItemId(itemId);
    setShow(true);
  };
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#81D075');
  // ====================== ELIMINADOS ======================
  const [deletedItems, setDeletedItems] = useState(new Set());
  // Función de eliminar
  const FnEliminar = () => {
    handleClose();
    return fetch(`${url}/${props.tabla}/${props.tabla}Delete/${selectedItemId}`, {
      method: 'POST',
    })
    .then(function (result) {   // Respuesta 
        if (result.ok) {
            return result.json();   // Retorno tipo json
        }
    })
    .then(function (data) {
      console.log(`tr_${selectedItemId}`)
        if(data.res === "200"){
            setToastBackground('#81D075');
            setToastMessage(data.msg);
            setShowToast(true);
            // Después de eliminar en la base de datos, agrega el ID al conjunto de elementos eliminados
            setDeletedItems((prevDeletedItems) => {
              const newDeletedItems = new Set(prevDeletedItems);
              newDeletedItems.add(`tr_${selectedItemId}`);
              return newDeletedItems;
            });
        }else{
            setToastBackground('#CC6868');
            setToastMessage(data.msg);
            setShowToast(true);
        }
    })
    .catch(function (error) {
        console.log(error);
        throw error;
    });
  };
   // ====================== MODAL DETALLE ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  // const handleShow = () => setShow(true);
  const [selectedIdDetalle, setSelectedIdDetalle] = useState(null);
  const [dataDetalle, setDataDetalle] = useState([]);
  const handleShowDetalle = (itemId) => {
    setSelectedIdDetalle(itemId);
    setShowDetalle(true);
    ListarDatosDetalle(url,tabla,itemId)
      .then(function (data) {
          setDataDetalle(data);
      })
      .catch(function (error) {
          console.log(error);
      });
    ListarDatosModal(url, nombre_controller_modal, itemId)
    .then(function (data) {
        setData(data);
        setLoading(false);
    })
    .catch(function (error) {
        console.log(error);
        setLoading(false);
    });
  };
  // ====================== MODAL ======================
  const cabeceraTableModal = [
      { name: '#', bdname: 'idmarco', ancho: '4%', align:'center'},       // <<<<<< CAMBIO
      { name: 'Marco', bdname: 'marconom', ancho: ''},                    // <<<<<< CAMBIO
      { name: 'Descripción', bdname: 'marcodesc', ancho: ''},             // <<<<<< CAMBIO
      { name: 'Beneficio', bdname: 'marcoben', ancho: ''},                // <<<<<< CAMBIO
      { name: 'Última Actualización', bdname: 'fechact', ancho: '14%'}    // <<<<<< CAMBIO
  ];
  // Constantes
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
          <div style={{ width: '100%' }}>
            <div className='row mb-2'>
              <div className='col-12'>
                <div className='row p-1 d-flex justify-content-end'>
                  <div className='col-12 custom-col'>
                    <label></label>
                    <input
                      type="text"
                      placeholder="Buscar..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className='form-control w-100'
                      style={{paddingRight:'0'}}
                    />
                  </div>
                  {cabeceraTable.map((header, index) => (
                    header.filtro === 'true' ? (
                      <div className="col-12 custom-col" key={index}>
                        <label></label>
                        <select
                          key={index}
                          aria-label="Default select example"
                          className="form-select"
                          value={filterOptions[header.bdname] || ""}
                          onChange={(e) => {
                            setFilterOptions((prevFilterOptions) => ({
                              ...prevFilterOptions,
                              [header.bdname]: e.target.value
                            }));
                          }}
                        >
                          <option value="">- {header.name} -</option>
                          {[...new Set(cuerpoTable.map((item) => item[header.bdname]))].map((value, optionIndex) => (
                            <option key={optionIndex} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null
                  ))}
                  <div className='col-12 custom-col'>
                    <label><small>Fecha Inicio</small></label>
                    <input type="date" className='form-control w-100' style={{paddingRight:'0'}}/>
                  </div>
                  <div className='col-12 custom-col'>
                    <label><small>Fecha Fin</small></label>
                    <input type="date" className='form-control w-100' style={{paddingRight:'0'}}/>
                  </div>
                </div>
              </div>
            </div>
            <div className='row '>
              <div className='col-12'>
                <div className='row p-1 d-flex justify-content-end'>
                  <div className='col-12 custom-col'>
                    <button className="btn btn-success din-btn-style w-100" ><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>{' '}
                  </div>
                  <div className='col-12 custom-col'>
                    <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                  </div>
                  <div className='col-12 custom-col'>
                    <Link to={`/${tabla}/${tabla}Add`}><button className="btn btn-primary din-btn-style w-100"><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Nuevo</button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-12'>
                <div className='row d-flex justify-content-end'>
                  <div className='col-12 custom-col-pagination'>
                    <div className='m-1'>
                      {/* <label><small>Cantidad</small></label> */}
                      <select className="form-select" aria-label="Default select example" value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                          <option value={10}>10 registros</option>
                          <option value={25}>25 registros</option>
                          <option value={50}>50 registros</option>
                          <option value={100}>100 registros</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-12 custom-col-pagination'>
                    <div className='m-1'>
                      {/* <label>&nbsp;</label> */}
                      <Pagination className='justify-content-end'>
                        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        {totalPages === currentPage && totalPages > 2 &&(
                          <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                            {currentPage - 2}
                          </Pagination.Item>
                        )}
                        {currentPage > 1 &&(
                          <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                            {currentPage - 1}
                          </Pagination.Item>
                        )}
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        {currentPage < totalPages && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                            {currentPage + 1}
                          </Pagination.Item>
                        )}
                        {currentPage < 2 && totalPages > 2 && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                            {currentPage + 2}
                          </Pagination.Item>
                        )}
                        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-1">
              <div className='col-12'>
                <table className="table table-striped table-bordered dt-responsive din-table-1" style={{ marginTop: '5px' }} id="table_id">
                  <thead>
                    <tr>
                      <th> </th>
                      {cabeceraTable.map((item, index) => (
                        <th key={index}>
                          {item.name.toUpperCase()}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.filter((item) => !deletedItems.has(`tr_${item[idprimary]}`))
                      .map((item, index) => (
                        <tr key={index} id={`tr_${item[idprimary]}`}>
                          <td>
                            <MyDropdown tabla={props.tabla} item={item} idprimary={idprimary} handleShow={handleShow} handleShowDetalle={handleShowDetalle}/>
                          </td>
                          {cabeceraTable.map((header, headerIndex) => (
                            <td key={headerIndex}>
                              {item[header.bdname] !== null ? item[header.bdname] : item[header.bdname2]}
                            </td>
                          ))}
                        </tr>
                        ))
                      ) : (
                      <tr>
                        <td className='text-center' colSpan={cabeceraTable.length + 1}>No hay registros en la base de datos.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <br/>
          </div>
          {/* Modal Eliminar*/}
          <>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header  className='bg-danger text-white' closeButton>
                <Modal.Title>Eliminación</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={handleClose}>
                  <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                </button>
                <button className="btn btn-danger din-btn-style" onClick={FnEliminar}>
                  <i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar
                </button>
              </Modal.Footer>
            </Modal>
          </>
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title >Detalle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="controlled-tab-example" defaultActiveKey="Datos Generales" className="mb-3" >
                <Tab eventKey="Datos Generales" title="Datos Generales">          {/* <<<<<< AGREGAR */}
                      {/* CARD BODY */}
                      <div className="card-body mx-2">
                          <div className="row">
                            {detalle.map((header, headerIndex) => (
                              <div key={headerIndex} className="col-12">
                                <div className='row'>
                                  <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                                    {header.name}:&nbsp;&nbsp;
                                  </div>
                                  <div className='col-7 col-sm-7 col-md-9'>
                                    {dataDetalle[header.bdname]}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                      </div>
                </Tab>
                <Tab eventKey="Datos 2" title="Datos 2">          {/* <<<<<< AGREGAR */}
                      {/* CARD BODY */}
                      <div className="card-body mx-2">
                          <div className="row">
                            {detalle.map((header, headerIndex) => (
                              <div key={headerIndex} className="col-12">
                                <div className='row'>
                                  <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                                    {header.name}:&nbsp;&nbsp;
                                  </div>
                                  <div className='col-7 col-sm-7 col-md-9'>
                                    {dataDetalle[header.bdname]}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                      </div>
                </Tab>
                <Tab eventKey="Marcos" title="Marcos">          {/* <<<<<< AGREGAR */}
                    {/* CARD BODY MODAL checklist */}
                    <div className="card-body mx-2">
                        {loading ? (
                            <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                        ) : (
                            <DetalleModal 
                            cabeceraTable={cabeceraTableModal} 
                            cuerpoTable={data} 
                            idprimary={idprimaryModal} />
                        )}
                    </div>
                </Tab>
                <Tab eventKey="Archivos" title="Archivos">
                    {/* CARD BODY MODAL checklist */}
                    <div className="card-body mx-2">
                        {loading ? (
                            <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                        ) : (
                            <DetalleModal 
                            cabeceraTable={cabeceraTableModal} 
                            cuerpoTable={data} 
                            idprimary={idprimaryModal} />
                        )}
                    </div>                
                  </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
              <Link to={`/${tabla}/${tabla}Upd/${selectedIdDetalle}`}>
                <button className="btn btn-success din-btn-style">
                  <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Editar
                </button>
              </Link>
            </Modal.Footer>
          </Modal>
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1000,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}
// Define la función CustomDropdownToggle   
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});
// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShow, handleShowDetalle } = props;
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
       <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
        <Dropdown.Item onClick={() => handleShowDetalle(props.item[props.idprimary])}>
          <i className="fas fa-search"></i> Detalle
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to={`/${props.tabla}/${props.tabla}Upd/${props.item[props.idprimary]}`} style={{textDecoration:'none', color:'#212529'}}><i className="fas fa-edit"></i> Editar</Link>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleShow(props.item[props.idprimary])}>
          <i className="fas fa-trash"></i> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
// Datos de detalle
function ListarDatosDetalle(url, tabla, id) {
  return fetch(`${url}/${tabla}/${tabla}Detail/${id}`, { credentials: 'include' })
      .then(response => {
          if (!response.ok) {
              throw new Error("Error en la respuesta del servidor");
          }
          return response.json();
      })
      .then(data => {
          const datos = {
              ...data.datos,
              fechact: data.datos.fechact.replace('T', ' '),
              fechreg: data.datos.fechreg.replace('T', ' ')
          };
          return datos;
      })
      .catch(error => {
          throw error;
      });
}

// Listado Modal
function ListarDatosModal(url, controlador, id) {
  return fetch(`${url}/${controlador}/${controlador}List/${id}`, { credentials: 'include' })
      .then(response => {
          if (!response.ok) {
              throw new Error("Error en la respuesta del servidor");
          }
          return response.json();
      })
      .then(data => {
          const datos = data.datos.map(item => ({
              ...item,
              fechreg: item.fechreg.replace('T', ' '),
              fechact: item.fechact.replace('T', ' ')
          }));
          return datos;
      })
      .catch(error => {
          throw error;
      });
}

export {ListadoView};