import Pagination from 'react-bootstrap/Pagination';
import '../../Assets/css/StyleListado.css'
import React, { useState } from 'react';

function DetalleModal(props) {
  const { cabeceraTable, cuerpoTable, idprimary } = props;
  // Filtro de busqueda
  const [searchTerm, setSearchTerm] = useState("");
  const filteredItems = cuerpoTable.filter((item) => {
    return cabeceraTable.some((header) => {
      const value = item[header.bdname];
      return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  // Cantidad de registros
  const [itemsPerPage, setItemsPerPage] = useState(5); 
  // Paginacion
  const [currentPage, setCurrentPage] = useState(1);
  // Calcula el índice de inicio y fin para mostrar los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredItems.slice(startIndex, endIndex);
  // Calcula la cantidad total de páginas
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  // Maneja el pase de página
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <section> 
      <div style={{ width: '100%' }}>
        <div className='row mt-2'>
          <div className='col-12 d-flex justify-content-end'>
            <div className='m-1'>
              <input
                  type="text"
                  placeholder="Buscar..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className='form-control w-100 justify-content-start'
                  style={{paddingRight:'0'}}
                />
            </div>
            <div className='m-1'>
              <select className="form-select" aria-label="Default select example" value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                <option value={5}>5 registros</option>
                <option value={10}>10 registros</option>
                <option value={20}>20 registros</option>
              </select>
            </div>
            <div className='m-1'>
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {totalPages === currentPage && totalPages > 2 &&(
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                    {currentPage - 2}
                  </Pagination.Item>
                )}
                {currentPage > 1 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage < totalPages && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}
                {currentPage < 2 && totalPages > 2 && (
                  <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                    {currentPage + 2}
                  </Pagination.Item>
                )}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              </Pagination>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-12'>
            <table className="table table-striped table-bordered dt-responsive din-table-1" style={{ marginTop: '5px' }} id="table_id">
              <thead>
                <tr>
                  {cabeceraTable.map((item, index) => (
                    <th key={index}>
                      {item.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index} id={`tr_${item[idprimary]}`}>
                    {cabeceraTable.map((header, headerIndex) => (
                      <td key={headerIndex} style={{width:`${header.ancho}`,textAlign:`${header.align}`}}>
                        {item[header.bdname]}
                      </td>
                    ))}
                  </tr>
                  ))
                ) : (
                  <tr>
                    <td className='text-center' colSpan={cabeceraTable.length + 1}>No hay registros en la base de datos.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </section>
  );
}

export {DetalleModal};