function InputAreaText(props) {
    const getCharacterCount = () => {
        const newText = props.value;
        if (newText) {
            return newText.length;
          }
          return 0;
    };
    const getCharacterCount2 = () => {
        const newText = document.getElementById(`txt${props.name}`);
        if (newText) {
            return newText.value.length;
          }
          return 0;
    };
    const handleTextareaChange = () => {
        const characterCount = getCharacterCount2();
        document.getElementById(`${props.name}CharacterCount`).textContent = ` ${characterCount}/500`;
    };

    return (
        <div className={`col-12 col-sm-${props.colsm} mb-3`}>
            <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <textarea id={`txt${props.name}`} className="form-control" rows={props.filas} value={props.value || ""} onChange={(e) => {props.onChange(e.target.value);handleTextareaChange();}}></textarea>
            <span id={`${props.name}CharacterCount`} className="text-secondary"><small>{getCharacterCount()}/500</small></span>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
  }
  
export {InputAreaText};