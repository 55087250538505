import { useSelector, useDispatch } from 'react-redux';//importar las variables globales del usuario
import { useState, useEffect } from "react";
import { setUser } from '../../redux/userReducer';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import { Nav, NavDropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { InputComboBoxCaja } from "../agregar/caja/InputComboBoxCaja";
import { InputText } from "../../componentes/editar/InputText";
import API_BASE_URL  from "../../config";

const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
function HeadView() {
    //const { NombreUsuario } = props;
    const [Usuario, setUsuario] = useState(false);
    const [Ruc, setRuc] = useState(false);
    //REDUX
    const dispatch = useDispatch();
    const [UserLoading, setUserLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);

    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    //Navigate
    const navigate = useNavigate();
    //CERRAR
    const FnCerrarSesion = async() => {
        const token = localStorage.getItem('token-dincors');
        try {
            //const response = await fetch(`${url}/auth/me`, {
            const response = await fetch(`${url}/Sesion/CerrarSesion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}` // Enviar el token en el encabezado de autorización
                },
                body: JSON.stringify({}),
            });
            if (response.ok) {
                const data = await response.json();
                if(data.res){
                    localStorage.removeItem('token-dincors');
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/Login`);
                }else{
                    throw new Error(data.msg);
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    // Verificacion de inicio de sesion
    const sessionRecover=async()=>{
        setUserLoading(true);
        //setTimeout(()=>{setUserLoading(true);},3000);
        try {
            const token = localStorage.getItem('token-dincors');
            if(!token){throw new Error('No se encontro la autorizacion');}
            const response = await fetch(`${url}/Sesion/ValidarSesion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify()
            });
            if (response.ok) {
                const data = await response.json();
                //console.log("sesion2: ",data);
                if(data.res){
                    dispatch(setUser({isLogIn:true, userName:data.token.nombre, userId:data.token.id, 
                        companyId:data.token.idempresa, companyRuc:data.token.empruc, companyName:data.token.emprs, 
                        idRol:data.token.idrol, apiKey:data.token.apikey}));
                    //setUsuario(data.token.nombre);
                    setTimeout(()=>{setUserLoading(false);},2000);
                    //setUserLoading(false);
                }else{
                    throw new Error(data.msg);
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch (ex) {
            setToastBackground('#CC6868');
            setToastMessage(ex.message);
            setShowToast(true);
            navigate('/Login');
            setUserLoading(false);
        }finally{
            //setTimeout(()=>{setUserLoading(false);},2000);
        }
    }
    // ====================== MODAL APERTURA CAJA ====================== 
    // VARIABLES
    const [Idcaja, setIdcaja] = useState(''); // <<<<<< CAMBIO
    const [SaldoInicial, setSaldoInicial] = useState(''); // <<<<<< CAMBIO
    //const [Idempresa] = useState(2);  
    const [ShowApertura, setShowApertura] = useState(false);
    const CloseAperturaModal = () => setShowApertura(false);
    const handleShowApertura = () => {
        setShowApertura(true);
    };
    // Función de Aperturar
    const FnAperturaCaja = async() => {
        const now = new Date();
        const jsonData = {
            Idcaja: Idcaja,
            SaldoInicial: SaldoInicial,
            Idempresa: Idempresa,
            Fechreg: now.toISOString(),
            Fechact: now.toISOString(),
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            const response = await fetch(`${url}/Cajas/CajasApertura`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            CloseAperturaModal();
        }
    };
    useEffect(()=>{
        if(!user.isLogIn){
            sessionRecover();
        }else{
            setUsuario(user.userName);
            setRuc(user.companyRuc);
            setTimeout(()=>{setUserLoading(false);},2000);
        }
        // eslint-disable-next-line
    }, [user]);
    return (
        <header id="header-layout">
            <nav className="navbar navbar-expand-lg navbar-light din-nav-principal">
                <div className="container-fluid">
                    {user.idRol === 2 ?
                    <a href="/#MovimientosList" className="navbar-brand d-flex align-items-center">
                        <img src={require('../../Assets/img/IMAGOTIPO_VERDE.png')} alt="Logo de la empresa" width="130" className='mx-auto'/>
                    </a>
                    :
                    <a href="/#AlquileresList" className="navbar-brand d-flex align-items-center">
                        <img src={require('../../Assets/img/IMAGOTIPO_VERDE.png')} alt="Logo de la empresa" width="130" className='mx-auto'/>
                    </a>
                    }
                    <div className="" >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className="d-flex">
                            <div className="mt-2 text-white" style={{fontWeight:600}} >
                                {UserLoading ? (
                                    <>
                                        <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'20px'}}></i>
                                        &nbsp;&nbsp;
                                    </>
                                ) : (
                                    <>
                                    {Ruc} - {Usuario} &nbsp;&nbsp;
                                    </>
                                )}
                            </div>
                            <ul className="navbar-nav" id="din-navbar">
                                <Nav>
                                    <NavDropdown id="nav-dropdown-dark-example"
                                        title={<i className="fa-solid fa-circle-user fa-xl text-white"></i>}
                                    >
                                        <NavDropdown.Item onClick={() => handleShowApertura()}>Aperturar Caja</NavDropdown.Item>
                                        {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item> */}
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={FnCerrarSesion} >Cerrar Sesion</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/"><i className="fa-solid fa-circle-user fa-xl"></i></a>
                                </li> */}
                            </ul>
                            {/* <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Dropdown
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>
            {/* Modal Agregar */}
            <>
                <Modal show={ShowApertura} onHide={CloseAperturaModal} size="lg">
                    <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                        <Modal.Title>Apertura de Caja</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {/* Desea aperturar la caja seleccionada? */}
                            <InputComboBoxCaja value={Idcaja} campo="Caja" name="Idcaja" obligatorio="" colsm="12" colmd="12" url={url} idempresa={Idempresa}
                                        onChange={(selectedValue) => {setIdcaja(selectedValue);}} />
                            <InputText value={SaldoInicial} campo="Saldo Inicial" name="SaldoInicial" obligatorio="*" colsm="12" colmd="12" onChange={setSaldoInicial}/>  {/* <<<<<< CAMBIO */}

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                            <Button variant="secondary" onClick={CloseAperturaModal}>Cerrar</Button>
                            <Button variant="success" onClick={FnAperturaCaja}>Guardar</Button>
                    </Modal.Footer>
                </Modal>
            </>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </header>
    );
}

export default HeadView