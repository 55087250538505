import React, { useEffect, useState } from 'react';

function InputTextCantidad(props) {
    const [cantidad, setCantidad] = useState(1);
    // useEffect(() => {
    //     setCantidad(props.value);
    // }, [props.value]);
    const handleMinusClick = () => {
        if (cantidad > 0) {
        setCantidad(cantidad - 1);
        props.onChange(cantidad - 1);
        }
    };
    const handlePlusClick = () => {
        setCantidad(cantidad + 1);
        props.onChange(cantidad + 1);
    };
    const handleInputChange = (e) => {
        const newValue = parseInt(e.target.value, 10) || 0;
        setCantidad(newValue);
        props.onChange(newValue);
      };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
            <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <div className="input-group">
                <button className="btn btn-outline-success" type="button" id="button-addon1" onClick={handleMinusClick}><i className="fa-solid fa-minus"></i></button>
                {/* <input id={`txt${props.name}`} className="form-control" onChange={(e) => props.onChange(e.target.value)}/> */}
                <input id={`txt${props.name}`} className="form-control" value={cantidad} 
                onChange={(e) => setCantidad(e.target.value)} 
                onBlur={handleInputChange}
                />
                <button className="btn btn-outline-success" type="button" id="button-addon1"  onClick={handlePlusClick}><i className="fa-solid fa-plus"></i></button>
            </div>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}
  
export {InputTextCantidad};