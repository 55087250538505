import { useState, useEffect } from "react";

function InputComboBoxTipoDocumento(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '0', nombre: 'SIN RUC'},
            { id: '1', nombre: 'DNI'},
            { id: '4', nombre: 'CARNET DE EXTRANJERIA'},
            { id: '6', nombre: 'RUC'},
            { id: '7', nombre: 'PASAPORTE'},
            { id: '9', nombre: 'CARNÉ DE SOLICIT DE REFUGIO'},
            { id: '22', nombre: 'CARNÉ DE IDENTIDAD - RELACIONES EXTERIORES'},
            { id: '23', nombre: 'PERM.TEMP.PERMANENCIA'},
            { id: '24', nombre: 'DOC. DE IDENTIDAD EXTRANJERO (3)'},
            { id: '26', nombre: 'CARNÉ DE PERMISO TEMP DE PERMANENCIA'},
        ];
        setListaComboBox(data);
    },[]);
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""} disabled={props.estado}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id} >
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxTipoDocumento};