import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InputText } from "../../componentes/editar/InputText";
import { InputPasswordOriginal } from "../../componentes/editar/InputPasswordOriginal";
import { InputPassword } from "../../componentes/editar/InputPassword";
import { InputComboBox } from "../../componentes/editar/InputComboBox";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import  RouterView  from '../../componentes/layout/RouterView';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'

const nombre_controller = 'Usuarios';    // <<<<<< CAMBIO
const url = "http://192.168.10.103:8000/api";       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Usuario', ruta: `/${nombre_controller}/${nombre_controller}List` },                   // <<<<<< CAMBIO
    { name: 'Edición Usuario', ruta: `/${nombre_controller}/${nombre_controller}Upd` },   // <<<<<< CAMBIO
];
function UsuarioUpd() {                   // <<<<<< CAMBIO
    const { id } = useParams();    
    const [Idrol, setIdrol] = useState('');     // <<<<<< CAMBIO
    const [Usunom, setUsunom] = useState('');               // <<<<<< CAMBIO
    const [Usuape, setUsuape] = useState('');               // <<<<<< CAMBIO
    const [Usucod, setUsucod] = useState('');             // <<<<<< CAMBIO
    const [Usuario, setUsuario] = useState('');           // <<<<<< CAMBIO
    const [User, setUser] = useState('');           // <<<<<< CAMBIO
    const [PasswordOriginal, setPasswordOriginal] = useState('');               // <<<<<< CAMBIO
    const [Password, setPassword] = useState('');               // <<<<<< CAMBIO
    const [Isactivo, setIsactivo] = useState('');               // <<<<<< CAMBIO
    const [Idmodulo, setIdmodulo] = useState('');     // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const [valuesLoaded, setValuesLoaded] = useState(true);
    const FnActualizar = () => {
        const formData = new FormData();
        formData.append('Id', id);                 // <<<<<< CAMBIO
        formData.append('Idrol', Idrol);        // <<<<<< CAMBIO
        formData.append('Usunom', Usunom);                  // <<<<<< CAMBIO
        formData.append('Usuape', Usuape);                  // <<<<<< CAMBIO
        formData.append('Usucod', Usucod);                // <<<<<< CAMBIO
        formData.append('Usuario', Usuario);              // <<<<<< CAMBIO
        formData.append('User', User);              // <<<<<< CAMBIO
        formData.append('Isactivo', Isactivo);                  // <<<<<< CAMBIO
        formData.append('Idmodulo', Idmodulo);                  // <<<<<< CAMBIO
        const now = new Date();
        formData.append('Idempresa', 1);                    // <<<<<< CAMBIO
        formData.append('Fechact', now.toISOString());      // <<<<<< CAMBIO
        formData.append('Codusuact', 2);                     // <<<<<< CAMBIO
        // Ahora formDataValues contiene los valores de todos los campos
        return fetch(`${url}/${nombre_controller}/${nombre_controller}Update`, {
            method: 'POST',
            body: formData,
        })
        .then(function (result) {   // Respuesta 
            if (result.ok) {
                return result.json();   // Retorno tipo json
            }
        })
        .then(function (data) {
            if(data.res === "200"){
                setToastBackground('#198754');
                setToastMessage(data.msg);
                setShowToast(true);
            }else{
                setToastBackground('#CC6868');
                setToastMessage(data.msg);
                setShowToast(true);
            }
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
    };
    const FnActualizarPassword = () => {
        if(Password!=null){
            const formData = new FormData();
            formData.append('Id', id);                              // <<<<<< CAMBIO
            formData.append('PasswordOriginal', PasswordOriginal);  // <<<<<< CAMBIO
            formData.append('Password', Password);                  // <<<<<< CAMBIO
            const now = new Date();
            formData.append('Fechact', now.toISOString());          // <<<<<< CAMBIO
            formData.append('Codusuact', 3);                        // <<<<<< CAMBIO
            // Ahora formDataValues contiene los valores de todos los campos
            return fetch(`${url}/${nombre_controller}/${nombre_controller}UpdatePassword`, {
                method: 'POST',
                body: formData,
            })
            .then(function (result) {   // Respuesta 
                if (result.ok) {
                    return result.json();   // Retorno tipo json
                }
            })
            .then(function (data) {
                if(data.res === "200"){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    setToastBackground('#CC6868');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }
            })
            .catch(function (error) {
                console.log(error);
                throw error;
            });
        }else{
            setToastMessage("Las contraseñas no coinciden");
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    useEffect(() => {
        UbicarRegistro(id, {
            setIdrol,           // <<<<<< CAMBIO
            setUsunom,          // <<<<<< CAMBIO
            setUsuape,          // <<<<<< CAMBIO
            setUsucod,          // <<<<<< CAMBIO
            setUsuario,         // <<<<<< CAMBIO
            setUser,            // <<<<<< CAMBIO
            //setPassword,        // <<<<<< CAMBIO
            setIsactivo,        // <<<<<< CAMBIO
            setIdmodulo,        // <<<<<< CAMBIO
        })
        .then(function (data) {
            setValuesLoaded(true);
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [id]);
    // ====================== MODAL ELIMINAR ======================
    // Agrega un estado para controlar la visibilidad del modal
    const navigate = useNavigate();
    const [showEliminar, setShowEliminar] = useState(false);
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = () => setShowEliminar(true);
    // Función de eliminar
    const FnEliminar = () => {
        handleCloseEliminar();
        return fetch(`${url}/${nombre_controller}/${nombre_controller}Delete/${id}`, {
        method: 'POST',
        })
        .then(function (result) {   // Respuesta 
            if (result.ok) {
                return result.json();   // Retorno tipo  json
            }
        })
        .then(function (data) {
            if(data.res === "200"){
                localStorage.setItem('toastMessage1', data.msg);
                localStorage.setItem('toastBackground1', '#81D075');
                navigate(`/${nombre_controller}/${nombre_controller}List`);
                setToastBackground('#81D075');
                setToastMessage(data.msg);
                setShowToast(true);
            }else{
                setToastBackground('#CC6868');
                setToastMessage(data.msg);
                setShowToast(true);
            }
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <section className="container-fluid mt-3">
                <div className="card m-1">
                    {/* CARD HEAD */}
                    <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                    {/* CARD BODY */}
                    <div className="card-body mx-2">
                        <div className="row">
                            <div className="col-12">
                            {valuesLoaded && (
                                <div className="row">
                                    <InputComboBox value={Idrol} campo="Rol" name="Idrol" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setIdrol}/>             {/* <<<<<< CAMBIO */}
                                    <InputComboBox value={Idmodulo} campo="Módulo" name="Idmodulo" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setIdmodulo}/>    {/* <<<<<< CAMBIO */}
                                    <InputText value={Usunom} campo="Nombre" name="Usunom" obligatorio="*" colsm="6" colmd="4" onChange={setUsunom}/>                      {/* <<<<<< CAMBIO */}
                                    <InputText value={Usuape} campo="Apellido" name="Usuape" obligatorio="*" colsm="6" colmd="4" onChange={setUsuape}/>                    {/* <<<<<< CAMBIO */}
                                    <InputText value={Usucod} campo="Código" name="Usucod" obligatorio="*" colsm="6" colmd="4" onChange={setUsucod}/>                      {/* <<<<<< CAMBIO */}
                                    <InputText value={Usuario} campo="Email" name="Usuario" obligatorio="" colsm="6" colmd="4" onChange={setUsuario}/>                      {/* <<<<<< CAMBIO */}
                                    <InputText value={User} campo="User" name="User" obligatorio="" colsm="6" colmd="4" onChange={setUser}/>                             {/* <<<<<< CAMBIO */}
                                    <InputCheckBox value={Isactivo} campo="Estado" name="Isactivo" obligatorio="*" colsm="6" colmd="4" mensaje="Activo" onChange={setIsactivo}/>            {/* <<<<<< CAMBIO */}
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                    {/* CARD FOOTER */}
                    <div className="card-footer border-0 bg-white">
                        <div className="mb-1 mt-1">
                            <div className="row">
                                <div className='col-12'>
                                    <div className='row p-1 d-flex justify-content-end'>
                                        <Link to={`/${nombre_controller}/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                        </Link>
                                        <Link onClick={FnActualizar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                        <Link onClick={handleShowEliminar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-danger din-btn-style w-100"><i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card m-1">
                            {/* CARD HEAD */}
                            <h5 className="card-header din-card-header">Cambio de contraseña</h5>
                            {/* CARD BODY */}
                            <div className="card-body mx-2">
                                <div className="row">
                                    <div className="col-12">
                                    {valuesLoaded && (
                                        <div className="row">
                                            {/* <InputPassword value={Password} campo="Password" name="Password" obligatorio="" colsm="6" colmd="4" onChange={setPassword}/> */}
                                            <InputPasswordOriginal campo="Contraseña actual" name="Password" obligatorio="" colsm="12" colmd="12" onChange={setPasswordOriginal}/>    {/* <<<<<< CAMBIO */}
                                            <InputPassword campo="Nueva contraseña" name="Password" obligatorio="" colsm="12" colmd="12" onChange={setPassword}/>    {/* <<<<<< CAMBIO */}
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                            {/* CARD FOOTER */}
                            <div className="card-footer border-0 bg-white">
                                <div className="mb-1 mt-1">
                                    <div className="row">
                                        <div className='col-12'>
                                            <div className='row p-1 d-flex justify-content-end'>
                                                <Link onClick={FnActualizarPassword} className="col-12 col-sm-6 col-md-4">
                                                    <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Actualizar Constraseña</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1000,
                    background: `${toastBackground}`,
                }}
                >
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            {/* Modal Eliminar */}
            <>
                <Modal show={showEliminar} onHide={handleCloseEliminar}>
                    <Modal.Header closeButton className="bg-danger text-white">
                        <Modal.Title>Eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEliminar}>
                        Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnEliminar}>
                        Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <FooterView/>
        </div>
        </>
    );
}

function UbicarRegistro(id, setters) {
    return fetch(`${url}/${nombre_controller}/${nombre_controller}Upd/${id}`, { credentials: 'include' })
        .then(response => {
            if (!response.ok) {
                throw new Error("Error en la respuesta del servidor");
            }
            return response.json();
        })
        .then(data => {
            const datos = data.datos;
            setters.setIdrol(datos.idrol);
            setters.setUsunom(datos.usunom);
            setters.setUsuape(datos.usuape);
            setters.setUsucod(datos.usucod);
            setters.setUsuario(datos.usuario);
            setters.setUser(datos.user);
            setters.setIsactivo(datos.isactivo);
            setters.setIdmodulo(datos.idmodulo);
            return datos;
        })
        .catch(error => {
            console.log(error);
            throw error;
        });
}

export default UsuarioUpd;

