import { useState, useEffect } from "react";

async function ListarCombobox2(name, url, idempresa) {
    try {
        const response = await fetch(`${url}/ComboBox/${name}Combo/${idempresa}`, {
            method: 'POST',
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
        }
        const data = await response.json();
        console.log("combo: ",data.datos)
        return data.datos;
    } catch (error) {
        //console.log(error);
        throw error;
    }
}
function InputComboBoxAlmacen(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        ListarCombobox2(props.name, props.url, props.idempresa)
            .then(function (data) {
                const defaultOption = data.find(dato => dato.isdefault === 1);
                if (defaultOption) {
                    props.onChange(defaultOption.id);
                }
                setListaComboBox(data);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [props.name, props.url,props.idempresa]);
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        //const selectedOption = ListarDatos.find((dato) => dato.id === parseInt(selectedValue));
        props.onChange(selectedValue);
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxAlmacen};