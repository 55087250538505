//import {configurateStore} from '@reduxjs/toolkit'
import userReducer from './userReducer';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: {
        user: userReducer,
    }
})

export default store;