function InputDate(props) {
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
            <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <input id={`txt${props.name}`} className="form-control" type="date" 
            value={props.value !== null ? props.value : ''} onChange={(e) => props.onChange(e.target.value)}/>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
  }
  
export {InputDate};