import { ListadoViewCrudTarifa } from "../../componentes/listado/ListadoViewCrudTarifa";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Tarifas';           // <<<<<< CAMBIO
const idprimary = 'idtarifa';                  // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Tarifa', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'ID', bdname: 'idtarifa'},          // <<<<<< CAMBIO
    { name: 'Categoria', bdname: 'categoria'}, // <<<<<< CAMBIO
    { name: 'Dia Semana', bdname: 'txtdiasemana'},// <<<<<< CAMBIO
    { name: 'Cantidad', bdname: 'cantidad' },  // <<<<<< CAMBIO
    { name: 'Monto', bdname: 'monto' },        // <<<<<< CAMBIO
    { name: 'Duracion', bdname: 'txtduracion' },  // <<<<<< CAMBIO
];

function TarifaList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudTarifa url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  {/* <<<<<< CAMBIO */}
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default TarifaList;  // <<<<<< CAMBIO

