import { useState } from "react";
import { InputText } from "../../componentes/agregar/InputText";
import { InputPassword } from "../../componentes/agregar/InputPassword";
import { InputComboBox } from "../../componentes/agregar/InputComboBox";
import { InputCheckBox } from "../../componentes/agregar/InputCheckBox";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'


const nombre_controller = 'Usuarios';           // <<<<<< CAMBIO
const url = 'http://192.168.10.103:8000/api'     // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Usuario', ruta: `/${nombre_controller}/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Registro Usuario', ruta: `/${nombre_controller}/${nombre_controller}Add` },  // <<<<<< CAMBIO
  ];
function UsuarioAdd() {                       // <<<<<< CAMBIO
    const [Id, setId] = useState('');     // <<<<<< CAMBIO
    const [Idrol, setIdrol] = useState('');     // <<<<<< CAMBIO
    const [Usunom, setUsunom] = useState('');               // <<<<<< CAMBIO
    const [Usuape, setUsuape] = useState('');               // <<<<<< CAMBIO
    const [Usucod, setUsucod] = useState('');             // <<<<<< CAMBIO
    const [Usuario, setUsuario] = useState('');           // <<<<<< CAMBIO
    const [User, setUser] = useState('');           // <<<<<< CAMBIO
    const [Password, setPassword] = useState('');               // <<<<<< CAMBIO
    // const [Password2, setPassword2] = useState('');             
    const [Isactivo, setIsactivo] = useState('');               // <<<<<< CAMBIO
    const [Idmodulo, setIdmodulo] = useState('');     // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');

    const FnGuardar = () => {
        if(Password!=null){
            const formData = new FormData();
            formData.append('Id', Id);                        // <<<<<< CAMBIO
            formData.append('Idrol', Idrol);                  // <<<<<< CAMBIO
            formData.append('Usunom', Usunom);                // <<<<<< CAMBIO
            formData.append('Usuape', Usuape);                // <<<<<< CAMBIO
            formData.append('Usucod', Usucod);                // <<<<<< CAMBIO
            formData.append('Usuario', Usuario);              // <<<<<< CAMBIO
            formData.append('User', User);                    // <<<<<< CAMBIO
            formData.append('Password', Password);            // <<<<<< CAMBIO
            formData.append('Isactivo', Isactivo ? 1 : 0);            // <<<<<< CAMBIO
            formData.append('Idmodulo', Idmodulo);            // <<<<<< CAMBIO
            const now = new Date();
            formData.append('Fechreg', now.toISOString());    // <<<<<< CAMBIO
            formData.append('Fechact', now.toISOString());    // <<<<<< CAMBIO
            formData.append('Codusureg', 1);                  // <<<<<< CAMBIO
            formData.append('Codusuact', 1);                  // <<<<<< CAMBIO
            // Ahora formDataValues contiene los valores de todos los campos
            return fetch(`${url}/${nombre_controller}/${nombre_controller}Create`, {
                method: 'POST',
                body: formData,
            })
            .then(function (result) {   // Respuesta 
                if (result.ok) {
                    return result.json();   // Retorno tipo json
                }
            })
            .then(function (data) {
                console.log(data)
                if(data.res === "200"){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            })
            .catch(function (error) {
                console.log(error);
                throw error;
            });
        }else{
            setToastMessage("Las contraseñas no coinciden");
            setShowToast(true);
            setToastBackground('#CC6868');
        }

        
    };
    
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputText campo="ID" name="Id" obligatorio="*" colsm="6" colmd="4" onChange={setId}/>               {/* <<<<<< CAMBIO */}
                                <InputComboBox campo="Rol" name="Idrol" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setIdrol}/> {/* <<<<<< CAMBIO */}
                                <InputComboBox campo="Módulo" name="Idmodulo" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setIdmodulo}/>          {/* <<<<<< CAMBIO */}
                                <InputText campo="Nombre" name="Usunom" obligatorio="*" colsm="6" colmd="4" onChange={setUsunom}/>               {/* <<<<<< CAMBIO */}
                                <InputText campo="Apellido" name="Usuape" obligatorio="*" colsm="6" colmd="4" onChange={setUsuape}/>                  {/* <<<<<< CAMBIO */}
                                <InputText campo="Código" name="Usucod" obligatorio="*" colsm="6" colmd="4" onChange={setUsucod}/>                {/* <<<<<< CAMBIO */}
                                <InputText campo="Email" name="Usuario" obligatorio="" colsm="6" colmd="4" onChange={setUsuario}/>            {/* <<<<<< CAMBIO */}
                                <InputText campo="User" name="User" obligatorio="" colsm="6" colmd="4" onChange={setUser}/>              {/* <<<<<< CAMBIO */}
                                <InputCheckBox campo="Estado" name="Isactivo" obligatorio="*" colsm="6" colmd="4" mensaje="Activo" onChange={setIsactivo}/>            {/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputPassword campo="Contraseña" name="Password" obligatorio="" colsm="6" colmd="4" onChange={setPassword}/>                     {/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    <Link onClick={FnGuardar} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                    </Link>
                                    <Link to={`/${nombre_controller}/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                    </Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        </>
    );
}

export default UsuarioAdd;    /* <<<<<< CAMBIO */

