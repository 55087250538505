import { ListadoViewCrudAlmacen } from "../../componentes/listado/ListadoViewCrudAlmacen";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";


const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const nombre_controller = 'Almacenes';           // <<<<<< CAMBIO
const idprimary = 'idalmacen';                  // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Almacen', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'ID', bdname: 'idalmacen'},          // <<<<<< CAMBIO
    { name: 'Codigo', bdname: 'almcodigo'}, // <<<<<< CAMBIO
    { name: 'Nombre', bdname: 'almnom'},// <<<<<< CAMBIO
    { name: 'Fecha de Inicio', bdname: 'almfechini' },  // <<<<<< CAMBIO
    { name: 'Estado', bdname: 'txtestado' },  // <<<<<< CAMBIO
    { name: '¿Almacen por defecto?', bdname: 'isdefault'},
];

function AlmacenList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudAlmacen url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  {/* <<<<<< CAMBIO */}
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default AlmacenList;  // <<<<<< CAMBIO

