// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { InputText } from "../../componentes/agregar/InputText";
import { InputTextSearch } from "../../componentes/agregar/InputTextSearch";
import { InputComboBoxBusquedaTextId } from "../../componentes/editar/InputComboBoxBusquedaTextId";
// import { InputComboBoxText } from "../../componentes/agregar/InputComboBoxText";
import { InputComboBoxGenero } from "../../componentes/agregar/cliente/InputComboBoxGenero";
import { InputComboBoxTipoDocumento } from "../../componentes/agregar/cliente/InputComboBoxTipoDocumento";
import { InputComboBoxRangoEdad } from "../../componentes/agregar/cliente/InputComboBoxRangoEdad";
import { InputComboBoxEstadoCivil } from "../../componentes/agregar/cliente/InputComboBoxEstadoCivil";
import { InputComboBoxParentesco } from "../../componentes/agregar/cliente/InputComboBoxParentesco";
import { InputComboBoxTextId } from "../../componentes/agregar/InputComboBoxTextId";
// import { InputComboBoxBusqueda } from "../../componentes/agregar/InputComboBoxBusqueda";
// import { InputComboBoxN2 } from "../../componentes/agregar/InputComboBoxN2";
import { InputComboBoxN2TextId } from "../../componentes/agregar/InputComboBoxN2TextId";
import { InputAreaText } from "../../componentes/agregar/InputAreaText";
import { InputDate } from "../../componentes/agregar/InputDate";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
import { Link, useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Clientes';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Cliente', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Registro Cliente', ruta: `/${nombre_controller}Add` },  // <<<<<< CAMBIO
];
function ClienteAdd() {
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]); 
    //Cliente
    const navigate = useNavigate();
    const [Clinom, setClinom] = useState('');                   // <<<<<< CAMBIO
    const [Cliruc, setCliruc] = useState('');                   // <<<<<< CAMBIO
    const [Clitelf, setClitelf] = useState('');                 // <<<<<< CAMBIO
    const [Clidirec, setClidirec] = useState('');               // <<<<<< CAMBIO
    const [Cliciudad, setCliciudad] = useState('');             // <<<<<< CAMBIO
    const [Cliemail, setCliemail] = useState('');               // <<<<<< CAMBIO
    const [Cliobs, setCliobs] = useState('');                   // <<<<<< CAMBIO
    const [Iddepartamento, setIddepartamento] = useState('');   // <<<<<< CAMBIO
    const [Txtdepartamento, setTxtdepartamento] = useState('');   // <<<<<< CAMBIO
    const [Idprovincia, setIdprovincia] = useState('');         // <<<<<< CAMBIO
    const [Txtprovincia, setTxtprovincia] = useState('');         // <<<<<< CAMBIO
    const [Iddistrito, setIddistrito] = useState('');           // <<<<<< CAMBIO
    const [Txtdistrito, setTxtdistrito] = useState('');           // <<<<<< CAMBIO
    const [Txttipodocumento, setTxttipodocumento] = useState(''); // <<<<<< CAMBIO
    const [Idpais, setIdpais] = useState(194);                   // <<<<<< CAMBIO
    const [Txtpais, setTxtpais] = useState('PERU');                   // <<<<<< CAMBIO
    const [FechaNacimiento, setFechaNacimiento] = useState(''); // <<<<<< CAMBIO
    // const [Txtcargo, setTxtcargo] = useState('');       // <<<<<< CAMBIO
    const [Txtgenero, setTxtgenero] = useState('');       // <<<<<< CAMBIO
    const [Txtrangoedad, setTxtrangoedad] = useState('');       // <<<<<< CAMBIO
    const [Txtestadocivil, setTxtestadocivil] = useState('');   // <<<<<< CAMBIO
    const [Txtparentesco, setTxtparentesco] = useState('');     // <<<<<< CAMBIO
    const [Estado, setEstado] = useState(1);             // <<<<<< CAMBIO
    const ClearComboBox = () => {
        setIdprovincia(null)
        setIddistrito(null)
    }
    // eslint-disable-next-line no-unused-vars
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const FnGuardar = async () => {
        let mensaje='';
        if(Clinom.trim() !== '' && Cliruc.trim() !== '' && Txttipodocumento !== '' 
            && Idpais.toString().trim() !== '' && Txtgenero !== '' && Txtrangoedad !== '' 
            && Clidirec.trim() !== ''){ // <<<<<< CAMBIO
            let cant = Cliruc.toString().length;
            if(Txttipodocumento==='RUC' && cant < 11){  // <<<<<< CAMBIO
                mensaje =`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres para el cliente.`;
                setToastMessage(mensaje);
                setShowToast(true);
                setToastBackground('#CC6868');
            }else{
                const now = new Date();
                const jsonData = {
                    Clinom: Clinom,         // <<<<<< CAMBIO
                    Cliruc: Cliruc,         // <<<<<< CAMBIOv
                    Clitelf: Clitelf,       // <<<<<< CAMBIO
                    Clidirec: Clidirec,     // <<<<<< CAMBIO
                    Cliciudad:Cliciudad,    // <<<<<< CAMBIO
                    Cliemail: Cliemail,     // <<<<<< CAMBIO
                    Cliobs: Cliobs,         // <<<<<< CAMBIO
                    Txtdepartamento: Txtdepartamento, // <<<<<< CAMBIO
                    Txtprovincia: Txtprovincia,     // <<<<<< CAMBIO
                    Txtdistrito: Txtdistrito,       // <<<<<< CAMBIO
                    Iddistrito: Iddistrito,         // <<<<<< CAMBIO
                    Txttipodocumento: Txttipodocumento,// <<<<<< CAMBIO
                    Idpais: Idpais,                 // <<<<<< CAMBIO
                    Txtpais: Txtpais,               // <<<<<< CAMBIO
                    Idempresa: Idempresa,
                    FechaNacimiento: FechaNacimiento,// <<<<<< CAMBIO
                    // Txtcargo: Txtcargo,             // <<<<<< CAMBIO
                    Txtgenero: Txtgenero,           // <<<<<< CAMBIO
                    Txtrangoedad: Txtrangoedad,     // <<<<<< CAMBIO
                    Txtestadocivil: Txtestadocivil, // <<<<<< CAMBIO
                    Txtparentesco: Txtparentesco,   // <<<<<< CAMBIO
                    Estado: Estado ? 2 : 1,         // <<<<<< CAMBIO
                    Fechreg: now.toISOString(),
                    Fechact: now.toISOString(),
                    Codusureg: Idusuario,
                    Codusuact: Idusuario,
                };
                try {
                    const token = localStorage.getItem('token-dincors');
                    const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Create`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(jsonData),
                    });
                    if(response.ok){                
                        const data = await response.json();
                        if(data.res){
                            localStorage.setItem('toastMessage1', data.msg);
                            localStorage.setItem('toastBackground1', '#198754');
                            navigate(`/${nombre_controller}List`);
                        }else{
                            setToastMessage(data.msg);
                            setShowToast(true);
                            setToastBackground('#CC6868');
                        }
                    }else  {
                        throw new Error(`${response.status} ${response.statusText}`);
                    }
                }catch (error){
                    setToastBackground('#CC6868');
                    setToastMessage(error.message);
                    setShowToast(true);
                }
            }
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Datos Generales</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputText campo="Cliente" name="Clinom" obligatorio="*" colsm="6" colmd="4" onChange={setClinom}/>        {/* <<<<<< CAMBIO */}
                                <InputComboBoxTipoDocumento campo="Tipo de Documento" name="Txttipodocumento" obligatorio="*" colsm="6" colmd="4" onChange={setTxttipodocumento}/> {/* <<<<<< CAMBIO */}
                                <InputTextSearch campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="4" onChange={setCliruc}/>  {/* <<<<<< CAMBIO */}
                                <InputText campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="4" onChange={setClitelf}/>               {/* <<<<<< CAMBIO */}
                                <InputComboBoxGenero campo="Genero" name="Txtgenero" obligatorio="*" colsm="6" colmd="4" onChange={setTxtgenero}/>  {/* <<<<<< CAMBIO */}
                                <InputComboBoxRangoEdad campo="Rango de Edad" name="Txtrangoedad" obligatorio="*" colsm="6" colmd="4" onChange={setTxtrangoedad}/>  {/* <<<<<< CAMBIO */}
                                <InputComboBoxBusquedaTextId value={Idpais} campo="Pais" name="Idpais" obligatorio="*" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIdpais(selectedValue);setTxtpais(selectedText);}}/>              {/* <<<<<< CAMBIO */}
                                <InputComboBoxTextId campo="Departamento" name="Iddepartamento" obligatorio="" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIddepartamento(selectedValue);setTxtdepartamento(selectedText);ClearComboBox();}} />   {/* <<<<<< CAMBIO */}
                                <InputComboBoxN2TextId campo="Provincia" name="Idprovincia" obligatorio="" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIdprovincia(selectedValue);setTxtprovincia(selectedText);}} idnivel={Iddepartamento}/>  {/* <<<<<< CAMBIO */}
                                <InputComboBoxN2TextId campo="Distrito" name="Iddistrito" obligatorio="" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIddistrito(selectedValue);setTxtdistrito(selectedText);}} idnivel={Idprovincia}/>  {/* <<<<<< CAMBIO */}
                                <InputText campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="4" onChange={setCliciudad}/>   {/* <<<<<< CAMBIO */}
                                <InputAreaText campo="Direccion" name="Clidirec" obligatorio="*" colsm="12" onChange={setClidirec}/>                {/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Datos Complementarios</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputDate campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="4" onChange={setFechaNacimiento}/>   {/* <<<<<< CAMBIO */}
                                {/* <InputComboBoxText campo="Cargo" name="Txtcargo" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtcargo}/>  */}
                                <InputComboBoxEstadoCivil campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="4" onChange={setTxtestadocivil}/> {/* <<<<<< CAMBIO */}
                                <InputComboBoxParentesco campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="4" onChange={setTxtparentesco}/>       {/* <<<<<< CAMBIO */}
                                <InputText campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="4" onChange={setCliemail}/>                {/* <<<<<< CAMBIO */}
                                <InputCheckBox value={Estado} campo=" " name="Estado" obligatorio="" colsm="6" colmd="4" mensaje="¿Cliente activo?" onChange={setEstado}/>      {/* <<<<<< CAMBIO */}
                                <InputAreaText campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs}/>     {/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    <Link onClick={FnGuardar} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                    </Link>
                                    <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                    </Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        </>
    );
}

export default ClienteAdd;    /* <<<<<< CAMBIO */

