import { ListadoView } from "../../componentes/listado/ListadoViewMejoraCaja";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Cajas';           // <<<<<< CAMBIO
const idprimary = 'idcaja';                  // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;     // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Caja', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Codigo de Caja', bdname: 'cajacod'},   // <<<<<< CAMBIO
    { name: 'Fecha de Recepcion', bdname: 'fecha'}, // <<<<<< CAMBIO
    { name: 'Nombre', bdname: 'cajanom'},         // <<<<<< CAMBIO
    { name: 'Descripcion', bdname: 'cajadesc' },                // <<<<<< CAMBIO
    { name: 'Saldo', bdname: 'saldo' },          // <<<<<< CAMBIO
    { name: '¿La caja esta asociada a una cuenta de banco?', bdname: 'isbanco' },            // <<<<<< CAMBIO
    { name: 'Nombre del Banco', bdname: 'bconom' },     // <<<<<< CAMBIO
    { name: 'Moneda', bdname: 'txtmoneda' },     // <<<<<< CAMBIO
    { name: 'Fecha de Actualizacion', bdname: 'fechact' },     // <<<<<< CAMBIO
];

function CajaList() {      // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    // Toast de Eliminación
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
            setToastMessage(storedMessage);
            setToastBackground(storedBackground);
            setShowToast(true);
            localStorage.removeItem('toastMessage1');
            localStorage.removeItem('toastBackground1');
        }
    }, []);
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoView url={url} tabla={nombre_controller} idprimary={idprimary} 
                        detalle={Detalle} />
                    </section>
                    <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                        style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}`,}}>
                        <Toast.Header>
                            <strong className="me-auto">Notificación</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                </div>
                <FooterView/>
            </div>
        </>
        
    );
}

export default CajaList;   // <<<<<< CAMBIO


