// import React, { useState } from 'react';

function InputAreaText(props) {
    // const [text, setText] = useState('');
    const handleTextareaChange = () => {
        const newText = document.getElementById(`txt${props.name}`).value;
        // setText(newText);
        const characterCount = newText.length;
        document.getElementById(`${props.name}CharacterCount`).textContent = ` ${characterCount}/500`;
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} mb-3`}>
            <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <textarea id={`txt${props.name}`} className="form-control" rows={props.filas} onChange={(e) => {props.onChange(e.target.value);handleTextareaChange();}}></textarea>
            <span id={`${props.name}CharacterCount`} className="text-secondary"><small>0/500</small></span>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
  }
  
export {InputAreaText};