import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, useEffect, forwardRef  } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import { DetalleModal } from '../../componentes/detalle/DetalleModal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../Assets/css/din_style_3.css'
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

function ListadoView(props) {
  const { url, tabla, idprimary, detalle} = props; /* cuerpoTable, nombre_controller_modal,idprimaryModal   onChange*/
  //Redux
  const user = useSelector((state) => state.user);
  const [Idempresa, setIdempresa] = useState(user.companyId);
  // const [Idusuario, setIdusuario] = useState(user.userId);
  // Función para actualizar el usuario en el estado local cuando cambie en Redux
  useEffect(() => {
      setIdempresa(user.companyId);
      // setIdusuario(user.userId);
  }, [user]);
  const navigate = useNavigate();
  const [loading, setLoading]=useState(false);
  const [loadingPag, setLoadingPag] = useState(false);
  const [data, setData]=useState([]);
  const [page, setPage]=useState(0);//Controlar la posición actual de la página
  const [count, setCount]=useState(0);//controlar el cambio del total de páginas
  const [form, setForm]=useState({itemcodigo:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
  const [secondForm, setSecondForm]=useState({itemcodigo:'', page:0, buscar:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
  //const [Idempresa] = useState(3);              // <<<<<< CAMBIO
  // const Idempresa=2;
  useEffect(()=>{
    if(form.buscar){
        searchData(form);
    }
    // eslint-disable-next-line
  },[form,user.companyId]);
  const searchData= async (data)=>{
      setLoading(true);
      try {
          const jsonData = {
              idempresa:user.companyId,
              itemcodigo:data.itemcodigo,
              pagina:data.page,
              idpage:localStorage.getItem('idpage') ? localStorage.getItem('idpage') : 0,
          };
          const token = localStorage.getItem('token-dincors');
          const response = await fetch(`${url}/${tabla}/${tabla}Pages`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
              },
              body:JSON.stringify(jsonData)
          });
          if(response.ok){
              const data = await response.json();
              //console.log("dataitem: ",data)
              if(data.res){
                  setData(data.datos);
                  setPage(page => page + data.pag);
                  setCount(data.pag);
                  if(localStorage.getItem('idpage')){
                    localStorage.removeItem('idpage');
                  }
              }else{
                  setCount(0);
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
      }catch (error) {
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
      }finally{
          setLoading(false);
      }
  }
 
  const searchForm=(form)=>{
    setForm(form);
    setPage(0);
  }

  const firstPage=()=>{
      setForm({...form, page: 0});
      setPage(0);
  }

  const nextPage=()=>{
      setLoadingPag(true);
      setForm({...form, page: page});
      setTimeout(() => {
        setLoadingPag(false);
      }, 600);
  };

  const handleSearch=()=>{
    searchForm(secondForm);
  };

  const handleChange=(e)=>{
    setSecondForm({
        ...secondForm,
        [e.target.name]:e.target.value
    });
  };

  // Exportar a Excel
  const handleExportToExcel = async () => {
    try{
      const token = localStorage.getItem('token-dincors');
      const jsonData = {
        Idempresa: Idempresa,
      }
      const response = await fetch(`${url}/${tabla}/${tabla}ExportExcel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(jsonData),
      });
      
      let data;
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          const blobData = await response.blob(); // Espera a que se resuelva la promesa
          data = blobData;
        } else {
          data = await response.json(); // Espera a que se resuelva la promesa
        }
        
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }

      if (data instanceof Blob) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Items.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setToastBackground('#198754');
        setToastMessage('Exportacion generada con exito'); // data.msg
        setShowToast(true);
      } else {
        setToastBackground('#CC6868');
        setToastMessage('Error al realizar la exportacion');
        setShowToast(true);
      }
    }catch (error) {
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    }
  };

  // ====================== MODAL ELIMINAR ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const handleShow = (itemId) => {
    setSelectedItemId(itemId);
    setShow(true);
  };
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#198754');
  // ====================== ELIMINADOS ======================
  const [deletedItems, setDeletedItems] = useState(new Set());
  // Función de eliminar
  const FnEliminar = async () => {
    handleClose();
    const jsonData = {
        Iditem: selectedItemId,
        Idempresa: Idempresa,
    };
    const token = localStorage.getItem('token-dincors');
    try{
      const response = await fetch(`${url}/${props.tabla}/${props.tabla}Delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonData),
      })
      if(response.ok){
        const data = await response.json();
        if(data.res){
            setToastBackground('#198754');
            setToastMessage(data.msg);
            setShowToast(true);
            // Después de eliminar en la base de datos, agrega el ID al conjunto de elementos eliminados
            setDeletedItems((prevDeletedItems) => {
              const newDeletedItems = new Set(prevDeletedItems);
              newDeletedItems.add(`tr_${selectedItemId}`);
              return newDeletedItems;
          });
        }else{
            setToastMessage(data.msg);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
      }
    }catch(error){
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    };
  };
   // ====================== MODAL DETALLE ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  const [selectedIdDetalle, setSelectedIdDetalle] = useState(null);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [DataAlmacen, setDataAlmacen] = useState([]);
  const token2 = localStorage.getItem('token-dincors');

  const handleShowDetalle = (itemId) => {
    setSelectedIdDetalle(itemId);
    setShowDetalle(true);
    ListarDatosDetalle(url,tabla,itemId,token2)
      .then(function (data) {
          //console.log("detail: ", data)
          setDataDetalle(data);
          setDataAlmacen(data.almacen);
      })
      .catch(function (error) {
          //console.log(error);
      });
  };
  // Redireccionar con navigate
  const handleRedirect = (url) => {
    navigate(url);
  };
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
          {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                  <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                  &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className='row mb-2'>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <label>Codigo o Item</label>
                      <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="itemcodigo" onChange={handleChange} value={secondForm.itemcodigo}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row '>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                    </div>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                    </div>
                    <div className='col-12 custom-col'>
                      <Link to={`/${tabla}Add`}><button className="btn btn-primary din-btn-style w-100"><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Nuevo</button></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='row d-flex justify-content-end'>
                    <div className='col-12 custom-col-pagination'>
                      <div className='m-1'>
                        <Pagination className='justify-content-end'>
                          {page > 10 && 
                            <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              PRIMERO
                            </Pagination.Prev>}
                          {count === 10 && 
                            <Pagination.Next m={1} onClick={nextPage} style={{width:100, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              {loadingPag ? (
                                <>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </>
                              ) : (
                                'SIGUIENTE'
                              )}
                            </Pagination.Next>}
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className='col-12'>
                  <div className="table" style={{ marginTop: '5px'}} id="table_id">
                    <div className='tbody mx-3' >
                      {
                      data.length > 0 ? (
                        data.filter((item) => !deletedItems.has(`tr_${item[idprimary]}`))
                        .map((item, index) => (
                          <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${item[idprimary]}`}>
                            <div className='td td-dropdown d-flex align-items-center justify-content-center'>
                              <MyDropdown tabla={props.tabla} item={item} idprimary={idprimary} handleShow={handleShow} handleShowDetalle={handleShowDetalle} handleRedirect={handleRedirect}/>
                            </div>
                            <div className='td td-datos' >
                              <div className='row'>
                                <div className='col-12 col-md-2'>
                                  Codigo: {item.itemcodigo}<br/> {/* <<<<<< CAMBIO */}
                                </div>
                                <div className='col-12 col-md-2'>
                                  Nombre: {item.itemdesc}<br/> {/* <<<<<< CAMBIO */}
                                </div>
                                <div className='col-12 col-md-3'>
                                  Fecha: {item.fechact}<br/> {/* <<<<<< CAMBIO */}
                                </div>
                                <div className='col-12 col-md-2'>
                                  Estado: {item.txtestado}<br/> {/* <<<<<< CAMBIO */}
                                </div>
                                <div className='col-12 col-md-2'>
                                  Unidad de Medida: {item.txtunidadmedida}<br/> {/* <<<<<< CAMBIO */}
                                </div>
                                {/* <div className='col-12 col-md-2'>
                                  Categoria: {item.idcategoria}<br/>
                                </div>
                                <div className='col-12 col-md-2'>
                                  Subcategoria: {item.idsubcategoria}<br/>
                              </div> */} 
                              </div>
                            </div>
                          </div>
                          ))
                        ) : (
                        <div>
                          <div className='text-center'>Elija los filtros para encontrar los registros</div>
                        </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          )}
          
          {/* Modal Eliminar*/}
          <>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header  className='bg-danger text-white' closeButton>
                <Modal.Title>Eliminación</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={handleClose}>
                  <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                </button>
                <button className="btn btn-danger din-btn-style" onClick={FnEliminar}>
                  <i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar
                </button>
              </Modal.Footer>
            </Modal>
          </>
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title >Detalle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="controlled-tab-example" defaultActiveKey="Datos Generales" className="mb-3" >
                <Tab eventKey="Datos Generales" title="Datos Generales">          {/* <<<<<< AGREGAR */}
                      {/* CARD BODY */}
                      <div className="card-body mx-2">
                          <div className="row">
                            {detalle.map((header, headerIndex) => (
                              <div key={headerIndex} className="col-12">
                                <div className='row'>
                                  <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                                    {header.name}:&nbsp;&nbsp;
                                  </div>
                                  <div className='col-7 col-sm-7 col-md-9'>
                                    {dataDetalle[header.bdname]}
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="col-12">
                              <div className='row'>
                                <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                                  Almacen:&nbsp;&nbsp;
                                </div>
                                <div className='col-7 col-sm-7 col-md-9'>
                                  {DataAlmacen.map((item, headerIndex) => (
                                    <li key={headerIndex}>
                                      {item.almcodigo} - {item.almnom}
                                    </li>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
              <Link to={`/${tabla}Upd/${selectedIdDetalle}`}>
                <button className="btn btn-success din-btn-style">
                  <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Editar
                </button>
              </Link>
            </Modal.Footer>
          </Modal>
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1000,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}
// Define la función CustomDropdownToggle
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});
// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShow, handleRedirect, handleShowDetalle } = props;
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
      <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
        <Dropdown.Item onClick={() => handleShowDetalle(props.item[props.idprimary])}>
          <i className="fas fa-search"></i> Detalle
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleRedirect(`/${props.tabla}Upd/${props.item[props.idprimary]}`)}>
          <i className="fas fa-edit"></i> Editar
        </Dropdown.Item >
        <Dropdown.Item onClick={() => handleShow(props.item[props.idprimary])}>
          <i className="fas fa-trash"></i> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
// Datos de detalle
async function ListarDatosDetalle(url, tabla, id, token2) {
    try {
        const response = await fetch(`${url}/${tabla}/${tabla}Detail/${id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${token2}`
            },
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            //console.log("dat: ",data)
            if(data.res){
                const datos = {
                    ...data.datos,
                    fechact: data.datos.fechact.replace('T', ' '),
                    fechreg: data.datos.fechreg.replace('T', ' '),
                    almacen: data.datos2,
                };
                return datos;
            }else{
                throw new Error(data.msg);
            }
        }else{
            throw new Error(`${response.status} ${response.statusText}`);
        }
    } catch (error) {
        //console.log(error);
        throw error;
    }
}

export {ListadoView};