import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, forwardRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { InputText as InputTextAdd } from "../../componentes/agregar/InputText";
import { InputText as InputTextUpd } from "../../componentes/editar/InputText";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

function ListadoViewCrudNivel(props) {
  const { url, tabla, idprimary, detalle } = props;
  //Redux
  const user = useSelector((state) => state.user);
  const [Idempresa, setIdempresa] = useState(user.companyId);
  const [Idusuario, setIdusuario] = useState(user.userId);
  // Función para actualizar el usuario en el estado local cuando cambie en Redux
  useEffect(() => {
      setIdempresa(user.companyId);
      setIdusuario(user.userId);
  }, [user]);
  const [loading, setLoading]=useState(false);
  const [loadingPag, setLoadingPag] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData]=useState([]);
  const [page, setPage]=useState(0);
  const [count, setCount]=useState(0);
  const [form, setForm]=useState({page:0, buscar:true}); // <<<<<< CAMBIO
  const [secondForm]=useState({page:0, buscar:true}); // <<<<<< CAMBIO
  useEffect(()=>{
    if(form.buscar){
        searchData(form);
    }
    // eslint-disable-next-line
  },[form,,user.companyId]);
  const searchData= async (data)=>{
      setLoading(true);
      try {
          const jsonData = {
              idempresa:user.companyId,
              pagina:data.page
          };
          const token = localStorage.getItem('token-dincors');
          const response = await fetch(`${url}/${tabla}/${tabla}Pages`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
              },
              body:JSON.stringify(jsonData)
          });
          if(response.ok){                
              const data = await response.json();
              if(data.res){
                  setData(data.datos);
                  setPage(page+data.pag);
                  setCount(data.pag);
              }else{
                  setCount(0);
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
      }catch (error) {
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
      }finally{
          setLoading(false);
      }
  }
  const handleSearch = () => {
      setLoadingSearch(true);
      firstPage();
      searchData(secondForm);
      setTimeout(() => {
        setLoadingSearch(false);
      }, 1000);
  };
  const firstPage=()=>{
      setForm({...form, page: 0});
      setPage(0);
  }
  const nextPage=()=>{
      setLoadingPag(true);
      setForm({...form, page: page});
      setTimeout(() => {
        setLoadingPag(false);
      }, 600);
  };
  // Exportar a Excel
  const handleExportToExcel = async () => {
    try{
      const jsonData = {
        idempresa: Idempresa,
      }
      const token = localStorage.getItem('token-dincors');
      const response = await fetch(`${url}/${tabla}/${tabla}ExportExcel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify(jsonData),
      });
      let data;
      if(response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          const blobData = await response.blob();
          data = blobData;
        } else {
          data = await response.json();
        }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
      if (data instanceof Blob) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Nivel.xlsx';    // <<<<<< CAMBIO
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setToastBackground('#198754');
        setToastMessage('Exportacion generada con exito');
        setShowToast(true);
      } else {
        setToastBackground('#CC6868');
        setToastMessage('Error al realizar la exportacion1');
        setShowToast(true);
      }
    }catch (error) {
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    }
  };
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#81D075');
  // ====================== CONSTANTES MODAL ======================
  const [Nivel, setNivel] = useState('');        // <<<<<< CAMBIO
  const FnLimpiarDatos = () => {
    setNivel('');          // <<<<<< CAMBIO
  }
  // ====================== MODAL AGREGAR ======================  
  const [ShowAgregar, setShowAgregar] = useState(false);
  const CloseAgregarModal = () => setShowAgregar(false);
  const handleShowAgregar = () => {
    setShowAgregar(true);
  };
  // Función de agregar
  const FnGuardarModal = async() => {
    var mensaje='';
    if(Nivel.trim() !== '') // <<<<<< CAMBIO
    {
        const now = new Date();
        const jsonData = {
            Nivel: Nivel,       // <<<<<< CAMBIO
            Idempresa: Idempresa,    
            Fechreg: now.toISOString(),
            Fechact: now.toISOString(),
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
          const response = await fetch(`${url}/${tabla}/${tabla}Create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData),
          });
          if(response.ok){                
            const data = await response.json();
            if(data.res){
                setToastBackground('#198754');
                setToastMessage(data.msg);
                setShowToast(true);
                CloseAgregarModal();
                setData((prevOrden) => [data.datos, ...prevOrden]);
                setPage(0);
                setCount(0);
                FnLimpiarDatos();
            }else{
                setToastMessage(data.msg);
                setShowToast(true);
                setToastBackground('#CC6868');
            }
          }else  {
              throw new Error(`${response.status} ${response.statusText}`);
          }
        }catch (error){
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
        }
    }else{
        mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
        setToastMessage(mensaje);
        setShowToast(true);
        setToastBackground('#CC6868');
    }
  };
  // ====================== MODAL EDITAR ======================
  const [showAlert, setShowAlert] = useState(false);
  const [ShowEditar, setShowEditar] = useState(false);
  const CloseEditarModal = () => setShowEditar(false);
  const [selectedId, setSelectedId] = useState(null);
  const token2 = localStorage.getItem('token-dincors');
  const handleShowEditar = (itemId) => {
    setSelectedId(itemId);
    UbicarRegistroModal(url,itemId,tabla,token2, {
      setNivel,     // <<<<<< CAMBIO
    })
    .then(function (data) {
        setShowEditar(true);
    })
    .catch(function (error) {
    });
  };
  const FnActualizarModal = async() => {
    var mensaje='';
    if(Nivel.trim() !== '' ) // <<<<<< CAMBIO
    {
        const now = new Date();
        const jsonData = {
            Idnivel: selectedId,       // <<<<<< CAMBIO
            Nivel: Nivel,       // <<<<<< CAMBIO
            Idempresa: Idempresa,
            Fechact: now.toISOString(),
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
          const response = await fetch(`${url}/${tabla}/${tabla}Update`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData),
          })
          if(response.ok){                
            const data = await response.json();
            if(data.res){
                setToastBackground('#198754');
                setToastMessage(data.msg);
                setShowToast(true);
                CloseEditarModal();
                setData((prevOrden) => prevOrden.map(item =>
                    item.idnivel === data.datos.idnivel ? data.datos : item
                )); // <<<<<< CAMBIO
                FnLimpiarDatos();
            }else{
                setToastMessage(data.msg);
                setShowToast(true);
                setToastBackground('#CC6868');
            }
          }else  {
              throw new Error(`${response.status} ${response.statusText}`);
          }
        }catch (error){
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
        }
    }else{
        mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
        setToastMessage(mensaje);
        setShowToast(true);
        setToastBackground('#CC6868');
    }
  };
  // ====================== MODAL ELIMINAR ======================
  const [ShowEliminar, setShowEliminar] = useState(false);
  const CloseEliminarModal = () => setShowEliminar(false);
  const [SelectedIdEliminar, setSelectedIdEliminar] = useState(null);
  const handleShowEliminar = (itemId) => {
      setSelectedIdEliminar(itemId);
      setShowEliminar(true);
  };
  const FnEliminarModal = async () => {
    const jsonData = {
      Idnivel:SelectedIdEliminar,  // <<<<<< CAMBIO
      Idempresa:Idempresa,
    };
    const token = localStorage.getItem('token-dincors');
    try{
      const response = await fetch(`${url}/${tabla}/${tabla}Delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonData),
      })
      if(response.ok){                
        const data = await response.json();
        if(data.res){
            setToastBackground('#198754');
            setToastMessage(data.msg);
            setShowToast(true);
            setData((prevOrden) => prevOrden.filter(item => item.idnivel !== data.datos));  // <<<<<< CAMBIO
            setPage(0);
            setCount(0);
        }else{
            throw new Error(data.msg);
        }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
    }catch(error){
        setToastBackground('#CC6868');
        setToastMessage(error.message);
        setShowToast(true);
    }finally{
      CloseEliminarModal();
    }
  };
  // ====================== MODAL DETALLE ======================
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  const [dataDetalle, setDataDetalle] = useState([]);
  const handleShowDetalle = (itemId) => {
    setShowDetalle(true);
    ListarDatosDetalle(url,tabla,itemId, token2)
      .then(function (data) {
          setDataDetalle(data);
      })
      .catch(function (error) {
      });
  };
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
        {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                  <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                  &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className='row '>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      {loadingSearch ? (
                          <>
                            <button className="btn btn-success din-btn-style w-100" disabled>
                              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            </button>
                          </>
                      ) : (
                          <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                      )}
                    </div>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                    </div>
                    <div className='col-12 custom-col'>
                      <Link><button className="btn btn-primary din-btn-style w-100" onClick={() => handleShowAgregar()}><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Nuevo</button></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='row d-flex justify-content-end'>
                    <div className='col-12 custom-col-pagination'>
                      <div className='m-1'>
                        <Pagination className='justify-content-end'>
                          {page > 10 && 
                            <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              PRIMERO
                            </Pagination.Prev>}
                          {count === 10 && 
                            <Pagination.Next m={1} onClick={nextPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              {loadingPag ? (
                                <>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </>
                              ) : (
                                'SIGUIENTE'
                              )}
                            </Pagination.Next>}
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className='col-12'>
                  <div className="table" style={{ marginTop: '5px'}} id="table_id">
                    <div className='tbody mx-3' >
                      {loading ? (
                          <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                      ) : (
                        data.length > 0 ? (
                          data.map((item, index) => (
                            <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${item[idprimary]}`}>
                              <div className='td td-dropdown d-flex align-items-center justify-content-center' >
                                <MyDropdown tabla={props.tabla} id={item[idprimary]} handleShowDetalle={handleShowDetalle}
                                handleShowEditar={handleShowEditar} handleShowEliminar={handleShowEliminar}/>
                              </div>
                              <div className='td td-datos' >
                                <div className='row'>
                                  <div className='col-12 col-md-2'>
                                    ID: <span>{item.idnivel}</span><br/>  {/* <<<<<< CAMBIO */}
                                  </div>
                                  <div className='col-12 col-md-10'>
                                    Nivel: {item.nivel}<br/> {/* <<<<<< CAMBIO */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            ))
                          ) : (
                          <div>
                            <div className='text-center'>Elija los filtros para encontrar los registros</div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          )}
          {/* Modal Agregar */}
          <>
            <Modal show={ShowAgregar} onHide={CloseAgregarModal} size="lg">
              <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                  <Modal.Title>Editar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                    <InputTextAdd campo="Nivel" name="Nivel" obligatorio="*" colsm="12" colmd="6" onChange={setNivel}/>   {/* <<<<<< CAMBIO */}
                </div>
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="secondary" onClick={CloseAgregarModal}>Cerrar</Button>
                    <Button variant="success" onClick={FnGuardarModal}>Guardar</Button>
              </Modal.Footer>
            </Modal>
          </>
          {/* Modal Editar */}
          <>
            <Modal show={ShowEditar} onHide={CloseEditarModal} >
              <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                  <Modal.Title>Editar</Modal.Title>
              </Modal.Header>
              <Alert key="danger" variant="danger" style={{ display: showAlert ? 'block' : 'none' }} className='m-2 mb-0'>
                ¿Esta seguro de eliminar este registro?<br/>
                <div className='row mt-1'>
                  <div className="col-3 p-1">
                    <Button variant="danger" className='w-100' onClick={() => { FnEliminarModal(); CloseEditarModal(); setShowAlert(false);}}>Confirmar</Button>
                  </div>
                  <div className="col-3 p-1">
                    <Button variant="secondary" className='w-100' onClick={() => setShowAlert(false)}>Cancelar</Button>
                  </div>
                </div>
              </Alert>
              <Modal.Body>
                <div className="row">
                    <InputTextUpd value={Nivel} campo="Nivel" name="Nivel" obligatorio="*" colsm="12" colmd="6" onChange={setNivel}/> {/* <<<<<< CAMBIO */}
                </div>
              </Modal.Body>
              <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                <div className='row mt-1'>
                  <div className="col-12 col-sm-6 col-md-3 p-1">
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 p-1">
                    <Button variant="secondary" onClick={() => {CloseEditarModal();setShowAlert(false);}} className='w-100'>Cerrar</Button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 p-1">
                    <Button variant="danger" className='w-100' onClick={() => {setShowAlert(true); setSelectedIdEliminar(selectedId);}}>Eliminar</Button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 p-1">
                    <Button variant="success" onClick={() => {FnActualizarModal();setShowAlert(false);}} className='w-100'>Guardar</Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
          {/* Modal Eliminar*/}
          <>
            <Modal show={ShowEliminar} onHide={CloseEliminarModal}>
              <Modal.Header closeButton style={{backgroundColor:'#dc3545', color:'White'}}>
                <Modal.Title>Eliminación</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Desea eliminar este registro?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={CloseEliminarModal}>
                  Cerrar
                </Button>
                <Button variant="danger" onClick={FnEliminarModal}>
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </>
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title >Detalle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="m-3">
                  {/* CARD BODY */}
                  <div className="mx-2">
                      <div className="row">
                      {detalle.map((header, headerIndex) => (
                        <div key={headerIndex} className="col-12">
                          <div className='row'>
                            <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                              {header.name}:&nbsp;&nbsp;
                            </div>
                            <div className='col-7 col-sm-7 col-md-9'>
                              {dataDetalle[header.bdname]}
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
                  </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
            </Modal.Footer>
          </Modal>
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}

// Define la función CustomDropdownToggle utilizando forwardRef
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});

// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShowDetalle, handleShowEditar,handleShowEliminar } = props;
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
       <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleShowDetalle(props.id)}>
          <i className="fas fa-search"></i> Detalle
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleShowEditar(props.id)}>
          <i className="fas fa-edit"></i> Editar
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleShowEliminar(props.id)}>
          <i className="fas fa-trash"></i> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
// Datos de detalle
async function ListarDatosDetalle(url, tabla, id, token2) {
    try{
      const response = await fetch(`${url}/${tabla}/${tabla}Detail/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token2}`
          },
          credentials: 'include',
      })
      if (response.ok) {
          const data = await response.json();
          if(data.res){
              const datos = {
                  ...data.datos,
                  fechact: data.datos.fechact.replace('T', ' '),
                  fechreg: data.datos.fechreg.replace('T', ' ')
              };
              return datos;
          }else{
              throw new Error(data.msg);
          }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
    }catch (error){
        throw error;
    }
}

// Datos del registro
async function UbicarRegistroModal(url, id, tabla, token2, setters) {
  try{
    const response = await fetch(`${url}/${tabla}/${tabla}Upd/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token2}`
        },
        credentials: 'include',
    })
    if(response.ok) {
        const data = await response.json();
        if(data.res){
            const datos = data.datos;
            setters.setNivel(datos.nivel);      // <<<<<< CAMBIO
            return datos;
        }else{
            throw new Error(data.msg);
        }
    }else{
        throw new Error(`${response.status} ${response.statusText}`);
    }
  }catch (error){
      throw error;
  }
}

export {ListadoViewCrudNivel};