import { useState, useEffect } from "react";

function InputComboBoxTipoDocumento(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '1', nombre: 'SIN RUC'},
            { id: '2', nombre: 'DNI'},
            { id: '3', nombre: 'CARNET DE EXTRANJERIA'},
            { id: '4', nombre: 'RUC'},
            { id: '5', nombre: 'PASAPORTE'},
            { id: '6', nombre: 'CED. DIPLOMATICA DE IDENTIDAD'},
            { id: '7', nombre: 'DOC. IDENT. PAIS. RESIDENCIA-NO.D'},
            { id: '8', nombre: 'TAX IDENTIFICATION NUMBER'},
            { id: '9', nombre: 'IDENTIFICATION NUMBER'},
        ];
        setListaComboBox(data);
    },[]);
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.nombre} >
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxTipoDocumento};