import { ListadoView } from "../../componentes/listado/ListadoViewMejoraComprobante";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";
const nombre_controller = 'Comprobantes';       // <<<<<< CAMBIO
const idprimary = 'id';                  // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO

// Rutas
const RutaHead = [
    { name: 'Comprobante', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'R.U.C.', bdname:'cliruc'},                // <<<<<< CAMBIO
    { name: 'Nombre', bdname: 'clinom'},                // <<<<<< CAMBIO
    { name: 'Tipo', bdname: 'nombre'},                  // <<<<<< CAMBIO
    { name: 'Monto Venta', bdname: 'totimporteventa' }, // <<<<<< CAMBIO
    { name: 'Fecha Emision', bdname: 'fechaemision' },  // <<<<<< CAMBIO
];

function ComprobanteList() {      // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    // Toast de Eliminación
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
            setToastMessage(storedMessage);
            setToastBackground(storedBackground);
            setShowToast(true);
            localStorage.removeItem('toastMessage1');
            localStorage.removeItem('toastBackground1');
        }
    }, []);
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <section>
                    <ListadoView url={url} tabla={nombre_controller} idprimary={idprimary} detalle={Detalle} />
                </section>
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                    style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}`,}}>
                    <Toast.Header>
                        <strong className="me-auto">Notificación</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
                <FooterView/>
            </div>
        </>
        
    );
}

export default ComprobanteList;   // <<<<<< CAMBIO


