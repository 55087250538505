import { ListadoViewCrudHabitacion } from "../../componentes/listado/ListadoViewCrudHabitacion";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";
const nombre_controller = 'Habitaciones';            // <<<<<< CAMBIO
const idprimary = 'idhabitacion';                    // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Habitacion', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Nombre', bdname: 'numero'},                 // <<<<<< CAMBIO
    { name: 'Categoria', bdname: 'categoria'},                 // <<<<<< CAMBIO
    { name: 'Detalle', bdname: 'detalle' },                   // <<<<<< CAMBIO
    { name: 'Fecha Registro', bdname: 'fechreg' },          // <<<<<< CAMBIO
    { name: 'Última Actualización', bdname: 'fechact' }     // <<<<<< CAMBIO
];

function HabitacionList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudHabitacion url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default HabitacionList;

