import { useState, useEffect } from "react";

function InputComboBoxTiponc(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '01', nombre: 'ANULACION DE OPERACION'},
            { id: '02', nombre: 'ANULACION POR ERROR EN EL RUC'},
            { id: '03', nombre: 'CORRECION POR ERROR EN LA DESCRIPCION'},
            { id: '04', nombre: 'DESCUENTO GLOBAL'},
            { id: '05', nombre: 'DESCUENTO POR ITEM'},
            { id: '06', nombre: 'DEVOLUCION TOTAL'},
            { id: '07', nombre: 'DEVOLUCION POR ITEM'},
            { id: '08', nombre: 'BONIFICACION'},
            { id: '09', nombre: 'DISMINUCION EN EL VALOR'},
            { id: '10', nombre: 'OTROS'},
        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const IdValue = e.target.value;
        const dataValue = ListarDatos.find((dato) => dato.id === IdValue);
        props.onChange(IdValue, dataValue ? dataValue.nombre : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
        </div>
    );
}

export {InputComboBoxTiponc};