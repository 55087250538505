import { ListadoView } from "../../componentes/listado/ListadoViewTabs";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'

const nombre_controller = 'Usuarios';            // <<<<<< CAMBIO
const idprimary = 'id';                    // <<<<<< CAMBIO
const url = 'http://192.168.0.103:8000/api'     // <<<<<< CAMBIO
const cabeceraTable = [
    { name: '#', bdname: 'id'},                        // <<<<<< CAMBIO
    { name: 'Nombre', bdname: 'usunom'},      // <<<<<< CAMBIO
    { name: 'Apellido', bdname: 'usuape'},      // <<<<<< CAMBIO
    { name: 'Codigo', bdname: 'usucod' },                 // <<<<<< CAMBIO
    { name: 'Email', bdname: 'usuario' },                 // <<<<<< CAMBIO
    { name: 'Última Actualización', bdname: 'fechact' }         // <<<<<< CAMBIO
];
// Rutas
const RutaHead = [
    { name: 'Usuario', ruta: `/${nombre_controller}/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Nombre', bdname: 'usunom'},                 // <<<<<< CAMBIO
    { name: 'Apellido', bdname: 'usuape'},                 // <<<<<< CAMBIO
    { name: 'Codigo', bdname: 'usucod' },                   // <<<<<< CAMBIO
    { name: 'Email', bdname: 'usuario' },             // <<<<<< CAMBIO
    { name: 'User', bdname: 'user' },         // <<<<<< CAMBIO
    //{ name: 'Password', bdname: 'password' },           // <<<<<< CAMBIO
    { name: 'Estado', bdname: 'isactivo' },                // <<<<<< CAMBIO
    { name: 'Ultima Sesión', bdname: 'last_sesion' },          // <<<<<< CAMBIO
    { name: 'Módulo', bdname: 'idmodulo' },     // <<<<<< CAMBIO
    { name: 'Fecha Registro', bdname: 'fechreg' },     // <<<<<< CAMBIO
    { name: 'Última Actualización', bdname: 'fechact' },     // <<<<<< CAMBIO
];

function UsuarioList() {      // <<<<<< CAMBIO
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        ListarDatos(nombre_controller)
            .then(function (data) {
                setData(data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }, []);
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    // Toast de Eliminación
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
          setToastMessage(storedMessage);
          setToastBackground(storedBackground);
          setShowToast(true);
          // Limpia el localStorage después de leer los datos
          localStorage.removeItem('toastMessage1');
          localStorage.removeItem('toastBackground1');
        }
      }, []);
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        {loading ? (
                            <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                        ) : (
                            <ListadoView url={url} tabla={nombre_controller} cabeceraTable={cabeceraTable} cuerpoTable={data} idprimary={idprimary} detalle={Detalle}/>   // <<<<<< CAMBIO
                        )}
                    </section>
                    <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                        style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}`,}}>
                        <Toast.Header>
                            <strong className="me-auto">Notificación</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                </div>
                <FooterView/>
            </div>
        </>
        
    );
}

function ListarDatos(controlador) {
    return fetch(`${url}/${controlador}/${controlador}List`, { credentials: 'include' })
        .then(response => {
            if (!response.ok) {
                throw new Error("Error en la respuesta del servidor");
            }
            return response.json();
        })
        .then(data => {
            const datos = data.datos.map(item => ({
                ...item,
                fechact: item.fechact.replace('T', ' ')
            }));
            return datos;
        })
        .catch(error => {
            throw error;
        });
}


export default UsuarioList;   // <<<<<< CAMBIO


