import { ListadoViewCrudMovimiento } from "../../componentes/listado/ListadoViewCrudMovimiento";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Movimientos';            // <<<<<< CAMBIO
const idprimary = 'idmovimiento';                    // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Movimiento', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Codigo', bdname: 'idmovimiento'},              // <<<<<< CAMBIO
    { name: 'Tipo Movimiento', bdname: 'movimientotipo'},            // <<<<<< CAMBIO
    { name: 'Almacen', bdname: 'almacen'},            // <<<<<< CAMBIO
    { name: 'Item', bdname: 'itemnom' },              // <<<<<< CAMBIO
    { name: 'Cantidad', bdname: 'itemcant' },         // <<<<<< CAMBIO
    { name: 'P.U. Compra', bdname: 'itemcprapu' }, // <<<<<< CAMBIO
    { name: 'P.U. Venta', bdname: 'itemvtapu' }, // <<<<<< CAMBIO
    { name: 'Valor Compra', bdname: 'itemcpravc' },    // <<<<<< CAMBIO 
    { name: 'Valor Venta', bdname: 'itemvtavv' },    // <<<<<< CAMBIO 
    { name: 'Observacion', bdname: 'itemobs' },    // <<<<<< CAMBIO 
    { name: 'Usuario', bdname: 'usuario' },           // <<<<<< CAMBIO
    { name: 'Fecha Registro', bdname: 'fechreg' },  // <<<<<< CAMBIO
];

function MovimientoList() {      // <<<<<< CAMBIO
    //Toast
    // const [showToast, setShowToast] = useState(false);
    // const [toastMessage, setToastMessage] = useState('');
    // const [toastBackground , setToastBackground ] = useState('#198754');
    // useEffect(() => {
    //     const storedMessage = localStorage.getItem('toastMessage1');
    //     const storedBackground = localStorage.getItem('toastBackground1');
    //     if (storedMessage && storedBackground) {
    //       setToastMessage(storedMessage);
    //       setToastBackground(storedBackground);
    //       setShowToast(true);
    //       localStorage.removeItem('toastMessage1');
    //       localStorage.removeItem('toastBackground1');
    //     }
    // }, []);
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudMovimiento url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default MovimientoList;

