import '../Assets/css/styleLogin.css'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from "react-google-recaptcha";
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userReducer';
//import { jwtDecode } from 'jwt-decode';
import API_BASE_URL  from "../config";

const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
function Login() {
    const [Ruc, setRuc] = useState('');                 // <<<<<< CAMBIO
    const [Usuario, setUsuario] = useState('');         // <<<<<< CAMBIO
    const [Password, setPassword] = useState('');       // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //CAPTCHA
    // const [userValidate, setuserValidate] = useState(false);
    //const captcha = useRef(null);
    // const ChangeCaptcha = () => {
    //     if(captcha.current.getValue()){
    //         setuserValidate(true);
    //     }
    // }
    //REDUX
    const dispatch = useDispatch();
    //INICIO DE SESION
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const FnIniciarSesion = async() => {
        setLoading(true);
        const jsonData = {
            Ruc: Ruc,                       // <<<<<< CAMBIO
            Usuario: Usuario,               // <<<<<< CAMBIO
            Password: Password,             // <<<<<< CAMBIO
        };
        try {
            const response = await fetch(`${url}/Sesion/IniciarSesion`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(jsonData),
            });
            if (response.ok) {
                const data = await response.json();
                //console.log("sesion: ",data);
                if(data.res){
                    localStorage.setItem('token-dincors', data.token.original.access_token);
                    localStorage.setItem('toastMessage1', data.msg);
                    dispatch(setUser({isLogIn:true, userName:data.datos.nombre, userId:data.datos.id, 
                        companyId:data.datos.idempresa, companyRuc:data.datos.empruc, companyName:data.datos.emprs, 
                        idRol:data.datos.idrol, apiKey:data.datos.apikey}));//asignar el nuevo usuario en el store de reducer
                    //localStorage.setItem('login-usuario', data.datos.nombre);
                    localStorage.setItem('toastBackground1', '#198754');
                    if(data.datos.idrol===1 || data.datos.idrol===3){
                        setTimeout(() => {
                            setLoading(false);
                            navigate(`/AlquileresList`);
                        }, 800);
                    }else{
                        setTimeout(() => {
                            setLoading(false);
                            navigate(`/MovimientosList`);
                        }, 800);
                    }
                    // setToastBackground('#198754');
                    // setToastMessage(data.msg);
                    // setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch (error) {
            setLoading(false);
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // //Verificar token
    // const FnValidarSesion = async() => {
    //     const token = localStorage.getItem('token-dincors');
    //     try {
    //         //const response = await fetch(`${url}/auth/me`, {
    //         const response = await fetch(`${url}/Sesion/ValidarSesion`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json; charset=UTF-8',
    //                 'Authorization': `Bearer ${token}` // Enviar el token en el encabezado de autorización
    //             },
    //             body: JSON.stringify({}),
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             if(data.res){
    //                 setToastBackground('#198754');
    //                 setToastMessage(data.msg);
    //                 setShowToast(true);
    //             }else{
    //                 throw new Error(data.msg);
    //             }
    //         } else {
    //             throw new Error(`${response.status} ${response.statusText}`);
    //         }
    //     } catch (error) {
    //         setToastBackground('#CC6868');
    //         setToastMessage(error.message);
    //         setShowToast(true);
    //     }
    // };
    // //Cerrar token
    // const FnCerrarSesion = async() => {
    //     const token = localStorage.getItem('token-dincors');
    //     try {
    //         //const response = await fetch(`${url}/auth/me`, {
    //         const response = await fetch(`${url}/Sesion/CerrarSesion`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json; charset=UTF-8',
    //                 'Authorization': `Bearer ${token}` // Enviar el token en el encabezado de autorización
    //             },
    //             body: JSON.stringify({}),
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             if(data.res){
    //                 localStorage.removeItem('token-dincors');
    //                 setToastBackground('#198754');
    //                 setToastMessage(data.msg);
    //                 setShowToast(true);
    //             }else{
    //                 throw new Error(data.msg);
    //             }
    //         } else {
    //             throw new Error(`${response.status} ${response.statusText}`);
    //         }
    //     } catch (error) {
    //         setToastBackground('#CC6868');
    //         setToastMessage(error.message);
    //         setShowToast(true);
    //     }
    // };
    // Definir la función checkEnter
    const checkEnter = (event, FnIniciarSesion) => {
        if (event.key === 'Enter') {
            FnIniciarSesion();
        }
    };
    // ====================== MODAL 2FA ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [showDobleFactor, setShowDobleFactor] = useState(false);
    const CloseDobleFactor = () => setShowDobleFactor(false);
    // const handleShowDobleFactor = () => {
    //     setShowDobleFactor(true);
    // };
    // Función de verificar token
    const [VerificarToken, setVerificarToken] = useState('');       // <<<<<< CAMBIO
    const FnVerificarToken = () => {
        const formData = new FormData();
        formData.append('Email', Usuario); 
        formData.append('Token', VerificarToken);                // <<<<<< CAMBIO
        return fetch(`${url}/Sesion/VerificarToken`, {
            method: 'POST',
            body: formData,
        })
        .then(function (result) {   // Respuesta 
            if (result.ok) {
                return result.json();   // Retorno tipo json
            }
        })
        .then(function (data) {
            console.log(data)
            if(data.res === "200"){
                navigate(`/AlquilerList`);
                setToastBackground('#198754');
                setToastMessage(data.msg);
                setShowToast(true);
            }else{
                setToastMessage(data.msg);
                setShowToast(true);
                setToastBackground('#CC6868');
            }
        })
        .catch(function (error) {
            throw error;
        });
    };
    // Toast de Eliminación
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
            setToastMessage(storedMessage);
            setToastBackground(storedBackground);
            setShowToast(true);
            localStorage.removeItem('toastMessage1');
            localStorage.removeItem('toastBackground1');
        }
    }, []);
    return (
        <div className="container-login">
            <div className="logoDincors"><img src={require('../Assets/img/IMAGOTIPO_VERDE3.png')} alt="Logo de la empresa" width="200px" /></div>
            {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
            ) : (
                <div className="box">
                    <span className="borderLine"></span>
                    <form>
                        <h2>BIENVENIDO</h2>
                        <div className="inputBox">
                            <input id="txtRuc" name="txtRuc" type="text" required="required" 
                                onKeyDown={(event) => checkEnter(event, FnIniciarSesion)} onChange={(event) => setRuc(event.target.value)}/>
                            <span>Ruc</span>
                            <i></i>
                        </div>
                        <div className="inputBox">
                            <input id="txtUsuario" name="txtUsuario" type="text" required="required" 
                                onKeyDown={(event) => checkEnter(event, FnIniciarSesion)} onChange={(event) => setUsuario(event.target.value)}/>
                            <span>Usuario</span>
                            <i></i>
                        </div>
                        <div className="inputBox">
                            <input id="txtPassword" name="txtPassword" type="password" required="required" 
                                onKeyDown={(event) => checkEnter(event, FnIniciarSesion)} onChange={(event) => setPassword(event.target.value)}/>
                            <span>Password</span>
                            <i></i>
                        </div>
                        {/* <div className="links">
                            <a href="/">¿Olvido su contraseña?</a>
                            <a href="/">Registrarse</a>
                        </div> */}
                        <div className='mt-5'>

                        </div>
                        {/* <div className='recaptcha mt-3' style={{display:'flex', justifyContent:'center'}}>
                            <ReCAPTCHA ref={captcha} sitekey="6Ldw5A0pAAAAAL1cTvisBPi2TIKlzJ2HIz7YlUxg" onChange={ChangeCaptcha}/>
                        </div> */}
                        <div className='mt-4'>
                            <Link onClick={FnIniciarSesion}>
                                <button className="btn btn-success din-btn-style w-100">Iniciar Sesión</button>
                            </Link>
                        </div>
                        {/* <div className='mt-2'>
                            <Link onClick={FnValidarSesion}>
                                <button className="btn btn-success din-btn-style w-100">Validar Sesión</button>
                            </Link>
                        </div>
                        <div className='mt-2'>
                            <Link onClick={FnCerrarSesion}>
                                <button className="btn btn-success din-btn-style w-100">Cerrar Sesión</button>
                            </Link>
                        </div> */}
                    </form>
                </div>
            )}
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white' 
            style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            {/* Modal Token*/}
            <Modal show={showDobleFactor} onHide={CloseDobleFactor} size="md">
                <Modal.Header className='bg-success text-white' closeButton>
                    <Modal.Title>AUTENTICACIÓN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="">
                    {/* CARD BODY */}
                    <div className="mx-2">
                        <div className="row">
                            <div className={`col-12 col-sm-12 col-md-12 `}>
                                <label htmlFor={`txtVerificarToken`} className="form-label">Ingrese el token de verificación</label>
                                <div className="input-group mb-3">
                                    <input id={`txtVerificarToken`} className="form-control" onChange={(event) => setVerificarToken(event.target.value)}/>
                                    <span className="input-group-text din-input-text-modal"><i className={`fa-solid fa-key`}></i></span>
                                </div>
                                <span id={`VerificarTokenError`} className="text-danger"></span>
                            </div>      
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={CloseDobleFactor}>
                    <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                </button>
                <Link onClick={FnVerificarToken}>
                    <button className="btn btn-success din-btn-style">
                    <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Verificar
                    </button>
                </Link>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Login;


