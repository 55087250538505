import { useState, useEffect } from "react";

async function ListarCombobox(name, url) {
    try {
      const response = await fetch(`${url}/ComboBox/${name}Combo`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error("Error en la respuesta del servidor");
      }
      const data = await response.json();
    //   console.log(data)
      return data.datos;
    } catch (error) {
      //console.log(error);
      throw error;
    }
}
function InputComboBox(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        ListarCombobox(props.name, props.url)
            .then(function (data) {
                setListaComboBox(data);
            })
            .catch(function (error) {
                // console.log(error);
            });
      }, [props.name, props.url]);
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id} >
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBox};