import { ListadoViewCrudHistorial } from "../../componentes/listado/ListadoViewCrudHistorial";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';

const nombre_controller = 'Alquileres';            // <<<<<< CAMBIO
const idprimary = 'idalquiler';                    // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Alquiler', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Numero Alquiler', bdname: 'idalquiler'},              // <<<<<< CAMBIO
    { name: 'Numero Habitacion', bdname: 'numero'},            // <<<<<< CAMBIO
    { name: 'Tipo Documento', bdname: 'txttipodocumento'},            // <<<<<< CAMBIO
    { name: 'Numero Documento', bdname: 'huesped_documento' },              // <<<<<< CAMBIO
    { name: 'Cliente', bdname: 'huesped_nombre' },         // <<<<<< CAMBIO
    { name: 'Comprobante', bdname: 'nombre' },         // <<<<<< CAMBIO
    { name: 'Duracion', bdname: 'cantidad_duracion' }, // <<<<<< CAMBIO
    { name: 'Unidad', bdname: 'txtduracion' }, // <<<<<< CAMBIO
    { name: 'Monto Total', bdname: 'aloj_monto_total' },    // <<<<<< CAMBIO 
    { name: 'Incidente', bdname: 'isincidente' },    // <<<<<< CAMBIO 
    { name: 'Estado', bdname: 'idestado' },    // <<<<<< CAMBIO 
    { name: 'Fecha Checkin', bdname: 'aloj_checkin' },           // <<<<<< CAMBIO
    { name: 'Fecha Checkout', bdname: 'aloj_checkout' },  // <<<<<< CAMBIO
];

function AlquilerHistorial() {      // <<<<<< CAMBIO
    //Toast
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
          setToastMessage(storedMessage);
          setToastBackground(storedBackground);
          setShowToast(true);
          localStorage.removeItem('toastMessage1');
          localStorage.removeItem('toastBackground1');
        }
    }, []);
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudHistorial url={url} tabla={nombre_controller} idprimary={idprimary} detalle={Detalle} />  
                    </section>
                </div>
                <FooterView/>
            </div>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </>
    );
}

export default AlquilerHistorial;

