import { useState, useEffect } from "react";

function InputComboBoxDuracion(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '1', nombre: '01 DIAS'},
            { id: '2', nombre: '02 HORAS'},
            { id: '3', nombre: '03 DIAS AUTORIZAR'},
            { id: '4', nombre: '04 HORAS AUTORIZAR'},
        ];
        setListaComboBox(data);
    },[]);
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.nombre} >
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxDuracion};