import { ListadoViewCrudCategoria } from "../../componentes/listado/ListadoViewCrudCategoria";  // <<<<<< CAMBIO
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Categorias';           // <<<<<< CAMBIO
const idprimary = 'idcategoria';                  // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Categoria', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'ID', bdname: 'idcategoria'},            // <<<<<< CAMBIO
    { name: 'Categoria', bdname: 'categoria'},      // <<<<<< CAMBIO
    { name: 'Descripcion', bdname: 'descripcion'},  // <<<<<< CAMBIO
    { name: 'Ultima actualizacion', bdname: 'fechact' }, // <<<<<< CAMBIO
];

function CategoriaList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudCategoria url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  {/* <<<<<< CAMBIO */}
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default CategoriaList;  // <<<<<< CAMBIO

