import { useState, useEffect } from "react";

function InputComboBoxDiaSemana(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '0', nombre: '00 DOMINGO'},
            { id: '1', nombre: '01 LUNES'},
            { id: '2', nombre: '02 MARTES'},
            { id: '3', nombre: '03 MIERCOLES'},
            { id: '4', nombre: '04 JUEVES'},
            { id: '5', nombre: '05 VIERNES'},
            { id: '6', nombre: '06 SABADO'},
        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const TextSemana = e.target.value;
        const TextPardia = ListarDatos.find((dato) => dato.nombre === TextSemana);
        props.onChange(TextSemana, TextPardia ? TextPardia.id : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.nombre}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxDiaSemana};