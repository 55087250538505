// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { InputAreaText } from "../../componentes/editar/InputAreaText";

const nombre_controller = 'Comprobantes';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Comprobante', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Edicion de Comprobante', ruta: `/${nombre_controller}Upd` },  // <<<<<< CAMBIO
];
function ComprobanteDetail() {
    const { id } = useParams();
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    const [ApiKey, setApiKey] = useState(user.apiKey);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
        setApiKey(user.apiKey);
    }, [user]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //Comprobante
    const navigate = useNavigate();
    const [Idcajaapertura, setIdcajaapertura] = useState('');                 // <<<<<< CAMBIO
    const [TotalVenta, setTotalVenta] = useState(0.00);
    const [DataVentasItem, setDataVentasItem]=useState([]);
    const [DatosComprobante, setDatosComprobante]=useState([]);
    // Venta total
    useEffect(() => {
        if (DataVentasItem && DataVentasItem.length > 0) {
            const initialTotal = DataVentasItem.reduce((acc, data) => {
                if (data.Itemcodigo === "SERV") {
                    // Sumar solo el PrecioUnitario (convertir a número antes de sumar)
                    return acc + parseFloat(data.PrecioUnitario);
                } else {
                    // Sumar PrecioUnitario multiplicado por CantidadItem (convertir a número antes de multiplicar)
                    return acc + (parseFloat(data.PrecioUnitario) * parseFloat(data.CantidadItem));
                }
            }, 0);
    
            setTotalVenta(initialTotal);
        } else {
            setTotalVenta(0);
        }
    }, [DataVentasItem]);
    
    // Guardar venta
    const [loadingSearch, setLoadingSearch] = useState(false);
    const FnPresentar = async () => {
        console.log('fac')
        const jsonData = {
            Idfactura: id,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        //console.log("datpres: ", jsonData)
        try {
            setLoadingSearch(true);
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Presentar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("presentar: ",data) //respuesta del json de facturacion
                setIddeclaracion(data.iddeclaracion);
                if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/ComprobantesUpd/${id}`);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setTimeout(() => {
                setLoadingSearch(false);
            }, 1000);   
        }
    };

    // Clonar factura
    // Tiempo
    const obtenerFechaHoraActual = () => {
        const ahora = new Date(); // Obtiene la fecha y hora actual
        const year = ahora.getFullYear();
        const month = String(ahora.getMonth() + 1).padStart(2, '0');
        const day = String(ahora.getDate()).padStart(2, '0');
        const hours = String(ahora.getHours()).padStart(2, '0');
        const minutes = String(ahora.getMinutes()).padStart(2, '0');
        const fechaHoraFormateada = `${year}-${month}-${day} ${hours}:${minutes}`;
        return fechaHoraFormateada;
    };
    const FnClonarFactura = async () => {
        const jsonData = {
            Idfactura: id,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
            FechaEmision: obtenerFechaHoraActual(),
        };
        const token = localStorage.getItem('token-dincors');
        //console.log("json: ", jsonData)
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Clonar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("clonar: ",data) //respuesta del json de facturacion
                if(data.res){
                    //setToastBackground('#198754');
                    //setToastMessage(data.msg);
                    //setShowToast(true);
                    navigate(`/ComprobantesDetail/${data.idfactura}`);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    const [Iddeclaracion, setIddeclaracion] = useState(0);
    useEffect(() => {
        const DataComprobante = async ()=>{
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                Idfactura: id,
                Idempresa: user.companyId, // <<<<<< CAMBIO
                Codusuact: user.userId,
            };
            try {
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Upd`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){
                    const data = await response.json();
                    console.log("ubicar: ", data)
                    if(data.res){
                        setIddeclaracion(data.iddeclaracion);
                        setDatosComprobante(data.datos);
                        setIdcajaapertura(data.idcajaapertura);
                        setDataVentasItem(data.ventas);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }
        DataComprobante();
        // eslint-disable-next-line
    }, [id]);
    useEffect(() => {
        setToastMessage('Verifique los datos de comprobante.');
        setToastBackground('#198754');
        setShowToast(true);
    }, []);

    // DESCARGAR PDF COMPROBANTE
    const handleExportToPDF = async () => {
        try {
            if(Iddeclaracion===0){
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo PDF.');
            }
            const response = await fetch(`https://efactura.jymsystems.com/api/DescargarFactura/pdf/${Iddeclaracion}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'apikey': `${ApiKey}`,
                },
                //credentials: 'include',
            });
            let data;
            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && 
                    (contentType.includes('application/pdf') ||
                    contentType.includes('application/xml') ||
                    contentType.includes('text/xml') ||
                    contentType.includes('application/zip'))) {
                data = await response.blob();
                } else {
                data = await response.json();
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            if (data instanceof Blob) {
                const contentType = response.headers.get('content-type');
                let fileExtension = '';
                if (contentType.includes('application/pdf')) {
                fileExtension = 'pdf';
                } else if (contentType.includes('application/xml') || contentType.includes('text/xml')) {
                fileExtension = 'xml';
                } else if (contentType.includes('application/zip')) {
                fileExtension = 'zip';
                }
                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${DatosComprobante.fac_documento}.${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setToastBackground('#198754');
                setToastMessage('Exportacion generada con exito');
                setShowToast(true);
            } else {
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo PDF.');
            }
        } catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };

    // DESCARGAR XML COMPROBANTE
    const handleExportToXML = async () => {
        try {
            if(Iddeclaracion===0){
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo XML.');
            }
            const response = await fetch(`https://efactura.jymsystems.com/api/DescargarFactura/xml/${Iddeclaracion}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'apikey': `${ApiKey}`,
                },
                //credentials: 'include',
            });
        
            let data;
            if (response.ok) {
              const contentType = response.headers.get('content-type');
              if (contentType && 
                  (contentType.includes('application/pdf') ||
                   contentType.includes('application/xml') ||
                   contentType.includes('text/xml') ||
                   contentType.includes('application/zip'))) {
                data = await response.blob();
              } else {
                data = await response.json();
              }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            if (data instanceof Blob) {
                const contentType = response.headers.get('content-type');
                let fileExtension = '';
                if (contentType.includes('application/pdf')) {
                    fileExtension = 'pdf';
                } else if (contentType.includes('application/xml') || contentType.includes('text/xml')) {
                    fileExtension = 'xml';
                } else if (contentType.includes('application/zip')) {
                    fileExtension = 'zip';
                }
                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${DatosComprobante.fac_documento}.${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setToastBackground('#198754');
                setToastMessage('Exportacion generada con exito');
                setShowToast(true);
            } else {
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo XML.');
            }
        } catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };

    // DESCARGAR CDR COMPROBANTE
    const handleExportToCDR = async () => {
        try {
            if(Iddeclaracion===0){
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo CDR.');
            }
            const response = await fetch(`https://efactura.jymsystems.com/api/DescargarFactura/cdr/${Iddeclaracion}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'apikey': `${ApiKey}`,
                },
                //credentials: 'include',
            });
        
            let data;
            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && 
                    (contentType.includes('application/pdf') ||
                    contentType.includes('application/xml') ||
                    contentType.includes('text/xml') ||
                    contentType.includes('application/zip'))) {
                    data = await response.blob();
                } else {
                    data = await response.json();
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            if (data instanceof Blob) {
                const contentType = response.headers.get('content-type');
                let fileExtension = '';
                if (contentType.includes('application/pdf')) {
                    fileExtension = 'pdf';
                } else if (contentType.includes('application/xml') || contentType.includes('text/xml')) {
                    fileExtension = 'xml';
                } else if (contentType.includes('application/zip')) {
                    fileExtension = 'zip';
                }
                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${DatosComprobante.fac_documento}.${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setToastBackground('#198754');
                setToastMessage('Exportacion generada con exito');
                setShowToast(true);
            } else {
                throw new Error('Operacion Detenida. No se pudo recuperar el archivo CDR.');
            }
        } catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };

    // DESCARGAR CDR COMPROBANTE
    const handleImprimirComprobante = async () => {
        const jsonData = {
            Idfactura: id,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}ImprimirComprobante`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("imprimir: ",data) 
                if(data.res){
                    let rutaImprimir='ImprimirFactura'
                    if(DatosComprobante.nombre==='TICKET'){
                        rutaImprimir='ImprimirVenta'
                    }else{
                        rutaImprimir='ImprimirFactura'
                    }
                    console.log('ruta: ',rutaImprimir)
                    const response2 = await fetch(`http://localhost:8080/${rutaImprimir}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                        },
                        body: JSON.stringify(data.reporte),
                    });
                    if(response2.ok){
                        setToastBackground('#198754');
                        setToastMessage(data.msg);
                        setShowToast(true);
                    }else{
                        throw new Error('Operacion Detenida. Error al conectar con la impresion.');
                    }
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    
    // NOTA DE CREDITO
    // Agrega un estado para controlar la visibilidad del modal
    const [showBaja, setShowBaja] = useState(false);
    const handleCloseBaja = () => setShowBaja(false);
    const handleShowBaja = () => {
        setShowBaja(true);
    };
    // ====================== DE BAJA ======================
    const [Idtiponc] = useState('01'); // <<<<<< CAMBIO
    const [Motivonc, setMotivonc] = useState(''); // <<<<<< CAMBIO
    const FnGenerarNotaCredito = async () => {
        const jsonData = {
            Idfactura: id,
            Idtiponc: Idtiponc,
            Motivonc: Motivonc,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        //console.log("datpres: ", jsonData)
        try {
            setLoadingSearch(true);
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}NotaCredito`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("nota de credito: ",data) //respuesta del json de facturacion
                if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/ComprobantesList`);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setTimeout(() => {
                setLoadingSearch(false);
            }, 1000);   
        }
    };

    const FnPresentarNC = async () => {
        const jsonData = {
            Idfactura: id,
            Idempresa: Idempresa,
            //Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            console.log("baja: ",jsonData)
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}PresentarNC`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(jsonData),
            })
            if(response.ok){
              const data = await response.json();
              setIddeclaracion(data.iddeclaracion);
              if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/ComprobantesUpd/${id}`);
                //   setToastBackground('#198754');
                //   setToastMessage(data.msg);
                //   setShowToast(true);
              }else{
                  setToastMessage(data.msg);
                  setShowToast(true);
                  setToastBackground('#CC6868');
              }
            }
        }catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            handleCloseBaja();
        };
    };

    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS COMPROBANTE</h5>
                <div className="card-body mx-2">
                    <div className="row border">
                        <div className="col-12 col-sm-12 col-md-12 text-center p-1">
                            <div className="row pt-1">
                                <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                    <button className="btn btn-outline-primary din-btn-style w-100" onClick={handleExportToPDF}><i className="fa-solid fa-file-arrow-down"></i>&nbsp;&nbsp;PDF</button>
                                </Link>
                                <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                    <button className="btn btn-outline-primary din-btn-style w-100" onClick={handleExportToXML}><i className="fa-solid fa-file-arrow-down"></i>&nbsp;&nbsp;XML</button>
                                </Link>
                                <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                    <button className="btn btn-outline-primary din-btn-style w-100" onClick={handleExportToCDR}><i className="fa-solid fa-file-arrow-down"></i>&nbsp;&nbsp;CDR</button>
                                </Link>
                                { DatosComprobante.estado==="ANULADO" || DatosComprobante.tipo==="07" ?
                                    null
                                :
                                <>
                                    <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                        <button className="btn btn-outline-primary din-btn-style w-100" onClick={handleShowBaja}>
                                            <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Nota de Credito
                                        </button>
                                    </Link>
                                </>  
                                }
                                { DatosComprobante.estado==="PRESENTADO" ?
                                    <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                        <button className="btn btn-outline-primary din-btn-style w-100" onClick={handleImprimirComprobante}><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Imprimir</button>
                                    </Link>
                                :
                                    <Link className="col-12 col-sm-6 col-md-2 mb-1">
                                        <button className="btn btn-outline-primary din-btn-style w-100" disabled><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Imprimir</button>
                                    </Link>
                                }
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="row border">
                        <div className="col-12 col-md-6 text-center p-1">
                            {
                                Idempresa === 2 ?
                                    // <img src={require('../../Assets/img/logo_inversiones_cabana_china.png')} alt="Logo de la empresa" width="250" className='mx-auto'/>
                                    <img src={require('../../Assets/img/Logo_Royal_2.jpeg')} alt="Logo de la empresa" width="250" className='mx-auto'/>
                                :
                                    <img src={require('../../Assets/img/Logo_Villaran_3.jpeg')} alt="Logo de la empresa" width="250" className='mx-auto'/>
                            }
                        </div>
                        <div className="col-12 col-md-6 text-center p-4 border-start d-flex flex-column justify-content-center align-items-center">
                            <div className="texto-grande-x" style={{fontWeight:'700'}}>{DatosComprobante.nombre} DE VENTA ELECTRONICA</div>
                            <div className="texto-grande-x" style={{fontWeight:'700'}}>{DatosComprobante.fac_documento}</div>
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Fecha Emision</label>
                                    <label className="form-control border-0">{DatosComprobante.fechaemision}</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Fecha Vencimiento</label>
                                    <label className="form-control border-0">{DatosComprobante.fechaemision}</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Moneda</label>
                                    <label className="form-control border-0">[{DatosComprobante.moneda}]</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Estado</label>
                                    <label className="form-control border-0">
                                        {/* <span className={`texto-xs badge ${['PENDIENTE', ].includes(DatosComprobante.estado) ? 'bg-danger' : 'bg-success'}`}>{DatosComprobante.estado}</span> */}
                                        <span className={`texto-xs badge ${{
                                                'ANULADO': 'bg-warning',
                                                'PENDIENTE': 'bg-danger'
                                              }[DatosComprobante.estado] || 'bg-success'}`}>
                                                {DatosComprobante.estado}
                                        </span>
                                    </label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Cliente</label>
                                    <label className="form-control border-0">[{DatosComprobante.cliruc}] {DatosComprobante.clinombre}</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Direccion</label>
                                    <label className="form-control border-0">{DatosComprobante.clidireccion}</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">I.G.V.</label>
                                    <label className="form-control border-0">{DatosComprobante.igv} %</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Medio de Pago</label>
                                    <label className="form-control border-0">
                                        {{'999': 'Otros medio de pago',
                                        '001': 'Deposito en cuenta',
                                        '002': 'Giro',
                                        '003': 'Transferencia de fondos',
                                        '004': 'Orden de pago',
                                        '005': 'Tarjeta de debito'
                                        }[DatosComprobante.mediopago]}
                                    </label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Forma de Pago</label>
                                    <label className="form-control border-0">{DatosComprobante.formapago}</label>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 mt-2">
                                    <label className="form-control border-0 texto-mediano pb-0">Notas</label>
                                    <label className="form-control border-0">{DatosComprobante.notas ? DatosComprobante.notas : '-'}</label>
                                </div>
                                {DatosComprobante.tipo === '07' ? (
                                    <>
                                        <div className="col-12 col-sm-6 col-md-4 mt-2">
                                            <label className="form-control border-0 texto-mediano pb-0">Observaciones</label>
                                            <label className="form-control border-0">{DatosComprobante.observaciones}</label>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 mt-2">
                                            <label className="form-control border-0 texto-mediano pb-0">Comprobante Relacionado</label>
                                            <label className="form-control border-0">{DatosComprobante.ncrefnombre}</label>
                                        </div>
                                    </>
                                ) : (
                                    DatosComprobante.nctipo === '01' ? (
                                        <>
                                            {/* <div className="col-12 col-sm-6 col-md-4 mt-2">
                                                <label className="form-control border-0 texto-mediano pb-0">Observaciones</label>
                                                <label className="form-control border-0">{DatosComprobante.observaciones}</label>
                                            </div> */}
                                            <div className="col-12 col-sm-6 col-md-4 mt-2">
                                                <label className="form-control border-0 texto-mediano pb-0">Nota de Credito</label>
                                                <label className="form-control border-0">{DatosComprobante.ncrefnombre}</label>
                                            </div>
                                        </>
                                    ) : null
                                    
                                )
                                }
                                
                            </div>
                        </div>
                    </div>
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Productos</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    <div className="row" style={{padding: "0px 10.5px"}}>
                        {DataVentasItem.map((data, index) => (
                            <div className={`col-12 border-bottom border-start border-end ${index === 0 ? 'border-top' : ''} p-2`} key={index}>
                                <div className='row'>
                                    <div className='col-10 col-sm-10 col-md-10'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {data.Itemcodigo} {data.Itemdesc}
                                                &nbsp;|&nbsp;<span className={`text-black texto-mediano`}>VENTA</span>
                                            </div>
                                            <div className='col-12 texto-lg'>
                                                <span className='texto-lg' style={{ fontWeight: '600' }}>{parseFloat(data.CantidadItem).toFixed(2)}</span> UNIDAD&nbsp;&nbsp;
                                                <span className={`texto-xs badge bg-success`}>PAGADO</span>&nbsp;&nbsp;
                                                <span className={`text-black texto-xs badge bg-warning`}>{Idcajaapertura}</span>
                                                &nbsp;&nbsp;<span className={`text-black texto-mediano`}>
                                                    {{'1000': 'OPERACION GRAVADO',
                                                    '9996': 'OPERACION GRATUITO',
                                                    '9997': 'OPERACION EXONERADA',
                                                    '9998': 'OPERACION INAFECTA',
                                                    }[data.TributoCodigo]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2 col-sm-2 col-md-2'>
                                        <div className='row '>
                                            <div className='col-12 text-end'>
                                                {/* <Link className='text-danger' onClick={() => handleShowRetirarVentaModal(index)}>
                                                    <i className="fas fa-trash fa-lg"></i>
                                                </Link> */}
                                            </div>
                                            <div className='col-12 text-end texto-lg' style={{ fontWeight: '600' }}>
                                                { data.Itemcodigo === 'SERV' ?
                                                    parseFloat(data.PrecioUnitario).toFixed(2)
                                                :
                                                    parseFloat(data.CantidadItem*data.PrecioUnitario).toFixed(2)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row mt-2' style={{ marginRight:'1px' }}>
                        <div className='col-10 text-end' style={{ fontWeight: '600' }}>Total</div>
                        <div className='col-2 text-end' style={{ fontWeight: '600'}}>{TotalVenta.toFixed(2)}</div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    <Link to={DatosComprobante.tipo === '07' ? `/${nombre_controller}List` : `/${nombre_controller}Upd/${id}`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Regresar</button>
                                    </Link>
                                    { DatosComprobante.estado==="ANULADO" || DatosComprobante.tipo==="07" ?
                                        <Link onClick={FnClonarFactura} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Clonar</button>
                                        </Link>
                                    :
                                        null
                                    }
                                    { DatosComprobante.estado==="PRESENTADO" ?
                                            <Link className="col-12 col-sm-6 col-md-2 custom-col">
                                                <button className="btn btn-success din-btn-style w-100" disabled><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Presentar</button>
                                            </Link>
                                        :
                                        loadingSearch ? (
                                                <div className="col-12 col-sm-6 col-md-2 custom-col">
                                                    <button className="btn btn-success din-btn-style w-100" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                        ) : (
                                                <Link onClick={DatosComprobante.tipo === '07' ? FnPresentarNC : FnPresentar} className="col-12 col-sm-6 col-md-2 custom-col">
                                                    <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Presentar</button>
                                                </Link>
                                        )
                                    }
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white' 
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1100, background: `${toastBackground}`}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        {/* Modal Dar Baja */}
        <>
            <Modal show={showBaja} onHide={handleCloseBaja}>
              <Modal.Header  className='bg-danger text-white' closeButton>
                <Modal.Title>Nota de Credito</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* ¿Desea generar nota de credito para este comprobante presentado a Sunat? */}
                {/* <InputComboBoxTiponc value={Idtiponc} campo="Tipo" name="Idtiponc" obligatorio="*" colsm="12" colmd="12" onChange={(selectedValue) => {setIdtiponc(selectedValue);}} /> */}
                <InputAreaText value={Motivonc} campo="Motivo de Anulacion" name="Motivonc" obligatorio="*" colsm="12" colmd="12" onChange={setMotivonc}/>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={handleCloseBaja}>
                  <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                </button>
                <button className="btn btn-danger din-btn-style" onClick={FnGenerarNotaCredito}>
                  <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Generar
                </button>
              </Modal.Footer>
            </Modal>
        </>
        </>
    );
}

export default ComprobanteDetail;    /* <<<<<< CAMBIO */

