import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';


// import ReactDOM from 'react-dom';
// ReactDOM.render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.StrictMode>,
//     document.getElementById('root')
// );


import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        //<BrowserRouter>
            <App/>
        //</BrowserRouter>
    // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
