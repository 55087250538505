import { ListadoView } from "../../componentes/listado/ListadoViewAlquiler";
import { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { animated, useSpring } from 'react-spring';
import Sidebar from "../Sidebar";
import API_BASE_URL  from "../../config";

const nombre_controller = 'Alquileres';           // <<<<<< CAMBIO
const idprimary = 'idhabitacion';                  // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;     // <<<<<< CAMBIO

// Rutas
const RutaHead = [
    { name: 'Recepcion', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Numero', bdname: 'numero'},      // <<<<<< CAMBIO
    { name: 'Estado', bdname: 'estnom'},      // <<<<<< CAMBIO
    { name: 'Categoria', bdname: 'categoria'},// <<<<<< CAMBIO
];

function AlquilerList() {      // <<<<<< CAMBIO
    //Toast
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //const [NombreUsuario, setNombreUsuario] = useState('DESCONOCIDO'); // <<<<<< CAMBIO

    // const FnValidarSesion = async() => {
    //     const token = localStorage.getItem('token-dincors');
    //     try {
    //         //const response = await fetch(`${url}/auth/me`, {
    //         console.log("2, validar sesion")
    //         const response = await fetch(`${url}/Sesion/ValidarSesion`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json; charset=UTF-8',
    //                 'Authorization': `Bearer ${token}` // Enviar el token en el encabezado de autorización
    //             },
    //             body: JSON.stringify({}),
    //         });
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log(data);
    //             if(data.res){
    //                 console.log("nom: ",data.token.nombre)
    //                 setNombreUsuario(data.token.nombre);
    //             }else{
    //                 throw new Error(data.msg);
    //             }
    //         } else {
    //             throw new Error(`${response.status} ${response.statusText}`);
    //         }
    //     } catch (error) {
    //         setToastBackground('#CC6868');
    //         setToastMessage(error.message);
    //         setShowToast(true);
    //     }
    // };
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        //const loginUsuario = localStorage.getItem('login-usuario');
        //setNombreUsuario(loginUsuario);
        if (storedMessage && storedBackground) {
          setToastMessage(storedMessage);
          setToastBackground(storedBackground);
          setShowToast(true);
          localStorage.removeItem('toastMessage1');
          localStorage.removeItem('toastBackground1');
          //localStorage.removeItem('login-usuario');
        }
    }, []);
    // useEffect(() => {
    //     FnValidarSesion();
    // }, []);
    //Animacion al cargar
    const { opacity } = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 200 },
    });
        
    return (
        <>
            <HeadView/> {/*  NombreUsuario={NombreUsuario} */}
            {/* <Sidebar /> */}
            <Sidebar/>
            <animated.div className="home-section" style={{opacity: opacity}}>
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        {/* <div className="container-fluid mt-3">
                            <div className='col-12 custom-col m-1 '>
                                <Link><button className="btn btn-success din-btn-style w-100" onClick={() => handleShowApertura()}><i className="fa-solid fa-cash-register"></i>&nbsp;&nbsp;Aperturar Caja</button></Link>
                            </div>
                        </div> */}
                        <ListadoView url={url} tabla={nombre_controller} idprimary={idprimary} detalle={Detalle} />
                    </section>
                    <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                        style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}`,}}>
                        <Toast.Header>
                            <strong className="me-auto">Notificación</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                </div>
                <FooterView/>
            </animated.div>
        </>
    );
}

export default AlquilerList;   // <<<<<< CAMBIO


