import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, forwardRef, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';

function ListadoViewCrudHistorial(props) {
  const { url, tabla, idprimary } = props; // cuerpoTable
  //Redux
  const user = useSelector((state) => state.user);
  const [Idempresa, setIdempresa] = useState(user.companyId);
  // Función para actualizar el usuario en el estado local cuando cambie en Redux
  useEffect(() => {
      setIdempresa(user.companyId);
  }, [user]);

  const [loading, setLoading]=useState(false);
  const [loadingPag, setLoadingPag] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData]=useState([]);
  const [page, setPage]=useState(0);//Controlar la posición actual de la página
  const [count, setCount]=useState(0);//controlar el cambio del total de páginas
  const [form, setForm]=useState({huesped:'', idestado:'',fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
  const [secondForm, setSecondForm]=useState({huesped:'', idestado:'',fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
  //const Idempresa=3;
  useEffect(()=>{
    if(form.buscar){
        searchData(form);
    }
    // eslint-disable-next-line
  },[form,user.companyId]);

  const searchData= async (data)=>{
      setLoading(true);
      try {
          const jsonData = {
              idempresa:user.companyId,
              huesped:data.huesped,
              idestado:data.idestado,
              fechaInicial:data.fechaInicial,
              fechaFinal:data.fechaFinal,
              pagina:data.page
          };
          console.log("jsonmov: ",jsonData)
          const token = localStorage.getItem('token-dincors');
          const response = await fetch(`${url}/${tabla}/${tabla}Historial`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${token}`
              },
              body:JSON.stringify(jsonData) /* empresa:user.companyId,  */
          });
          if(response.ok){                
              const data = await response.json();
              console.log("his: ", data)
              if(data.res){
                  setData(data.datos);
                  setPage(page+data.pag);
                  setCount(data.pag);
                  setSecondForm({
                      ...secondForm,
                      fechaInicial: '',
                      fechaFinal: ''
                  });
              }else{
                  setCount(0);
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
      }catch (error) {
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
      }finally{
          setLoading(false);
      }
  }

  const searchForm=(form)=>{
      setForm(form);
      setPage(0);
  }

  const firstPage=()=>{
      setForm({...form, page: 0});
      setPage(0);
  }

  const nextPage=()=>{
      setLoadingPag(true);
      setForm({...form, page: page});
      setTimeout(() => {
        setLoadingPag(false);
      }, 600);
  };

  const handleSearch=()=>{
    setLoadingSearch(true);
    //firstPage();
    if(page===0 && count===0){
      searchData(secondForm);
    }else{
      searchForm(secondForm);     
    }
    //searchForm(secondForm);     
    setTimeout(() => {
      setLoadingSearch(false);
    }, 1000);    
  };

  const handleChange=(e)=>{
    setSecondForm({
        ...secondForm,
        [e.target.name]:e.target.value
    });
  };

  const handleInitialDateChange = (newDate) => {
    setSecondForm({
        ...secondForm,
        fechaInicial: newDate ? newDate : ''
    });
  };

  const handleFinalDateChange = (newDate) => {
      setSecondForm({
          ...secondForm,
          fechaFinal: newDate ? newDate: ''
      });
  };

  // Exportar a Excel
  const handleExportToExcel = async () => {
    try{
      const jsonData = {
        idempresa: Idempresa,
      }
      const token = localStorage.getItem('token-dincors');
      const response = await fetch(`${url}/${tabla}/${tabla}ExportExcel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(jsonData),
      });
      let data;
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          const blobData = await response.blob(); // Espera a que se resuelva la promesa
          data = blobData;
        } else {
          data = await response.json(); // Espera a que se resuelva la promesa
        }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
      if (data instanceof Blob) {
        // Manejar el archivo descargado
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Alquiler.xlsx';    // <<<<<< CAMBIO
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setToastBackground('#198754');
        setToastMessage('Exportacion generada con exito'); // data.msg
        setShowToast(true);
      } else {
        setToastBackground('#CC6868');
        setToastMessage('Error al realizar la exportacion1');
        setShowToast(true);
      }
    }catch (error) {
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    }
  };
  
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#81D075');
  const token2 = localStorage.getItem('token-dincors');
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [DataComprobante, setDataComprobante] = useState([]);
  const [DataDetalleHuespedes, setDataDetalleHuespedes]=useState([]);
  const handleShowDetalle = async(itemId) => {
    setShowDetalle(true);
    try{
      const response = await fetch(`${url}/${tabla}/${tabla}HistorialDetail/${itemId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token2}`
          },
          credentials: 'include',
      })
      if(response.ok) {
          const data = await response.json();
          console.log("det: ",data)
          if(data.res){
              setDataDetalle(data.datos);
              setDataDetalleHuespedes(data.huesped);
              setDataComprobante(data.comprobante);
          }else{
              throw new Error(data.msg);
          }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
    }catch (error){
        throw error;
    }
  };
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
        {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                  <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                  &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className='row mb-2'>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <label>Nombre / Documento</label>
                      <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="huesped" onChange={handleChange} value={secondForm.huesped}/>
                    </div>
                    <div className="col-12 custom-col">
                      <label>Estado</label>
                      <select className="form-select" id="idestado" name="idestado" onChange={handleChange} value={secondForm.idestado}>
                        <option value="">- Seleccione una opcion -</option>
                        <option value="1">DISPONIBLE</option>
                        <option value="2">MANTENIMIENTO</option>
                        <option value="3">CERRADO</option>
                      </select>
                    </div>
                    <div className='col-12 custom-col'>
                      <label><small>Fecha Inicio Checkout</small></label>
                      <input type="date" name="fechaInicial" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleInitialDateChange(e.target.value)}/>
                    </div>
                    <div className='col-12 custom-col'>
                      <label><small>Fecha Fin Checkout</small></label>
                      <input type="date" name="fechaFinal" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleFinalDateChange(e.target.value) }/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row '>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      {loadingSearch ? (
                        <>
                          <button className="btn btn-success din-btn-style w-100" disabled>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                          </button>
                        </>
                      ) : (
                        <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                      )}
                    </div>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                    </div>
                    {/* <div className='col-12 custom-col'>
                      <Link><button className="btn btn-primary din-btn-style w-100" onClick={() => handleShowAgregar()}><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Nuevo</button></Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='row d-flex justify-content-end'>
                    <div className='col-12 custom-col-pagination'>
                      <div className='m-1'>
                        <Pagination className='justify-content-end'>
                          {page > 10 && 
                            <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              PRIMERO
                            </Pagination.Prev>}
                          {count === 10 && 
                            <Pagination.Next m={1} onClick={nextPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              {loadingPag ? (
                                <>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </>
                              ) : (
                                'SIGUIENTE'
                              )}
                            </Pagination.Next>}
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className='col-12'>
                  <div className="table" style={{ marginTop: '5px'}} id="table_id">
                    <div className='tbody ' >
                            {data.length > 0 ? (
                              data.map((item, index) => (
                                  <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${item[idprimary]}`}>
                                    <div className='td td-dropdown d-flex align-items-center justify-content-center'>
                                      <MyDropdown tabla={props.tabla} id={item[idprimary]} handleShowDetalle={handleShowDetalle}/>
                                    </div>
                                    <div className='td td-datos' >
                                      <div className='row'>
                                          <div className='col-12 col-sm-8 col-md-8 col-lg-8'>
                                              <div className='row'>
                                                  <div className='col-12 texto-lg'>
                                                      <span className='' style={{ textTransform: 'uppercase',fontWeight: '600' }}>#{item.idalquiler}</span>&nbsp;&nbsp;
                                                      <span className={``}>-&nbsp;&nbsp;{item.numero}</span>&nbsp;&nbsp;<span className={`texto-mediano`}>{item.categoria}</span>
                                                      &nbsp;&nbsp;
                                                      {item.isincidente === 1 ?
                                                          <i className="fa-solid fa-circle-exclamation fa-lg text-warning"></i>
                                                          :
                                                          <i className="fa-solid fa-circle-exclamation fa-lg text-secondary"></i>
                                                      }
                                                  </div>
                                                  <div className='col-12' style={{ textTransform: 'uppercase' }}>
                                                      <span>{item.huesped_documento} - </span><span className='text-success' style={{ textTransform: 'uppercase',fontWeight: '600' }}>{item.huesped_nombre}</span>&nbsp;&nbsp;
                                                      <span className={`texto-xs badge ${{1: 'bg-success',2: 'bg-info',3: 'bg-danger',}[item.idestado]}`}>
                                                        {{'1': 'DISPONIBLE',
                                                        '2': 'MANTENIMIENTO',
                                                        '3': 'CERRADO',
                                                        }[item.idestado]}
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className='col-12 col-sm-4 col-md-4 col-lg-4 ' > {/* d-flex align-items-center */}
                                              <div className='row '>
                                                  <div className='col-12'>
                                                      <span style={{fontWeight: '600'}}>In: </span><span>{item.aloj_checkin}</span>
                                                  </div>
                                                  <div className='col-12'>
                                                      <span style={{fontWeight: '600'}}>Out: </span><span>{item.aloj_checkout}</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                              <div>
                                <div className='text-center' colSpan={12}>Elija los filtros para encontrar los registros</div>
                              </div>
                            )}
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          )}
          {/* Modal Agregar */}
          {/* <>
            <Modal show={ShowAgregar} onHide={CloseAgregarModal} size="lg">
              <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                  <Modal.Title>Editar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                    <InputComboBoxAdd2 campo="Almacen" name="Idalmacen" obligatorsio="*" colsm="12" colmd="12" url={url} onChange={setIdalmacen} idempresa={Idempresa}/> 
                    <InputComboBoxBusquedaItemAdd campo="Item" name="Iditem2" obligatorio="*" idempresa={Idempresa} colsm="12" colmd="12" url={url} onChange={(selectedValue) => {setIditem(selectedValue);}}/>
                    <InputTextAdd campo="Cantidad" name="Itemcant" obligatorio="*" colsm="12" colmd="6" onChange={setItemcant}/>               
                    <InputTextAdd campo="Precio Unitario" name="Itemcprapu" obligatorio="*" colsm="12" colmd="6" onChange={setItemcprapu}/>               
                    <InputTextAdd campo="Motivo" name="Itemobs" obligatorio="*" colsm="12" colmd="6" onChange={setItemobs}/>               
                    <InputTextAdd campo="Codigo Operacion" name="Operacioncod" obligatorio="*" colsm="12" colmd="6" onChange={setOperacioncod}/> 
                </div>
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="secondary" onClick={CloseAgregarModal}>Cerrar</Button>
                    <Button variant="success" onClick={FnGuardarModal}>Guardar</Button>
              </Modal.Footer>
            </Modal>
          </> */}
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title >Detalle</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-1' id='TabsCaja'>
              <Tabs id="controlled-tab-example" defaultActiveKey="Alquiler" className="mb-3" >
                <Tab eventKey="Alquiler" title="Alquiler">
                  <div className="m-3">
                    {/* CARD BODY */}
                    <div className="mx-2">
                        <div className="row">
                          <div className="col-12">
                            <div className='row'>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Numero de Alquiler:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.idalquiler}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Numero de Habitacion:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.numero}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Detalle:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.detalle}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Nivel:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.nivel}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto de Alojamiento:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.aloj_monto_alquiler ? parseFloat(dataDetalle.aloj_monto_alquiler).toFixed(2) : "0.00"}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto de Servicios:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.aloj_monto_servicio ? parseFloat(dataDetalle.aloj_monto_servicio).toFixed(2) : "0.00"}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto por Cobrar:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.aloj_monto_cobrar ? parseFloat(dataDetalle.aloj_monto_cobrar).toFixed(2) : "0.00"}</div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Huespedes" title="Huespedes">
                  <div className='m-3'>
                    <div className='mx-2'>
                      <div className='row'>
                          {DataDetalleHuespedes.map((data, index) => (
                              <div className="col-12 mb-3" key={index}>
                                  <Card border={data.idcliente === dataDetalle.idhuesped ? "success" : undefined} >
                                      <Card.Header className={`p-2 text-center ${data.idcliente === dataDetalle.idhuesped ? 'bg-success' : ''}`}>
                                          <h1 className={`m-0 ${data.idcliente === dataDetalle.idhuesped ? 'text-white' : ''}`}>
                                              #{index+1}: CLIENTE {data.idcliente === dataDetalle.idhuesped ? 'PRINCIPAL' : ''}
                                          </h1>
                                      </Card.Header>
                                      <Card.Body>
                                          <div className='row'>
                                              <div className='col-10'>
                                                  <div className='row'>
                                                      <div className='col-12' style={{ textTransform: 'uppercase' }}>
                                                          <span style={{fontWeight:'500'}}>{data.clinom}</span> - {data.txttipodocumento}: {data.cliruc}
                                                      </div>
                                                      <div className='col-12' style={{ textTransform: 'uppercase' }}>
                                                          DIRECCION: {data.clidirec} - TELF. {data.clitelf ? data.clitelf : "-"}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className='col-2 text-end'>
                                              </div>
                                          </div>
                                      </Card.Body>
                                  </Card>
                              </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Incidente" title="Incidente">
                  <div className='m-3'>
                    <div className='mx-2'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row'>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Incidente:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>
                                <input className="form-check-input" type="checkbox" checked={dataDetalle.isincidente === 1} readOnly/>
                              </div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Tipo:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.txttipoincidente ? dataDetalle.txttipoincidente : "-"}</div>
                              <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Descripcion:&nbsp;&nbsp;</div>
                              <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalle.incidente ? dataDetalle.incidente : "-"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Comprobante" title="Comprobante">
                  <div className='m-3'>
                    <div className='mx-2'>
                      <div className='row'>
                        <div className='col-12'>
                          {DataComprobante ? (
                              <div className='row'>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Tipo Comprobante:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.nombre ? DataComprobante.nombre+" DE VENTA ELECTRONICA" : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Numero Comprobante:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.fac_documento ? DataComprobante.fac_documento : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Fecha Emision:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.fechaemision ? DataComprobante.fechaemision : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Fecha Vencimiento:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.fechaemision ? DataComprobante.fechaemision : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Moneda:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.moneda ? DataComprobante.moneda : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Estado:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'><span className={`texto-xs badge ${['PENDIENTE', ].includes(DataComprobante.estado) ? 'bg-danger' : 'bg-success'}`}>{DataComprobante.estado ? DataComprobante.estado : "-"}</span></div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Cliente:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>[{DataComprobante.cliruc ? DataComprobante.cliruc : "-"}] {DataComprobante.clinombre ? DataComprobante.clinombre : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Direccion:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.clidireccion ? DataComprobante.clidireccion : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>I.G.V.:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.igv ? DataComprobante.igv : "-"}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Medio de Pago:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>
                                    {{'999': 'Otros medio de pago',
                                        '001': 'Deposito en cuenta',
                                        '002': 'Giro',
                                        '003': 'Transferencia de fondos',
                                        '004': 'Orden de pago',
                                        '005': 'Tarjeta de debito'
                                    }[DataComprobante.mediopago ? DataComprobante.mediopago : "-"]}
                                </div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Forma de Pago:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.formapago}</div>
                                <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Notas:&nbsp;&nbsp;</div>
                                <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{DataComprobante.notas ? DataComprobante.notas : "-"}</div>
                              </div>
                            )
                            : (
                                <div className='row px-4'> 
                                    <span className='text-center texto-xl text-secondary' style={{opacity:'0.3'}}> 
                                        COMPROBANTE NO GENERADO
                                    </span>
                                </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
              {/* <Link to={`/${tabla}Upd/${selectedIdDetalle}`}>
                <button className="btn btn-success din-btn-style">
                  <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Editar
                </button>
              </Link> */}
            </Modal.Footer>
          </Modal>
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}

// Define la función CustomDropdownToggle utilizando forwardRef
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});

// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShowDetalle} = props;
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
       <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
        <Dropdown.Item onClick={() => handleShowDetalle(props.id)}>
          <i className="fas fa-search"></i> Detalle
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}


export {ListadoViewCrudHistorial};