import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { InputText } from "../../componentes/editar/InputText";
import { InputComboBoxMoneda } from "../../componentes/editar/caja/InputComboBoxMoneda";
import { InputDate } from "../../componentes/editar/InputDate";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import  RouterView  from '../../componentes/layout/RouterView';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Cajas';           // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const idprimary = 'Idcaja';

function CajaUpd() {     // <<<<<< CAMBIO
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    //Caja
    const { id } = useParams();    
    const [Cajacod, setCajacod] = useState('');                   // <<<<<< CAMBIO
    const [Fecha, setFecha] = useState('');                   // <<<<<< CAMBIO
    const [Cajanom, setCajanom] = useState('');                 // <<<<<< CAMBIO
    const [Cajadesc, setCajadesc] = useState('');                 // <<<<<< CAMBIO
    const [Saldo, setSaldo] = useState('');               // <<<<<< CAMBIO
    const [Isbanco, setIsbanco] = useState('');             // <<<<<< CAMBIO
    const [Bconom, setBconom] = useState('');               // <<<<<< CAMBIO
    const [Bconro, setBconro] = useState('');                   // <<<<<< CAMBIO
    const [Bcosucursal, setBcosucursal] = useState('');   // <<<<<< CAMBIO
    const [Idestado, setIdestado] = useState(''); // <<<<<< CAMBIO
    const [Txtmoneda, setTxtmoneda] = useState('');       // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const [valuesLoaded, setValuesLoaded] = useState(true);
    const FnActualizar = async () => {
        let mensaje='';
        if(Fecha.trim() !== '' && Cajanom.trim() !== '' && Txtmoneda !== ''){     // <<<<<< CAMBIO
            const now = new Date();
            const jsonData = {
                Idcaja: id,      // <<<<<< CAMBIO
                Cajacod: Cajacod,     // <<<<<< CAMBIO
                Fecha: Fecha,     // <<<<<< CAMBIO
                Cajanom: Cajanom,   // <<<<<< CAMBIO
                Cajadesc: Cajadesc, // <<<<<< CAMBIO
                Saldo:Saldo,// <<<<<< CAMBIO
                Isbanco: Isbanco, // <<<<<< CAMBIO
                Bconom: Bconom,     // <<<<<< CAMBIO
                Bconro: Bconro,   // <<<<<< CAMBIO
                Bcosucursal: Bcosucursal, // <<<<<< CAMBIO
                Idestado: Idestado,   // <<<<<< CAMBIO
                Txtmoneda: Txtmoneda,     // <<<<<< CAMBIO
                Idempresa: Idempresa, // <<<<<< CAMBIO
                Fechact: now.toISOString(),
                Codusuact: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            try{
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                })
                if(response.ok){
                    const data = await response.json();
                    if(data.res){
                        localStorage.setItem('toastMessage1', data.msg);
                        localStorage.setItem('toastBackground1', '#198754');
                        localStorage.setItem('idpage', id);
                        navigate(`/${nombre_controller}List`);
                    }else{
                        setToastMessage(data.msg);
                        setShowToast(true);
                        setToastBackground('#CC6868');
                    }
                }
            } catch(error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            };
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    const token2 = localStorage.getItem('token-dincors');
    useEffect(() => {
        UbicarRegistro(id, token2, {
            setCajacod,          // <<<<<< CAMBIO
            setFecha,          // <<<<<< CAMBIO
            setCajanom,         // <<<<<< CAMBIO
            setCajadesc,        // <<<<<< CAMBIO    
            setSaldo,       // <<<<<< CAMBIO    
            setIsbanco,        // <<<<<< CAMBIO    
            setBconom,          // <<<<<< CAMBIO
            setBconro, // <<<<<< CAMBIO        
            setBcosucursal,    // <<<<<< CAMBIO        
            setIdestado,      // <<<<<< CAMBIO    
            setTxtmoneda,     // <<<<<< CAMBIO    
        })
        .then(function (data) {
            setValuesLoaded(true);
        })
        .catch(function (error) {
            console.log(error);
        });
        // eslint-disable-next-line
    }, [id]);
    // ====================== MODAL ELIMINAR ======================
    // Agrega un estado para controlar la visibilidad del modal
    const navigate = useNavigate();
    const [showEliminar, setShowEliminar] = useState(false);
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = () => setShowEliminar(true);
    // Función de eliminar
    const FnEliminar = async() => {
        handleCloseEliminar();
        const jsonData = {
            Idcaja: id,          // <<<<<< CAMBIO
            Idempresa: Idempresa,   // <<<<<< CAMBIO
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/${nombre_controller}List`);
                }else{
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            }
        }
        catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        };
    };
    // ======== MOVIMIENTO CAJA ========
    // Funcion listado de movimiento de caja
    const [loading, setLoading]=useState(false);
    const [loadingPag, setLoadingPag] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [data, setData]=useState([]);
    const [page, setPage]=useState(0);//Controlar la posición actual de la página
    const [count, setCount]=useState(0);//controlar el cambio del total de páginas
    const [form, setForm]=useState({idmovimiento:'', concepto:'', fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
    const [secondForm, setSecondForm]=useState({idmovimiento:'', concepto:'', fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
    //const Idempresa=3;
    useEffect(()=>{
        if(form.buscar){
            searchData(form);
        }
        // eslint-disable-next-line
    },[form,user.companyId]);

    const searchData= async (data)=>{
        setLoading(true);
        try {
            const jsonData = {
                Idcaja:id,
                idempresa:user.companyId,
                idmovimiento:data.idmovimiento,
                concepto:data.concepto,
                fechaInicial:data.fechaInicial,
                fechaFinal:data.fechaFinal,
                pagina:data.page
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Pages2`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body:JSON.stringify(jsonData) /* empresa:user.companyId,  */
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setData(data.datos);
                    setPage(page+data.pag);
                    setCount(data.pag);
                    setSecondForm({
                        ...secondForm,
                        fechaInicial: '',
                        fechaFinal: ''
                    });
                }else{
                    setCount(0);
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setLoading(false);
        }
    }

    const searchForm=(form)=>{
        setForm(form);
        setPage(0);
    }

    const firstPage=()=>{
        setForm({...form, page: 0});
        setPage(0);
    }

    const nextPage=()=>{
        setLoadingPag(true);
        setForm({...form, page: page});
        setTimeout(() => {
            setLoadingPag(false);
        }, 600);
    };

    const handleSearch=()=>{
        setLoadingSearch(true);
        //firstPage();
        if(page===0 && count===0){
        searchData(secondForm);
        }else{
        searchForm(secondForm);     
        }
        //searchForm(secondForm);     
        setTimeout(() => {
        setLoadingSearch(false);
        }, 1000);    
    };

    const handleChange=(e)=>{
        setSecondForm({
            ...secondForm,
            [e.target.name]:e.target.value
        });
    };

    const handleInitialDateChange = (newDate) => {
        setSecondForm({
            ...secondForm,
            fechaInicial: newDate ? newDate : ''
        });
    };

    const handleFinalDateChange = (newDate) => {
        setSecondForm({
            ...secondForm,
            fechaFinal: newDate ? newDate: ''
        });
    };
    // ======== OPERACION CAJA ========
    // Funcion listado de Operacion de caja
    const [loading2, setLoading2]=useState(false);
    const [loadingPag2, setLoadingPag2] = useState(false);
    const [loadingSearch2, setLoadingSearch2] = useState(false);
    const [data2, setData2]=useState([]);
    const [page2, setPage2]=useState(0);//Controlar la posición actual de la página
    const [count2, setCount2]=useState(0);//controlar el cambio del total de páginas
    const [form2, setForm2]=useState({idcajaoperacion:'', concepto2:'', fechaInicial2:'', fechaFinal2:'', page2:0, buscar2:true}); // form es la variable formulario de estado que tiene los datos
    const [secondForm2, setSecondForm2]=useState({idcajaoperacion:'', concepto2:'', fechaInicial2:'', fechaFinal2:'', page2:0, buscar2:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
    //const Idempresa=3;
    useEffect(()=>{
        if(form2.buscar2){
            searchData2(form2);
        }
        // eslint-disable-next-line
    },[form2,user.companyId]);

    const searchData2 = async (data)=>{
        setLoading2(true);
        try {
            const jsonData = {
                Idcaja:id,
                idempresa:user.companyId,
                idcajaoperacion:data.idcajaoperacion,
                concepto:data.concepto2,
                fechaInicial:data.fechaInicial2,
                fechaFinal:data.fechaFinal2,
                pagina:data.page2
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Pages3`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body:JSON.stringify(jsonData) /* empresa:user.companyId,  */
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setData2(data.datos);
                    setPage2(page2+data.pag);
                    setCount2(data.pag);
                    setSecondForm2({
                        ...secondForm2,
                        fechaInicial2: '',
                        fechaFinal2: ''
                    });
                }else{
                    setCount2(0);
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setLoading2(false);
        }
    }

    const searchForm2=(form2)=>{
        setForm2(form2);
        setPage2(0);
    }

    const firstPage2=()=>{
        setForm2({...form2, page2: 0});
        setPage2(0);
    }

    const nextPage2=()=>{
        setLoadingPag2(true);
        setForm2({...form2, page2: page2});
        setTimeout(() => {
            setLoadingPag2(false);
        }, 600);
    };

    const handleSearch2=()=>{
        setLoadingSearch2(true);
        //firstPage();
        if(page2===0 && count2===0){
            searchData2(secondForm2);
        }else{
            searchForm2(secondForm2);     
        }
        //searchForm(secondForm);     
        setTimeout(() => {
            setLoadingSearch2(false);
        }, 1000);    
    };

    const handleChange2=(e)=>{
        setSecondForm2({
            ...secondForm2,
            [e.target.name]:e.target.value
        });
    };

    const handleInitialDateChange2 = (newDate) => {
        setSecondForm2({
            ...secondForm2,
            fechaInicial2: newDate ? newDate : ''
        });
    };

    const handleFinalDateChange2 = (newDate) => {
        setSecondForm2({
            ...secondForm2,
            fechaFinal2: newDate ? newDate: ''
        });
    };
    const RutaHead = [
        { name: 'Caja', ruta: `/${nombre_controller}List` },        // <<<<<< CAMBIO
        { name: `Edición Caja #${id}`, ruta: `/${nombre_controller}Upd` }, // <<<<<< CAMBIO
    ];
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <section className="container-fluid mt-3">
                {/* PRINCIPAL */}
                <div className="card m-1">
                    {/* CARD HEAD */}
                    <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                    {/* CARD BODY */}
                    <div className="card-body mx-2">
                        {valuesLoaded && (
                        <>
                            {/* Titulo */}
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="card-title text-success">Datos Generales</h5>
                                    <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                </div>
                            </div>
                            {/* Datos */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <InputDate value={Fecha} campo="Fecha de recepcion" name="Fecha" obligatorio="*" colsm="6" colmd="4" onChange={setFecha}/>    {/* <<<<<< CAMBIO */}
                                        <InputComboBoxMoneda value={Txtmoneda} campo="Tipo de Moneda" name="Txtmoneda" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtmoneda}/> {/* <<<<<< CAMBIO */}
                                        <InputText value={Cajanom} campo="Nombre" name="Cajanom" obligatorio="*" colsm="6" colmd="4" onChange={setCajanom}/>  {/* <<<<<< CAMBIO */}
                                        <InputText value={Cajadesc} campo="Descripcion" name="Cajadesc" obligatorio="" colsm="6" colmd="4" onChange={setCajadesc}/>{/* <<<<<< CAMBIO */}
                                        <InputCheckBox value={Isbanco} campo="¿La caja esta asociada a una cuenta de banco?" name="Isbanco" obligatorio="" colsm="6" colmd="4" mensaje="" onChange={setIsbanco}/> {/* <<<<<< CAMBIO */}
                                        <InputText value={Bconom} campo="Nombre del Banco" name="Bconom" obligatorio="" colsm="6" colmd="4" onChange={setBconom}/>{/* <<<<<< CAMBIO */}
                                        <InputText value={Bconro} campo="Cuenta Bancaria" name="Bconro" obligatorio="" colsm="6" colmd="4" onChange={setBconro}/>{/* <<<<<< CAMBIO */}
                                        <InputText value={Bcosucursal} campo="Sucursal" name="Bcosucursal" obligatorio="" colsm="6" colmd="4" onChange={setBcosucursal}/>{/* <<<<<< CAMBIO */}
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                    </div>
                    {/* CARD FOOTER */}
                    <div className="card-footer border-0 bg-white">
                        <div className="mb-1 mt-1">
                            <div className="row">
                                <div className='col-12'>
                                    <div className='row p-1 d-flex justify-content-end'>
                                        <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                        </Link>
                                        <Link onClick={FnActualizar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                        <Link onClick={handleShowEliminar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-danger din-btn-style w-100"><i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* LISTADO */}
                <div className="card m-1 mt-3">
                    {/* CARD HEAD */}
                    <h5 className="card-header din-card-header">COMPLEMENTOS</h5>
                    {/* CARD BODY */}
                    <div className="card-body mx-2">
                        <Tabs id="controlled-tab-example" defaultActiveKey="Movimientos" className="mb-0" >
                            <Tab eventKey="Movimientos" title="Movimientos" >
                                <div className="row mt-3" style={{padding: "0px 0px 0px 10.5px"}}>
                                    {loading ? (
                                        <div className='w-100' style={{ height: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                                            <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                                            &nbsp;&nbsp;<h1>Cargando datos...</h1>
                                        </div>
                                        ) : (
                                        <div style={{ width: '100%' }}>
                                            <div className='row mb-2'>
                                                <div className='col-12'>
                                                    <div className='row p-1 d-flex justify-content-end'>
                                                        <div className='col-12 custom-col'>
                                                            <label>ID Movimiento</label>
                                                            <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="idmovimiento" onChange={handleChange} value={secondForm.idmovimiento}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label>Concepto</label>
                                                            <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="concepto" onChange={handleChange} value={secondForm.concepto}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label><small>Fecha Inicio</small></label>
                                                            <input type="date" name="fechaInicial" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleInitialDateChange(e.target.value)}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label><small>Fecha Fin</small></label>
                                                            <input type="date" name="fechaFinal" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleFinalDateChange(e.target.value) }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row '>
                                                <div className='col-12'>
                                                    <div className='row p-1 d-flex justify-content-end'>
                                                        <div className='col-12 custom-col'>
                                                        {loadingSearch ? (
                                                            <>
                                                            <button className="btn btn-success din-btn-style w-100" disabled>
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                            </button>
                                                            </>
                                                        ) : (
                                                            <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12'>
                                                    <div className='row d-flex justify-content-end'>
                                                        <div className='col-12 custom-col-pagination'>
                                                            <div className='m-1'>
                                                                <Pagination className='justify-content-end'>
                                                                {page > 10 && 
                                                                    <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                                                                    PRIMERO
                                                                    </Pagination.Prev>}
                                                                {count === 10 && 
                                                                    <Pagination.Next m={1} onClick={nextPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                                                                    {loadingPag ? (
                                                                        <>
                                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                                        </>
                                                                    ) : (
                                                                        'SIGUIENTE'
                                                                    )}
                                                                    </Pagination.Next>}
                                                                </Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-1">
                                                <div className='col-12'>
                                                    <div className="table" style={{ marginTop: '5px'}} id="table_id">
                                                        <div className='tbody mx-3' >
                                                            {loading ? (
                                                                <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                                                            ) : (
                                                                data.length > 0 ? (
                                                                data.map((dato, index) => (
                                                                    <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${dato[idprimary]}`}>
                                                                        <div className='row'>
                                                                            <div className='col-2 col-sm-1 col-md-1 col-lg-1'>
                                                                                {dato.idmovimiento}
                                                                            </div>
                                                                            <div className='col-10 col-sm-7 col-md-7 col-lg-7'>
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        {dato.concepto}
                                                                                    </div>
                                                                                    <div className='col-12 texto-lg'>
                                                                                        <span className={`texto-xs badge ${[1, 4, 5, 8].includes(dato.idtipomovimiento) ? 'bg-danger' : 'bg-success'}`}>{dato.tipomovimiento}</span>&nbsp;&nbsp;&nbsp;
                                                                                        <span className={`texto-lg`}>{dato.fecha_registro}</span>&nbsp;&nbsp;
                                                                                        <span className={`text-black texto-xs badge bg-warning`}>{dato.idcajaapertura}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ fontWeight: '600'}} className='col-12 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center' >
                                                                                {dato.debe ? 
                                                                                <span className='text-success'>{dato.moneda}&nbsp; + {parseFloat(dato.debe).toFixed(2)} </span>
                                                                                : 
                                                                                <span className='text-danger'>{dato.moneda}&nbsp; - {parseFloat(dato.haber).toFixed(2)} </span>
                                                                                }
                                                                            </div>
                                                                            <div style={{ fontWeight: '600' }} className='col-12 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center'>
                                                                                <span className='texto-lg' style={{ fontWeight: '600' }}>{dato.moneda}&nbsp; {parseFloat(dato.saldo).toFixed(2)}</span>&nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    ))
                                                                ) : (
                                                                <div>
                                                                    <div className='text-center'>Elija los filtros para encontrar los registros</div>
                                                                </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="Operaciones" title="Operaciones">
                                <div className="row mt-3" style={{padding: "0px 0px 0px 10.5px"}}>
                                    {loading2 ? (
                                        <div className='w-100' style={{ height: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                                            <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                                            &nbsp;&nbsp;<h1>Cargando datos...</h1>
                                        </div>
                                        ) : (
                                        <div style={{ width: '100%' }}>
                                            <div className='row mb-2'>
                                                <div className='col-12'>
                                                    <div className='row p-1 d-flex justify-content-end'>
                                                        <div className='col-12 custom-col'>
                                                            <label>ID Operacion</label>
                                                            <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="idcajaoperacion" onChange={handleChange2} value={secondForm2.idcajaoperacion}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label>Concepto</label>
                                                            <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="concepto2" onChange={handleChange2} value={secondForm2.concepto2}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label><small>Fecha Inicio</small></label>
                                                            <input type="date" name="fechaInicial" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleInitialDateChange2(e.target.value)}/>
                                                        </div>
                                                        <div className='col-12 custom-col'>
                                                            <label><small>Fecha Fin</small></label>
                                                            <input type="date" name="fechaFinal" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleFinalDateChange2(e.target.value) }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row '>
                                                <div className='col-12'>
                                                    <div className='row p-1 d-flex justify-content-end'>
                                                        <div className='col-12 custom-col'>
                                                            {loadingSearch2 ? (
                                                                <>
                                                                <button className="btn btn-success din-btn-style w-100" disabled>
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                                </button>
                                                                </>
                                                            ) : (
                                                                <button className="btn btn-success din-btn-style w-100" onClick={handleSearch2}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12'>
                                                    <div className='row d-flex justify-content-end'>
                                                        <div className='col-12 custom-col-pagination'>
                                                            <div className='m-1'>
                                                                <Pagination className='justify-content-end'>
                                                                    {page2 > 10 && 
                                                                        <Pagination.Prev m={1} onClick={firstPage2} style={{width:90, textAlign:'center', pointerEvents: loadingPag2 ? 'none' : 'auto'}}>
                                                                        PRIMERO
                                                                        </Pagination.Prev>}
                                                                    {count2 === 10 && 
                                                                        <Pagination.Next m={1} onClick={nextPage2} style={{width:90, textAlign:'center', pointerEvents: loadingPag2 ? 'none' : 'auto'}}>
                                                                        {loadingPag2 ? (
                                                                            <>
                                                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                                            </>
                                                                        ) : (
                                                                            'SIGUIENTE'
                                                                        )}
                                                                        </Pagination.Next>
                                                                    }
                                                                </Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-1">
                                                <div className='col-12'>
                                                    <div className="table" style={{ marginTop: '5px'}} id="table_id">
                                                        <div className='tbody mx-3' >
                                                            {loading2 ? (
                                                                <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                                                            ) : (
                                                                data2.length > 0 ? (
                                                                data2.map((dato, index) => (
                                                                    <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${dato[idprimary]}`}>
                                                                        <div className='row'>
                                                                            <div style={{ fontWeight: '600' }} className='col-1 col-sm-1 col-md-1 col-lg-1 d-flex align-items-center'>
                                                                                <span className='texto-lg' style={{ fontWeight: '600' }}>{dato.idcajaoperacion}</span>&nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                            <div className='col-7 col-sm-8 col-md-8 col-lg-9'>
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        {dato.concepto}
                                                                                    </div>
                                                                                    <div className='col-12 texto-lg'>
                                                                                        <span className={`texto-xs badge ${[1, 4, 5, 8].includes(dato.idtipomovimiento) ? 'bg-danger' : 'bg-success'}`}>{dato.tipomovimiento}</span>&nbsp;&nbsp;&nbsp;
                                                                                        <span className={`texto-lg`}>{dato.fechreg}</span>&nbsp;&nbsp;
                                                                                        <span className={`text-black texto-xs badge bg-warning`}>{dato.idcajaapertura}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ fontWeight: '600'}} className='col-4 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center' >
                                                                                {dato.idtipomovimiento===2 ? 
                                                                                    <span className='text-success'>{dato.moneda}&nbsp; + {parseFloat(dato.monto).toFixed(2)} </span>
                                                                                : 
                                                                                    <span className='text-danger'>{dato.moneda}&nbsp; - {parseFloat(dato.monto).toFixed(2)} </span>
                                                                                }
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    ))
                                                                ) : (
                                                                <div>
                                                                    <div className='text-center'>Elija los filtros para encontrar los registros</div>
                                                                </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                    )}
                                </div>
                            </Tab>
                        </Tabs> 
                    </div>
                </div>
            </section>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide 
            className='text-white' style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1000,background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            {/* Modal Eliminar */}
            <>
                <Modal show={showEliminar} onHide={handleCloseEliminar}>
                    <Modal.Header closeButton className="bg-danger text-white">
                        <Modal.Title>Eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEliminar}>
                        Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnEliminar}>
                        Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <FooterView/>
        </div>
        </>
    );
}

async function UbicarRegistro(id, token2, setters) {
    try{
        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Upd/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token2}`
            },
            credentials: 'include',
        })
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setCajacod(datos.cajacod); /* <<<<<< CAMBIO */
                setters.setFecha(datos.fecha);    /* <<<<<< CAMBIO */
                setters.setCajanom(datos.cajanom);  /* <<<<<< CAMBIO */
                setters.setCajadesc(datos.cajadesc);    /* <<<<<< CAMBIO */
                setters.setSaldo(datos.saldo);  /* <<<<<< CAMBIO */
                setters.setIsbanco(datos.isbanco);    /* <<<<<< CAMBIO */
                setters.setBconom(datos.bconom);    /* <<<<<< CAMBIO */
                setters.setBconro(datos.bconro);  /* <<<<<< CAMBIO */
                setters.setBcosucursal(datos.bcosucursal);    /* <<<<<< CAMBIO */
                setters.setIdestado(datos.idestado);    /* <<<<<< CAMBIO */
                setters.setTxtmoneda(datos.txtmoneda);  /* <<<<<< CAMBIO */
                return datos;
            }else{
                throw new Error(data.msg);
            }
        }else{
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }
    catch (error) {
        throw error;
    };
} 

export default CajaUpd;

