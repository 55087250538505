import { useState, useEffect } from "react";

async function ListarCombobox(name, url) {
    try {
      const response = await fetch(`${url}/ComboBox/${name}Combo`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error("Error en la respuesta del servidor");
      }
      const data = await response.json();
      return data.datos;
    } catch (error) {
      //console.log(error);
      throw error;
    }
}
  
function InputComboBoxTextId(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        ListarCombobox(props.name, props.url)
            .then(function (data) {
                setListaComboBox(data);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [props.name, props.url]);

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const selectedOption = ListarDatos.find((dato) => dato.id === parseInt(selectedValue));
        props.onChange(selectedValue, selectedOption ? selectedOption.nombre : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.id}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxTextId};