import { useState, useEffect } from "react";
import React from 'react'
import Select from 'react-select'


const cantidad = 20;    // <<<<<< CAMBIO

function ListarCombobox(name,url) {//search
    return fetch(`${url}/ComboBox/${name}Combo/null`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    })
    .then(function (response) {
        if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
        }
        return response.json();
    })
    .then(function (data) {
        const datosTransformados = data.datos.map(item => ({
            value: item.id,
            label: item.nombre
        }));
        return datosTransformados;
    })
    .catch(function (error) {
        throw error;
    });
}
function InputComboBoxBusquedaTextId(props) {
    const [ListarDatos, setListarDatos] = useState([]);
    const [ListarDatosFiltrados, setListarDatosFiltrados] = useState([]);
    useEffect(() => {
        ListarCombobox(props.name, props.url) //search
            .then(function (data) {
                setListarDatos(data);
                setListarDatosFiltrados(data.slice(0, cantidad)); // Filtrar los primeros 20 registros
                return data;
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [props.name, props.url]); //search
    let abortController;
    const handleChange = (inputValue, { action }) => {
        if(!inputValue){
            inputValue='null'
        }
        if (action === 'input-change') {
            // Cancelar la solicitud anterior si aún no ha sido completada
            if (abortController) {
                abortController.abort();
            }
            // Crear un nuevo controlador de aborto
            const newAbortController = new AbortController();
            abortController = newAbortController;
            setTimeout(() => {
                fetch(`${props.url}/ComboBox/${props.name}Combo/${inputValue}`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    credentials: 'include',
                    signal: newAbortController.signal, // Asociar la señal de aborto con la solicitud
                })
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error("Error en la respuesta del servidor");
                    }
                    return response.json();
                })
                .then(function (data) {
                    const datosTransformados = data.datos.map(item => ({
                        value: item.id,
                        label: item.nombre
                    }));
                    setListarDatosFiltrados(datosTransformados.slice(0, cantidad));
                })
                .catch(function (error) {
                    if (error.name !== 'AbortError') {
                        // Solo lanzar el error si no fue causado por la cancelación de la solicitud
                        throw error;
                    }
                    //throw error;
                });
            }, 300);
            // const datosFiltrados = ListarDatos.filter(item =>
            //     item.label && item.label.toLowerCase().includes(inputValue ? inputValue.toLowerCase() : '')
            // );
            // setListarDatosFiltrados(datosFiltrados.slice(0, cantidad));
        }
    };

    // const handleChange = (inputValue, { action }) => {
    //     if (action === 'input-change') {
    //         const datosFiltrados = ListarDatos.filter(item =>
    //             item.label && item.label.toLowerCase().includes(inputValue ? inputValue.toLowerCase() : '')
    //         );
    //         // Actualizar el estado ListarDatosFiltrados con los datos filtrados
    //         setListarDatosFiltrados(datosFiltrados.slice(0, cantidad));
    //     }
    // };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <Select 
            options={ListarDatosFiltrados} id={`cbx${props.name}`} 
            value={ListarDatos.find(item => item.value === props.value)}
            onChange={(selectedOption) => {
                // Asegurarse de que selectedOption tenga un valor antes de llamar a props.onChange
                const selectedValue = selectedOption ? selectedOption.value : null;
                const selectedOpt = selectedOption.label;
                props.onChange(selectedValue,selectedOpt);
            }}
            onInputChange={handleChange}/>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxBusquedaTextId};