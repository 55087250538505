import { useState } from 'react';

function InputPasswordOriginal(props) {
    const [showPassword, setShowPassword] = useState(false);
    // const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        const inputValue = e.target.value;
        props.onChange(inputValue);
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
    return (
        <>
            <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
                <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className="input-group">
                    <input id={`txt${props.name}`} type="password" className="form-control" onChange={handleChange}/>
                    <button className="input-group-text din-input-text-modal btn" onClick={togglePasswordVisibility}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></button>
                </div>
            </div>
        </>
    );
  }
  
export {InputPasswordOriginal};