import { useState, useEffect } from "react";

function InputComboBoxTipoDocumentoFactura(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '0', nombre: 'SIN RUC'},
            { id: '1', nombre: 'DNI'},
            { id: '4', nombre: 'CARNET DE EXTRANJERIA'},
            { id: '6', nombre: 'RUC'},
            { id: '7', nombre: 'PASAPORTE'},
            { id: '9', nombre: 'CARNÉ DE SOLICIT DE REFUGIO'},
            { id: '22', nombre: 'CARNÉ DE IDENTIDAD - RELACIONES EXTERIORES'},
            { id: '23', nombre: 'PERM.TEMP.PERMANENCIA'},
            { id: '24', nombre: 'DOC. DE IDENTIDAD EXTRANJERO (3)'},
            { id: '26', nombre: 'CARNÉ DE PERMISO TEMP DE PERMANENCIA'},
        ];
        setListaComboBox(data);
    },[]);
    return (
        <div className={`col-12 col-sm-12 col-md-12 mb-3`}>                            
            <div className='row'>
                <label htmlFor={`cbx${props.name}`} className="col-3 col-form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className='col-9'>
                    <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""}>
                        <option value="">- {props.campo} -</option>
                        {ListarDatos.map((dato) => (
                            <option key={dato.id} value={dato.nombre} >
                                {dato.nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export {InputComboBoxTipoDocumentoFactura};