// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";

import { InputText } from "../../componentes/agregar/InputText";
import { InputComboBoxMoneda } from "../../componentes/editar/caja/InputComboBoxMoneda";
import { InputDate } from "../../componentes/agregar/InputDate";
import { InputCheckBox } from "../../componentes/agregar/InputCheckBox";

import { Link, useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Cajas';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Caja', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Registro Caja', ruta: `/${nombre_controller}Add` },  // <<<<<< CAMBIO
];
function CajaAdd() {
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    //Caja
    const navigate = useNavigate();
    const [Cajacod] = useState('');                   // <<<<<< CAMBIO
    const [Fecha, setFecha] = useState('');                   // <<<<<< CAMBIO
    const [Cajanom, setCajanom] = useState('');                 // <<<<<< CAMBIO
    const [Cajadesc, setCajadesc] = useState('');               // <<<<<< CAMBIO
    const [Saldo] = useState('');             // <<<<<< CAMBIO
    const [Isbanco, setIsbanco] = useState('');               // <<<<<< CAMBIO
    const [Bconom, setBconom] = useState('');                   // <<<<<< CAMBIO
    const [Bconro, setBconro] = useState('');   // <<<<<< CAMBIO
    const [Bcosucursal, setBcosucursal] = useState('');   // <<<<<< CAMBIO
    const [Idestado] = useState(1);         // <<<<<< CAMBIO
    const [Txtmoneda, setTxtmoneda] = useState('SOLES');         // <<<<<< CAMBIO
    //const [Idempresa] = useState(2);              // <<<<<< CAMBIO
    // eslint-disable-next-line no-unused-vars
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const FnGuardar = async () => {
        let mensaje='';
        if(Fecha.trim() !== '' && Cajanom.trim() !== '' && Txtmoneda !== ''){ // <<<<<< CAMBIO
            const now = new Date();
            const jsonData = {
                Cajacod: Cajacod,         // <<<<<< CAMBIO
                Fecha: Fecha,         // <<<<<< CAMBIOv
                Cajanom: Cajanom,       // <<<<<< CAMBIO
                Cajadesc: Cajadesc,     // <<<<<< CAMBIO
                Saldo: Saldo,    // <<<<<< CAMBIO
                Isbanco: Isbanco ? 1:0,     // <<<<<< CAMBIO
                Bconom: Bconom,         // <<<<<< CAMBIO
                Bconro: Bconro, // <<<<<< CAMBIO
                Bcosucursal: Bcosucursal,     // <<<<<< CAMBIO
                Idestado: Idestado,       // <<<<<< CAMBIO
                Txtmoneda: Txtmoneda,         // <<<<<< CAMBIO
                Idempresa: Idempresa,// <<<<<< CAMBIO
                Fechreg: now.toISOString(),
                Fechact: now.toISOString(),
                Codusureg: Idusuario,
                Codusuact: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            try {
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){                
                    const data = await response.json();
                    console.log(data)
                    if(data.res){
                        localStorage.setItem('toastMessage1', data.msg);
                        localStorage.setItem('toastBackground1', '#198754');
                        navigate(`/${nombre_controller}List`);
                    }else{
                        setToastMessage(data.msg);
                        setShowToast(true);
                        setToastBackground('#CC6868');
                    }
                }else  {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error){
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
            
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Datos Generales</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputDate campo="Fecha de recepcion" name="Fecha" obligatorio="*" colsm="6" colmd="4" onChange={setFecha}/>    {/* <<<<<< CAMBIO */}
                                <InputComboBoxMoneda value={Txtmoneda} campo="Tipo de Moneda" name="Txtmoneda" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtmoneda}/> {/* <<<<<< CAMBIO */}
                                <InputText campo="Nombre" name="Cajanom" obligatorio="*" colsm="6" colmd="4" onChange={setCajanom}/>  {/* <<<<<< CAMBIO */}
                                <InputText campo="Descripcion" name="Cajadesc" obligatorio="" colsm="6" colmd="4" onChange={setCajadesc}/>{/* <<<<<< CAMBIO */}
                                <InputCheckBox campo="¿La caja esta asociada a una cuenta de banco?" name="Isbanco" obligatorio="" colsm="6" colmd="4" mensaje="" onChange={setIsbanco}/> {/* <<<<<< CAMBIO */}
                                <InputText campo="Nombre del Banco" name="Bconom" obligatorio="" colsm="6" colmd="4" onChange={setBconom}/>{/* <<<<<< CAMBIO */}
                                <InputText campo="Cuenta Bancaria" name="Bconro" obligatorio="" colsm="6" colmd="4" onChange={setBconro}/>{/* <<<<<< CAMBIO */}
                                <InputText campo="Sucursal" name="Bcosucursal" obligatorio="" colsm="6" colmd="4" onChange={setBcosucursal}/>{/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    <Link onClick={FnGuardar} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                    </Link>
                                    <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                    </Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        </>
    );
}

export default CajaAdd;    /* <<<<<< CAMBIO */

