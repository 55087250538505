import { useState, useEffect } from "react";

function InputComboBoxEstado(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '0', nombre: 'INACTIVO'},
            { id: '1', nombre: 'ACTIVO'},
        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const TextEstado = e.target.value;
        const IDEstado = ListarDatos.find((dato) => dato.nombre === TextEstado);
        props.onChange(TextEstado, IDEstado ? IDEstado.id : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.nombre}>
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxEstado};