import { useState, useEffect } from "react";

function InputComboBoxParentesco(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '1', nombre: 'PADRE'},
            { id: '2', nombre: 'MADRE'},
            { id: '3', nombre: 'APODERADO'},
            { id: '4', nombre: 'CONYUGE'},
            { id: '5', nombre: 'HIJO/A'},
            { id: '6', nombre: 'NIETO/A'},
            { id: '7', nombre: 'HERMANO/A'},
            { id: '8', nombre: 'ABUELO/A'},
            { id: '9', nombre: 'BISNIETO/A'},
            { id: '10', nombre: 'SOBRINO/A'},
            { id: '11', nombre: 'TIO/A'},
            { id: '12', nombre: 'BISABUELO/A'},
            { id: '13', nombre: 'PRIMO/A HERMANO/A'},
        
        ];
        setListaComboBox(data);
    },[]);
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`cbx${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <select className="form-select" id={`cbx${props.name}`} onChange={(e) => props.onChange(e.target.value)} value={props.value || ""}>
                <option value="">- {props.campo} -</option>
                {ListarDatos.map((dato) => (
                    <option key={dato.id} value={dato.nombre} >
                        {dato.nombre}
                    </option>
                ))}
            </select>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxParentesco};