function InputPassword(props) {
    const handleChange = (e) => {
        const inputValue = e.target.value;
        props.onChange(inputValue);
        const password1 = document.getElementById(`txt${props.name}`).value;
        const password2 = document.getElementById(`txt${props.name}2`).value;
        const successMessage = "Las contraseñas coinciden";
        const errorMessage = "Las contraseñas no coinciden";
        if (password1 === "" && password2 === "") {
            document.getElementById(`${props.name}Error`).innerText = "";
            document.getElementById(`${props.name}Success`).innerText = "";
            document.getElementById(`${props.name}2Error`).innerText = "";
            document.getElementById(`${props.name}2Success`).innerText = "";
        } else if (password1 === password2 && password1 !== "" && password2 !== "") {
            document.getElementById(`${props.name}Error`).innerText = "";
            document.getElementById(`${props.name}Success`).innerText = successMessage;
            document.getElementById(`${props.name}2Error`).innerText = "";
            document.getElementById(`${props.name}2Success`).innerText = successMessage;
        } else {
            document.getElementById(`${props.name}Error`).innerText = errorMessage;
            document.getElementById(`${props.name}Success`).innerText = "";
            document.getElementById(`${props.name}2Error`).innerText = errorMessage;
            document.getElementById(`${props.name}2Success`).innerText = "";
            props.onChange(null)
        }
    };
    return (
        <>
            <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
                <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <input id={`txt${props.name}`} type="password" className="form-control" onChange={handleChange} />
                <small><span id={`${props.name}Error`} className="text-danger"></span></small>
                <small><span id={`${props.name}Success`} className="text-success"></span></small>
            </div>
            <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
                <label htmlFor={`txt${props.name}2`} className="form-label">Repetir {props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <input id={`txt${props.name}2`} type="password" className="form-control" onChange={handleChange} />
                <small><span id={`${props.name}2Error`} className="text-danger"></span></small>
                <small><span id={`${props.name}2Success`} className="text-success"></span></small>
            </div>
        </>
    );
  }
  
export {InputPassword};