import '../Assets/fontawesome-free-6.0.0-web/css/all.min.css'
// import '../Assets/css/din_style.css'
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';//importar las variables globales del usuario
import { useEffect } from "react";
import { setUser } from '../redux/userReducer';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL  from "../config";
const url = `${API_BASE_URL}`; 
function Inicio() {
    //REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    //Navigate
    const navigate = useNavigate();
    // Verificacion de inicio de sesion
    const sessionRecover=async()=>{
        //setTimeout(()=>{setUserLoading(true);},3000);
        try {
            const token = localStorage.getItem('token-dincors');
            if(!token){throw new Error('No se encontro la autorizacion');}
            const response = await fetch(`${url}/Sesion/ValidarSesion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify()
            });
            if (response.ok) {
                const data = await response.json();
                if(data.res){
                    dispatch(setUser({isLogIn:true, userName:data.token.nombre, userId:data.token.id, companyId:data.token.idempresa, companyRuc:data.token.empruc, companyName:data.token.emprs, idRol:data.token.idrol, apiKey:data.token.apikey}));
                    // if(data.token.idrol===1 || data.datos.idrol===3){
                    //     navigate(`/AlquileresList`);
                    // }else{
                    //     navigate(`/MovimientosList`);
                    // }
                }else{
                    throw new Error(data.msg);
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch (ex) {
            navigate('/Login');
        }
    }
    useEffect(()=>{
        if(!user.isLogIn){
            sessionRecover();
        }
        // eslint-disable-next-line
    }, [user]);
    return (
        //className="home-section container-fluid"
        <>
        {/* <div className="home-section"> */}
            <section className=''>
                <Outlet />
                {/* <FooterView></FooterView> */}
            </section>
        {/* </div> */}
        </>
    );
}
export default Inicio;


