import './Assets/fontawesome-free-6.0.0-web/css/all.min.css'
// import './Assets/css/din_style.css'
// import './Assets/css/din_style_2.css'
// import './Assets/css/StyleSlidebar.css'
import React from "react";
import { HashRouter, Routes, Route } from 'react-router-dom'; //HashRouter navigate
// import Sidebar from "./pages/Sidebar";
// import  HeadView  from "./componentes/layout/HeadView";
import  Inicio  from "./pages/Inicio";
import  UsuarioList  from "./pages/usuario/UsuarioList";
import  UsuarioAdd  from "./pages/usuario/UsuarioAdd";
import  UsuarioUpd  from "./pages/usuario/UsuarioUpd";
import  ClienteList  from "./pages/cliente/ClienteList";
import  ClienteAdd  from "./pages/cliente/ClienteAdd";
import  ClienteUpd  from "./pages/cliente/ClienteUpd";
import  AlquilerList  from "./pages/alquiler/AlquilerList";
import  AlquilerHistorial  from "./pages/alquiler/AlquilerHistorial";
import  AlquilerAdd  from "./pages/alquiler/AlquilerAdd";
import  AlquilerUpd  from "./pages/alquiler/AlquilerUpd";
import  ProveedorList  from "./pages/proveedor/ProveedorList";
import  ProveedorAdd  from "./pages/proveedor/ProveedorAdd";
import  ProveedorUpd  from "./pages/proveedor/ProveedorUpd";
import  HabitacionList  from "./pages/habitacion/HabitacionList";
import  TarifaList  from "./pages/tarifa/TarifaList";
import  AlmacenList  from "./pages/almacen/AlmacenList";
import  CategoriaList  from "./pages/categoria/CategoriaList";
import  NivelList  from "./pages/nivel/NivelList";
import  Login  from "./pages/Login";
import InventarioList from "./pages/inventario/InventarioList";
import PeriodoList from "./pages/periodo/PeriodoList";
import  CajaList  from "./pages/caja/CajaList";
import  CajaAdd  from "./pages/caja/CajaAdd";
import  CajaUpd  from "./pages/caja/CajaUpd";
import  PlanillaList  from "./pages/planilla/PlanillaList";
import  PlanillaUpd  from "./pages/planilla/PlanillaUpd";
import  EntradaList  from "./pages/entrada/EntradaList";
import  SalidaList  from "./pages/salida/SalidaList";
import  MovimientoList  from "./pages/movimiento/MovimientoList";
import  StockList  from "./pages/stock/StockList";
import  ItemList  from "./pages/item/ItemList";
import  ItemAdd  from "./pages/item/ItemAdd";
import  ItemUpd  from "./pages/item/ItemUpd";
import  ComprobanteList  from "./pages/comprobante/ComprobanteList";
import  ComprobanteAdd  from "./pages/comprobante/ComprobanteAdd";
import  ComprobanteUpd  from "./pages/comprobante/ComprobanteUpd";
import  ComprobanteDetail  from "./pages/comprobante/ComprobanteDetail";
import store from './redux/store.js';//Objeto central que almacena el estado global de la aplicacion.
import { Provider } from 'react-redux'

function App() {
    return (
        <>
            <Provider store={store}>{/* Provider expone el estado global de las variables de redux */}
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Inicio/>}>
                            <Route path="/Login" element={<Login/>}></Route>
                            <Route> {/* Alquileres */}
                              <Route path="AlquileresList" element={<AlquilerList/>}/>
                              <Route path="AlquileresAdd/:id" element={<AlquilerAdd/>}/>
                              <Route path="AlquileresUpd/:id/:id2" element={<AlquilerUpd/>}/>
                              <Route path="AlquileresHistorial" element={<AlquilerHistorial/>}/>
                            </Route>
                            <Route > {/* Habitaciones */}
                              <Route path="HabitacionesList" element={<HabitacionList/>}/>
                            </Route>
                            <Route > {/* Tarifas */}
                              <Route path="TarifasList" element={<TarifaList/>}/>
                            </Route>
                            <Route > {/* Almacenes */}
                            <Route path="AlmacenesList" element={<AlmacenList/>}/>
                          </Route>
                            <Route > {/* Categorias */}
                              <Route path="CategoriasList" element={<CategoriaList/>}/>
                            </Route>
                            <Route > {/* Niveles */}
                              <Route path="NivelesList" element={<NivelList/>}/>
                            </Route>
                            <Route> {/* Clientes */}
                              <Route path="ClientesList" element={<ClienteList/>}/>
                              <Route path="ClientesAdd" element={<ClienteAdd/>}/>
                              <Route path="ClientesUpd/:id" element={<ClienteUpd/>}/>
                            </Route>
                            <Route > {/* Proveedores */}
                              <Route path="ProveedoresList" element={<ProveedorList/>}/>
                              <Route path="ProveedoresAdd" element={<ProveedorAdd/>}/>
                              <Route path="ProveedoresUpd/:id" element={<ProveedorUpd/>}/>
                            </Route>
                            <Route > {/* Usuarios */}
                              <Route path="UsuariosList" element={<UsuarioList/>}/>
                              <Route path="UsuariosAdd" element={<UsuarioAdd/>}/>
                              <Route path="UsuariosUpd/:id" element={<UsuarioUpd/>}/>
                            </Route>
                            <Route path="*" element={<Inicio/>}/>
                            <Route > {/* Inventarios */}
                              <Route path='InventariosList' element={<InventarioList/>}/>
                            </Route>
                            <Route > {/* Entradas */}
                              <Route path='EntradasList' element={<EntradaList/>}/>
                            </Route>
                            <Route > {/* Salidas */}
                              <Route path='SalidasList' element={<SalidaList/>}/>
                            </Route>
                            <Route > {/* Movimientos */}
                              <Route path='MovimientosList' element={<MovimientoList/>}/>
                            </Route>
                            <Route > {/* Stocks */}
                              <Route path='StocksList' element={<StockList/>}/>
                            </Route>
                            <Route > {/* Periodos */}
                              <Route path='PeriodosList' element={<PeriodoList/>}/>
                            </Route>
                            <Route > {/* Cajas */}
                              <Route path="CajasList" element={<CajaList/>}/>
                              <Route path="CajasAdd" element={<CajaAdd/>}/>
                              <Route path="CajasUpd/:id" element={<CajaUpd/>}/>
                            </Route>
                            <Route > {/* Planillas */}
                              <Route path="PlanillasList" element={<PlanillaList/>}/>
                              <Route path="PlanillasUpd/:id" element={<PlanillaUpd/>}/>
                            </Route>
                            <Route>{/* Items */}
                              <Route path="ItemsList" element={<ItemList/>}/>
                              <Route path="ItemsAdd" element={<ItemAdd/>}/>
                              <Route path="ItemsUpd/:id" element={<ItemUpd/>}/>
                            </Route>
                            <Route>{/* Comprobantes */}
                              <Route path="ComprobantesList" element={<ComprobanteList/>}/>
                              <Route path="ComprobantesAdd" element={<ComprobanteAdd/>}/>
                              <Route path="ComprobantesUpd/:id" element={<ComprobanteUpd/>}/>
                              <Route path="ComprobantesDetail/:id" element={<ComprobanteDetail/>}/>
                            </Route>
                        </Route>
                    </Routes>
                </HashRouter>
            </Provider>
        </>
    );
}

export default App;
