import { useState } from "react";
function FooterView() {
    const [currentYear] = useState("");
    return (
        <div className="container-fluid">
            
            <footer>
                <div className="m-1 mt-3">&copy; {currentYear} - DinEmployee</div>
                <div>&nbsp;</div>
            </footer>
        </div>
    );
}

export {FooterView}