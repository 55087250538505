import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InputText } from "../../componentes/editar/InputText";
import { InputComboBoxTxtunidadmedida } from "../../componentes/editar/item/InputComboBoxTxtunidadmedida";
import { InputComboBoxTxtestado } from "../../componentes/editar/item/InputComboBoxTxtestado";
import { InputComboBoxCategoria } from "../../componentes/editar/item/InputComboBoxCategoria";
import { InputComboBoxN2TextId } from "../../componentes/editar/InputComboBoxN2TextId";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import  RouterView  from '../../componentes/layout/RouterView';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";
const nombre_controller = 'Items';           // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Item', ruta: `/${nombre_controller}List` },        // <<<<<< CAMBIO
    { name: 'Edición Item', ruta: `/${nombre_controller}Upd` }, // <<<<<< CAMBIO
];
function ItemUpd() {     // <<<<<< CAMBIO
    const { id } = useParams();   
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]); 
    const [Itemcodigo, setItemcodigo] = useState('');            //varchar       // <<<<<< CAMBIO
    const [Itemdesc, setItemdesc] = useState('');                //text   // <<<<<< CAMBIO
    //const [Catnombre, setCatnombre] = useState('');            //varchar // <<<<<< CAMBIO
    //const [Subnombre, setSubnombre] = useState('');            //varchar // <<<<<< CAMBIO
    const [Txtunidadmedida, setTxtunidadmedida] = useState('');  //int // <<<<<< CAMBIO
    const [Txtmediso, setTxtmediso] = useState('');              //varchar     // <<<<<< CAMBIO
    const [Itemcprapu, setItemcprapu] = useState('');     //decimal // <<<<<< CAMBIO
    const [Itemvtapu, setItemvtapu] = useState('');       //decimal // <<<<<< CAMBIO
    const [Txtestado, setTxtestado] = useState('');              //int // <<<<<< CAMBIO
    const [Idcategoria, setIdcategoria] = useState('');              //int // <<<<<< CAMBIO
    const [Idsubcategoria, setIdsubcategoria] = useState('');              //int // <<<<<< CAMBIO
    const [Movimiento, setMovimiento] = useState(false);              //int // <<<<<< CAMBIO
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const [valuesLoaded, setValuesLoaded] = useState(true);
    // limpiar
    const ClearComboBox = () => {
        setIdsubcategoria(null)
    }
    const FnActualizar = async () => {
        let mensaje='';
        if(Txtestado !== '' && Itemcodigo.trim() !== '' && Itemdesc.trim() !== '' && Txtunidadmedida !==''){     // <<<<<< CAMBIO
            const jsonData = {
                Iditem: id,      // <<<<<< CAMBIO
                Itemcodigo: Itemcodigo,     // <<<<<< CAMBIO
                Itemdesc: Itemdesc,     // <<<<<< CAMBIO
                Txtunidadmedida: Txtunidadmedida, // <<<<<< CAMBIO
                Txtmediso: Txtmediso,         // <<<<<< CAMBIO
                Itemcprapu: Itemcprapu,       // <<<<<< CAMBIO
                Itemvtapu: Itemvtapu,     // <<<<<< CAMBIO
                Txtestado: Txtestado,     // <<<<<< CAMBIO
                Idcategoria: Idcategoria,       // <<<<<< CAMBIO
                Idsubcategoria: Idsubcategoria, // <<<<<< CAMBIO
                DatosAlmacen: SelectedAlmacen,
                Movimiento: Movimiento ? 1 : 0,
                Idempresa: Idempresa,       // <<<<<< CAMBIO
                Codusuact: Idusuario,
            };
            //console.log("alma: ", jsonData)
            const token = localStorage.getItem('token-dincors');
            try{
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                })
                if(response.ok){
                    const data = await response.json();
                    //console.log("data: ",data);
                    if(data.res){
                        localStorage.setItem('toastMessage1', data.msg);
                        localStorage.setItem('toastBackground1', '#198754');
                        localStorage.setItem('idpage', id);
                        navigate(`/${nombre_controller}List`);
                    }else{
                        throw new Error(data.msg);
                    }
                }else {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            } catch(error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            };
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    // almacenes seleccionados
    const [DatosAlmacen, setDatosAlmacen] = useState([]);
    const [SelectedAlmacen, setSelectedAlmacen] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}/ComboBox/IdalmacenCombo/${Idempresa}`, {
                    method: 'POST',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                const data = await response.json();
                if(data.res==='200'){
                    setDatosAlmacen(data.datos);
                }
            } catch (error) {
                //console.log(error);
                throw error;
            }
        };
        fetchData();
    }, [Idempresa]);
    useEffect(() => {
        const returnAlmacen = async () => {
            try{
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Almacen/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token2}`
                    },
                    credentials: 'include',
                })
                if (response.ok) {
                    const data = await response.json();
                    //console.log(data)
                    if(data.res){
                        setSelectedAlmacen(data.almacen ? data.almacen : []);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }
            catch (error) {
                throw error;
            };
        };
        returnAlmacen();
        const returnMovimiento = async () => {
            try{
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Movimiento/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token2}`
                    },
                    credentials: 'include',
                })
                if (response.ok) {
                    const data = await response.json();
                    if(data.res){
                        setMovimiento(data.movimiento===0 ? false : true);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }
            catch (error) {
                throw error;
            };
        };
        returnMovimiento();
        // eslint-disable-next-line
    }, [Idempresa]);
    const handleCheckboxChange = (e) => {
        const valorSeleccionado = e.target.value;
        const isChecked = e.target.checked;
        setSelectedAlmacen((prevSeleccionados) => {
            if (isChecked) {
                // Agregar el elemento seleccionado a SelectedAlmacen si no está presente
                if (!prevSeleccionados.some((item) => item.idalmacen === valorSeleccionado)) {
                    return [...prevSeleccionados, { idalmacen: parseInt(valorSeleccionado)}];
                }
                
            } else {
                // Remover el elemento deseleccionado de SelectedAlmacen
                return prevSeleccionados.filter((item) => item.idalmacen !== parseInt(valorSeleccionado));
            }
            return prevSeleccionados; // Devuelve el estado sin cambios si no se modificó
        });    
    };
    const token2 = localStorage.getItem('token-dincors');
    useEffect(() => {
        UbicarRegistro(id, token2, {
            setItemcodigo,          // <<<<<< CAMBIO
            setItemdesc,          // <<<<<< CAMBIO
            setTxtunidadmedida,        // <<<<<< CAMBIO    
            setTxtmediso,          // <<<<<< CAMBIO
            setItemcprapu, // <<<<<< CAMBIO        
            setItemvtapu,    // <<<<<< CAMBIO        
            setTxtestado,    // <<<<<< CAMBIO
            setIdsubcategoria,
        })
        .then(function (data) {
            setValuesLoaded(true);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }, [id, token2]);
    useEffect(() => {
        if(Idsubcategoria){
            UbicarNiveles(Idsubcategoria, {
                setIdcategoria,  // <<<<<< CAMBIO
                setIdsubcategoria,      // <<<<<< CAMBIO
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }, [Idsubcategoria]);
    // ====================== MODAL ELIMINAR ======================
    // Agrega un estado para controlar la visibilidad del modal
    const navigate = useNavigate();
    const [showEliminar, setShowEliminar] = useState(false);
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = () => setShowEliminar(true);
    // Función de eliminar
    const FnEliminar = async() => {
        handleCloseEliminar();
        const jsonData = {
            Iditem: id,          // <<<<<< CAMBIO
            Idempresa: Idempresa,   // <<<<<< CAMBIO
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/${nombre_controller}List`);
                }else{
                    throw new Error(data.msg);
                }
            }else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }
        catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        };
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <section className="container-fluid mt-3">
                <div className="card m-1">
                    {/* CARD HEAD */}
                    <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                    {/* CARD BODY */}
                    <div className="card-body mx-2">
                        {valuesLoaded && (
                        <>
                            {/* Titulo */}
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="card-title text-success">Datos Generales</h5>
                                    <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                </div>
                            </div>
                            {/* Datos */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <InputComboBoxTxtestado estado={false} value={Txtestado} campo="Estado" name="Txtestado" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtestado}/> {/* <<<<<< CAMBIO */}
                                        <InputText estado={Movimiento} value={Itemcodigo} campo="Codigo" name="Itemcodigo" obligatorio="*" colsm="6" colmd="4" onChange={setItemcodigo}/>       {/* <<<<<< CAMBIO */}
                                        <InputText estado={Movimiento} value={Itemdesc} campo="Descripcion" name="Itemdesc" obligatorio="*" colsm="6" colmd="4" onChange={setItemdesc}/>        {/* <<<<<< CAMBIO */}
                                        {/* Combobox de Categoria Movimiento*/}
                                        <InputComboBoxCategoria estado={Movimiento} value={Idcategoria} campo="Categoria" name="Idcategoria3" obligatorio="*" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdcategoria(selectedValue);ClearComboBox();}} idempresa={Idempresa}/>   {/* <<<<<< CAMBIO */}
                                        <InputComboBoxN2TextId estado={Movimiento} value={Idsubcategoria} campo="Subcategoria" name="Idsubcategoria" obligatorio="*" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdsubcategoria(selectedValue);}} idnivel={Idcategoria}/>  {/* <<<<<< CAMBIO */}
                                        {/* Combobox de Sub-Categoria */}
                                        <InputComboBoxTxtunidadmedida estado={Movimiento} value={Txtunidadmedida} campo="Unidad Medida" name="Txtunidadmedida" obligatorio="*" colsm="6" colmd="4" url={url}
                                        onChange={(Txtunidadmedida, Txtmediso) => {setTxtunidadmedida(Txtunidadmedida);setTxtmediso(Txtmediso);}}/>                         {/* <<<<<< CAMBIO */}
                                        <InputText estado={Movimiento} value={Itemcprapu} campo="Precio Compra" name="Itemcprapu" obligatorio="" colsm="6" colmd="4" onChange={setItemcprapu}/>   {/* <<<<<< CAMBIO */}
                                        <InputText estado={Movimiento} value={Itemvtapu} campo="Precio Venta" name="Itemvtapu" obligatorio="" colsm="6" colmd="4" onChange={setItemvtapu}/>       {/* <<<<<< CAMBIO */}
                                    </div>
                                </div>
                            </div>
                            {/* Titulo */}
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="card-title text-success">Almacenes disponibles</h5>
                                    <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                </div>
                            </div>
                            {/* Datos */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        {/* {DatosAlmacen.map((dato) => (
                                            <div key={dato.id} className="col-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id={dato.id} value={dato.id} 
                                                    checked={SelectedAlmacen.some((item) => item.idalmacen === dato.id)}
                                                    onChange={(e) => {
                                                            const valorSeleccionado = e.target.value;
                                                            const isChecked = e.target.checked;
                                                            if (isChecked) {
                                                                if (!SelectedAlmacen.some((item) => item.idalmacen === valorSeleccionado)) {
                                                                    setSelectedAlmacen((prevSeleccionados) => [
                                                                        ...prevSeleccionados,
                                                                        { idalmacen: valorSeleccionado },
                                                                    ]);
                                                                }
                                                            } else {
                                                                setSelectedAlmacen((prevSeleccionados) =>
                                                                    prevSeleccionados.filter((item) => item.idalmacen !== valorSeleccionado)
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={dato.id}>{dato.nombre}</label>
                                                </div>
                                            </div>
                                        ))} */}
                                        {DatosAlmacen.map((dato) => (
                                            <div key={dato.id} className="col-4">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={dato.id}
                                                        value={parseInt(dato.id)}
                                                        checked={SelectedAlmacen.some((item) => item.idalmacen === dato.id)}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label" htmlFor={dato.id}>
                                                        {dato.nombre}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                    </div>
                    {/* CARD FOOTER */}
                    <div className="card-footer border-0 bg-white">
                        <div className="mb-1 mt-1">
                            <div className="row">
                                <div className='col-12'>
                                    <div className='row p-1 d-flex justify-content-end'>
                                        <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                        </Link>
                                        <Link onClick={FnActualizar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                        <Link onClick={handleShowEliminar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-danger din-btn-style w-100"><i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1000,background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            {/* Modal Eliminar */}
            <>
                <Modal show={showEliminar} onHide={handleCloseEliminar}>
                    <Modal.Header closeButton className="bg-danger text-white">
                        <Modal.Title>Eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEliminar}>
                        Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnEliminar}>
                        Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <FooterView/>
        </div>
        </>
    );
}

async function UbicarRegistro(id, token2, setters) {
    try{
        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Upd/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token2}`
            },
            credentials: 'include',
        })
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setItemcodigo(datos.itemcodigo); /* <<<<<< CAMBIO */
                setters.setItemdesc(datos.itemdesc);    /* <<<<<< CAMBIO */
                setters.setTxtunidadmedida(datos.txtunidadmedida);    /* <<<<<< CAMBIO */
                setters.setTxtmediso(datos.txtmediso);    /* <<<<<< CAMBIO */
                setters.setItemcprapu(datos.itemcprapu);    /* <<<<<< CAMBIO */
                setters.setItemvtapu(datos.itemvtapu);    /* <<<<<< CAMBIO */
                setters.setTxtestado(datos.txtestado);    /* <<<<<< CAMBIO */
                setters.setIdsubcategoria(datos.idsubcategoria);    /* <<<<<< CAMBIO */
                return datos;
            }else{
                throw new Error(data.msg);
            }
        }else{
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }
    catch (error) {
        throw error;
    };
}
async function UbicarNiveles(id, setters) {
    try {
        const response = await fetch(`${url}/ComboBox/CategoriasReturn/${id}`, {
            method: 'POST',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setIdcategoria(datos.id3);/* <<<<<< CAMBIO */
                setters.setIdsubcategoria(datos.id1);   /* <<<<<< CAMBIO */
                return datos;
            }
        }else{
            throw new Error("Error en la respuesta del servidor");
        }
    } catch (error) {
      throw error;
    }
}
export default ItemUpd;


