import { useState } from 'react';

function InputPassword(props) {
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (e) => {
        const inputValue = e.target.value;
        props.onChange(inputValue);
        const password1 = document.getElementById(`txt${props.name}1`).value;
        const password2 = document.getElementById(`txt${props.name}2`).value;
        const successMessage = "Las contraseñas coinciden";
        const errorMessage = "Las contraseñas no coinciden";
        if (password1 === "" && password2 === "") {
            document.getElementById(`${props.name}1Error`).innerText = "";
            document.getElementById(`${props.name}1Success`).innerText = "";
            document.getElementById(`${props.name}2Error`).innerText = "";
            document.getElementById(`${props.name}2Success`).innerText = "";
        } else if (password1 === password2 && password1 !== "" && password2 !== "") {
            document.getElementById(`${props.name}1Error`).innerText = "";
            document.getElementById(`${props.name}1Success`).innerText = successMessage;
            document.getElementById(`${props.name}2Error`).innerText = "";
            document.getElementById(`${props.name}2Success`).innerText = successMessage;
        } else {
            document.getElementById(`${props.name}1Error`).innerText = errorMessage;
            document.getElementById(`${props.name}1Success`).innerText = "";
            document.getElementById(`${props.name}2Error`).innerText = errorMessage;
            document.getElementById(`${props.name}2Success`).innerText = "";
            props.onChange(null)
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
    return (
        <>
            <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
                <label htmlFor={`txt${props.name}1`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className="input-group">
                    <input id={`txt${props.name}`} type="password" className="form-control" onChange={handleChange}/>
                    <button className="input-group-text din-input-text-modal btn" onClick={togglePasswordVisibility}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></button>
                </div>
                <small><span id={`${props.name}1Error`} className="text-danger"></span></small>
                <small><span id={`${props.name}1Success`} className="text-success"></span></small>
            </div>
            <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`} style={{ display: props.vista }}>
                <label htmlFor={`txt${props.name}2`} className="form-label">Repetir {props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className="input-group">
                    <input id={`txt${props.name}`} type="password" className="form-control" onChange={handleChange}/>
                    <button className="input-group-text din-input-text-modal btn" onClick={togglePasswordVisibility}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></button>
                </div>
                <small><span id={`${props.name}2Error`} className="text-danger"></span></small>
                <small><span id={`${props.name}2Success`} className="text-success"></span></small>
            </div>
        </>
    );
  }
  
export {InputPassword};