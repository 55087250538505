import {createSlice} from "@reduxjs/toolkit";

const initialState={
    isLogin:false,
    userName:'Unknown',
    userId:0,
    companyId:0,
    companyRuc:0,
    companyName:'Unknown',
    idRol:0,
    apiKey:0,
};

const userSlice=createSlice({
    name:'user',
    initialState,
    reducers:{
        setUser:(state,action) => {
            state.isLogIn=action.payload.isLogIn;
            state.userId=action.payload.userId;
            state.userName=action.payload.userName;
            state.companyId=action.payload.companyId;
            state.companyRuc=action.payload.companyRuc;
            state.companyName=action.payload.companyName;
            state.idRol=action.payload.idRol;
            state.apiKey=action.payload.apiKey;
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
