import { ListadoViewCrudSalida } from "../../componentes/listado/ListadoViewCrudSalida";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Salidas';            // <<<<<< CAMBIO
const idprimary = 'idsalida';                    // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Salida', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Codigo', bdname: 'idsalida'},              // <<<<<< CAMBIO
    { name: 'Almacen', bdname: 'almacen'},            // <<<<<< CAMBIO
    { name: 'Item', bdname: 'itemnom' },              // <<<<<< CAMBIO
    { name: 'Cantidad', bdname: 'itemcant' },         // <<<<<< CAMBIO
    { name: 'Precio Unitario', bdname: 'itemvtapu' }, // <<<<<< CAMBIO
    { name: 'Precio Venta', bdname: 'itemvtavv' },    // <<<<<< CAMBIO
    { name: 'Numero Operacion', bdname: 'operacioncod' },    // <<<<<< CAMBIO 
    { name: 'Observacion', bdname: 'itemobs' },       // <<<<<< CAMBIO
    { name: 'Usuario', bdname: 'usuario' },           // <<<<<< CAMBIO
    { name: 'Fecha Registro', bdname: 'itemfecha' },  // <<<<<< CAMBIO
];

function SalidaList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudSalida url={url} tabla={nombre_controller} 
                             idprimary={idprimary} detalle={Detalle} />  
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}

export default SalidaList;

