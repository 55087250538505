import React, { useState, useEffect } from 'react';
import { Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';//importar las variables globales del usuario
import API_BASE_URL  from "../config";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    FolderOutlined,
    //HomeOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

function getItem(label, key, icon, children, type, path) {
    return {
        key,
        icon,
        children,
        label,
        type,
        path,
    };
}

const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const Sidebar = () => {
    //Redux
    const user = useSelector((state) => state.user);
    //const [Idusuario, setIdusuario] = useState(user.userId);
    //const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idrol, setIdrol] = useState(user.idRol);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        //setIdempresa(user.companyId);
        //setIdusuario(user.userId);
        setIdrol(user.idRol);
    }, [user]);
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };
    const sidebarWidth = collapsed ? 70 : 220;
    const sidebarAlign = collapsed ? 'center' : 'end';
    //Opcion seleccionada
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(null);
    useEffect(() => {
        // Obtener la última parte de la ruta (nombre del componente)
        const currentPath = location.pathname.split('/').pop();
        setSelectedKey(currentPath);
    }, [location]);
    // OPCIONES
    const [items, setItems] = useState([]);
    useEffect(() => {
        // Define los items dependiendo del valor de Idrol
        if (Idrol === 1) {
            setItems([
                getItem('Alojamientos', 'sub1', <FolderOutlined />, [
                    getItem('Recepcion', '4', null, null, null, '/AlquileresList'),
                    getItem('Alquileres', '3', null, null, null, '/AlquileresHistorial'),
                ]),
                getItem('Administracion', 'sub2', <FolderOutlined />, [
                    getItem('Habitaciones', '5', null, null, null, '/HabitacionesList'),
                    getItem('Categorias', '6', null, null, null, '/CategoriasList'),
                    getItem('Niveles', '7', null, null, null, '/NivelesList'),
                    getItem('Tarifas', '8', null, null, null, '/TarifasList'),
                    getItem('Clientes', '9', null, null, null, '/ClientesList'),
                ]),
                getItem('Inventarios', 'sub3', <FolderOutlined />, [
                    getItem('Entradas', '10', null, null, null, '/EntradasList'),
                    getItem('Salidas', '11', null, null, null, '/SalidasList'),
                    getItem('Movimientos', '12', null, null, null, '/MovimientosList'),
                    getItem('Stocks', '13', null, null, null, '/StocksList'),
                    getItem('Proveedores', '15', null, null, null, '/ProveedoresList'),
                    getItem('Items', '16', null, null, null, '/ItemsList'),
                    getItem('Categorias de Item', '14', null, null, null, '/InventariosList'),
                    getItem('Almacenes', '20', null, null, null, '/AlmacenesList'),
                ]),
                getItem('Ventas', 'sub5', <FolderOutlined />, [
                    getItem('Comprobantes', '19', null, null, null, '/ComprobantesList'),
                ]),
                getItem('Tesoreria', 'sub4', <FolderOutlined />, [
                    getItem('Cajas', '17', null, null, null, '/CajasList'),
                    getItem('Cierre de Caja', '18', null, null, null, '/PlanillasList'),
                ]),
            ])
        } else if (Idrol === 2) {
            setItems([
                getItem('Inventarios', 'sub3', <FolderOutlined />, [
                    getItem('Entradas', '10', null, null, null, '/EntradasList'),
                    getItem('Salidas', '11', null, null, null, '/SalidasList'),
                    getItem('Movimientos', '12', null, null, null, '/MovimientosList'),
                    getItem('Stocks', '13', null, null, null, '/StocksList'),
                    getItem('Proveedores', '15', null, null, null, '/ProveedoresList'),
                    getItem('Items', '16', null, null, null, '/ItemsList'),
                    getItem('Categorias de Item', '14', null, null, null, '/InventariosList'),
                    getItem('Almacenes', '20', null, null, null, '/AlmacenesList'),
                ]),
            ])
        } else if (Idrol === 3) {
            setItems([
                getItem('Alojamientos', 'sub1', <FolderOutlined />, [
                    getItem('Recepcion', '4', null, null, null, '/AlquileresList'),
                    getItem('Alquileres', '3', null, null, null, '/AlquileresHistorial'),
                ]),
                getItem('Inventarios', 'sub3', <FolderOutlined />, [
                    getItem('Entradas', '10', null, null, null, '/EntradasList'),
                    getItem('Movimientos', '12', null, null, null, '/MovimientosList'),
                    getItem('Stocks', '13', null, null, null, '/StocksList'),
                ]),
                getItem('Administracion', 'sub2', <FolderOutlined />, [
                    getItem('Habitaciones', '5', null, null, null, '/HabitacionesList'),
                    getItem('Categorias', '6', null, null, null, '/CategoriasList'),
                    getItem('Niveles', '7', null, null, null, '/NivelesList'),
                    getItem('Tarifas', '8', null, null, null, '/TarifasList'),
                    getItem('Clientes', '9', null, null, null, '/ClientesList'),
                ]),
                getItem('Ventas', 'sub5', <FolderOutlined />, [
                    getItem('Comprobantes', '19', null, null, null, '/ComprobantesList'),
                ]),
                getItem('Tesoreria', 'sub4', <FolderOutlined />, [
                    getItem('Cierre de Caja', '18', null, null, null, '/PlanillasList'),
                ]),
            ])
        }
    }, [Idrol]);
    // const items = [
    //     // getItem('Alquileres', '1', <HomeOutlined />, null, null, '/AlquileresList'),
    //     getItem('Alojamientos', 'sub1', <FolderOutlined />, [
    //         getItem('Recepcion', '4', null, null, null, '/AlquileresList'),
    //         getItem('Alquileres', '3', null, null, null, '/AlquileresHistorial'),
    //     ]),
    //     getItem('Administracion', 'sub2', <FolderOutlined />, [
    //         getItem('Habitaciones', '5', null, null, null, '/HabitacionesList'),
    //         getItem('Categorias', '6', null, null, null, '/CategoriasList'),
    //         getItem('Niveles', '7', null, null, null, '/NivelesList'),
    //         getItem('Tarifas', '8', null, null, null, '/TarifasList'),
    //         getItem('Clientes', '9', null, null, null, '/ClientesList'),
    //     ]),
    //     getItem('Inventarios', 'sub3', <FolderOutlined />, [
    //         getItem('Entradas', '10', null, null, null, '/EntradasList'),
    //         getItem('Salidas', '11', null, null, null, '/SalidasList'),
    //         getItem('Movimientos', '12', null, null, null, '/MovimientosList'),
    //         getItem('Stocks', '13', null, null, null, '/StocksList'),
    //         getItem('Proveedores', '15', null, null, null, '/ProveedoresList'),
    //         getItem('Items', '16', null, null, null, '/ItemsList'),
    //         getItem('Categorias de Item', '14', null, null, null, '/InventariosList'),
    //     ]),
    //     getItem('Ventas', 'sub5', <FolderOutlined />, [
    //         getItem('Comprobantes', '19', null, null, null, '/ComprobantesList'),
    //     ]),
    //     getItem('Tesoreria', 'sub4', <FolderOutlined />, [
    //         getItem('Cajas', '17', null, null, null, '/CajasList'),
    //         getItem('Cierre de Caja', '18', null, null, null, '/PlanillasList'),
    //     ]),
    // ];
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //CERRAR
    const navigate = useNavigate();
    const FnCerrarSesion = async() => {
        const token = localStorage.getItem('token-dincors');
        try {
            //const response = await fetch(`${url}/auth/me`, {
            const response = await fetch(`${url}/Sesion/CerrarSesion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}` // Enviar el token en el encabezado de autorización
                },
                body: JSON.stringify({}),
            });
            if (response.ok) {
                const data = await response.json();
                if(data.res){
                    localStorage.removeItem('token-dincors');
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/Login`);
                }else{
                    throw new Error(data.msg);
                }
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    return (
        <div style={{ width: 220, display: 'flex', flexDirection: 'column', height: '100vh', position: 'fixed', 
                      top: '56px', zIndex: collapsed ? '0' : '10', transition: `z-index 0.6s ease`}}>
            <Button type="second" onClick={toggleCollapsed}
              style={{ marginBottom: 0, width: sidebarWidth, background: '#F0F1F0', color: 'black', borderRadius: 0, height: '49px', textAlign: sidebarAlign }}
            >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            {/* onSelect={handleMenuSelect} selectedKeys={selectedKeys} */}
            <Menu mode="inline" theme="light" inlineCollapsed={collapsed} 
                style={{ background: '#F0F1F0', flex: 1, height: '100%', width: sidebarWidth }}>
                {items.map(item => (
                    (item.children && item.children.length > 0) ? (
                        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}
                        className={item.children.some(child => selectedKey === child.path.split('/').pop()) ? 'ant-menu-submenu-selected' : ''}>
                            {item.children.map(childItem => (
                                <Menu.Item key={childItem.key} icon={childItem.icon}
                                className={selectedKey === childItem.path.split('/').pop() ? 'ant-menu-item-selected' : ''}>

                                    {childItem.path ? <Link to={childItem.path} style={{textDecoration:'none'}}>{childItem.label}</Link> : childItem.label}
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    ) : (
                        <Menu.Item key={item.key} icon={item.icon}
                        className={selectedKey === item.path.split('/').pop() ? 'ant-menu-item-selected' : ''}>

                            {item.path ? <Link to={item.path} style={{textDecoration:'none'}}>{item.label}</Link> : item.label}
                        </Menu.Item>
                    )
                ))}
                {/* Opción para cerrar sesión */}
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                    <Link onClick={FnCerrarSesion} style={{ textDecoration: 'none' }}>
                        Cerrar Sesión
                    </Link>
                </Menu.Item>
            </Menu>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000}
                autohide className='text-white' style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Sidebar;
