function InputCheckBox(props) {
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} l4 mb-3`}>
            {props.campo !== "" && (
            <label htmlFor={`txt${props.name}`} className="form-label">
                {props.campo}<span className="text-danger">{props.obligatorio}</span>
            </label>
            )}
            <div className="form-check form-switch mt-2">
                <input id={`txt${props.name}`} className="form-check-input" type="checkbox" onChange={(e) => props.onChange(e.target.checked)}/>
                <label className="form-check-label" >{props.mensaje}</label>
            </div>
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>

    );
  }
  
export {InputCheckBox};