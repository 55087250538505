import { useState, useEffect } from "react";

function InputComboBoxComprobante(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '03', nombre: 'BOLETA'},
            { id: '01', nombre: 'FACTURA'},
            { id: '42', nombre: 'TICKET'},
        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const IdValue = e.target.value;
        const dataValue = ListarDatos.find((dato) => dato.id === IdValue);
        props.onChange(IdValue, dataValue ? dataValue.nombre : "");
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <div className="row">
                <label htmlFor={`cbx${props.name}`} className="col-3 col-form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className='col-9'>
                    <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                        <option value="">- {props.campo} -</option>
                        {ListarDatos.map((dato) => (
                            <option key={dato.id} value={dato.id}>
                                {dato.nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export {InputComboBoxComprobante};