import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, forwardRef, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

function ListadoViewCrudMovimiento(props) {
  const { url, tabla, idprimary, detalle } = props; // cuerpoTable
  //Redux
  const user = useSelector((state) => state.user);
  const [Idempresa, setIdempresa] = useState(user.companyId);
  // Función para actualizar el usuario en el estado local cuando cambie en Redux
  useEffect(() => {
      setIdempresa(user.companyId);
  }, [user]);
  const [loading, setLoading]=useState(false);
  const [loadingPag, setLoadingPag] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData]=useState([]);
  const [page, setPage]=useState(0);//Controlar la posición actual de la página
  const [count, setCount]=useState(0);//controlar el cambio del total de páginas
  const [form, setForm]=useState({itemnom:'', fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
  const [secondForm, setSecondForm]=useState({itemnom:'', fechaInicial:'', fechaFinal:'', page:0, buscar:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
  //const Idempresa=3;
  useEffect(()=>{
    if(form.buscar){
        searchData(form);
    }
    // eslint-disable-next-line
  },[form,user.companyId]);

  const searchData= async (data)=>{
      setLoading(true);
      try {
          const jsonData = {
              idempresa:user.companyId,
              itemnom:data.itemnom,
              fechaInicial:data.fechaInicial,
              fechaFinal:data.fechaFinal,
              pagina:data.page
          };
          console.log("jsonmov: ",jsonData)
          const token = localStorage.getItem('token-dincors');
          const response = await fetch(`${url}/${tabla}/${tabla}Pages`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${token}`
              },
              body:JSON.stringify(jsonData) /* empresa:user.companyId,  */
          });
          if(response.ok){                
              const data = await response.json();
              console.log("mov: ",data)
              if(data.res){
                  setData(data.datos);
                  setPage(page+data.pag);
                  setCount(data.pag);
                  setSecondForm({
                      ...secondForm,
                      fechaInicial: '',
                      fechaFinal: ''
                  });
              }else{
                  setCount(0);
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
      }catch (error) {
          setToastBackground('#CC6868');
          setToastMessage(error.message);
          setShowToast(true);
      }finally{
          setLoading(false);
      }
  }

  const searchForm=(form)=>{
      setForm(form);
      setPage(0);
  }

  const firstPage=()=>{
      setForm({...form, page: 0});
      setPage(0);
  }

  const nextPage=()=>{
      setLoadingPag(true);
      setForm({...form, page: page});
      setTimeout(() => {
        setLoadingPag(false);
      }, 600);
  };

  const handleSearch=()=>{
    setLoadingSearch(true);
    //firstPage();
    if(page===0 && count===0){
      searchData(secondForm);
    }else{
      searchForm(secondForm);     
    }
    //searchForm(secondForm);     
    setTimeout(() => {
      setLoadingSearch(false);
    }, 1000);    
  };

  const handleChange=(e)=>{
    setSecondForm({
        ...secondForm,
        [e.target.name]:e.target.value
    });
  };

  const handleInitialDateChange = (newDate) => {
    setSecondForm({
        ...secondForm,
        fechaInicial: newDate ? newDate : ''
    });
  };

  const handleFinalDateChange = (newDate) => {
      setSecondForm({
          ...secondForm,
          fechaFinal: newDate ? newDate: ''
      });
  };

  // Exportar a Excel
  const handleExportToExcel = async () => {
    try{
      const jsonData = {
        idempresa: Idempresa,
      }
      const token = localStorage.getItem('token-dincors');
      const response = await fetch(`${url}/${tabla}/${tabla}ExportExcel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(jsonData),
      });
      let data;
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          const blobData = await response.blob(); // Espera a que se resuelva la promesa
          data = blobData;
        } else {
          data = await response.json(); // Espera a que se resuelva la promesa
        }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
      if (data instanceof Blob) {
        // Manejar el archivo descargado
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Movimientos.xlsx';    // <<<<<< CAMBIO
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setToastBackground('#198754');
        setToastMessage('Exportacion generada con exito'); // data.msg
        setShowToast(true);
      } else {
        throw new Error(`Error al realizar la exportacion`);
      }
    }catch (error) {
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    }
  };
  
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#81D075');
  // ====================== MODAL AGREGAR ======================  
  // const [ShowAgregar, setShowAgregar] = useState(false);
  // const CloseAgregarModal = () => setShowAgregar(false);
  // const handleShowAgregar = () => {
  //   setShowAgregar(true);
  // };
  // // Función de agregar
  // const FnGuardarModal = async() => {
  //   var mensaje='';
  //   if(Itemcant.trim() !== '' && Itemcprapu.trim() !== '' && Itemobs.trim() !== '' && Operacioncod.trim() !== '' && 
  //   Idalmacen.toString().trim() !== '' && Iditem.toString().trim() !== '')
  //   {
  //       const now = new Date();
  //       const jsonData = {
  //           Idalmacen: Idalmacen,
  //           Iditem: Iditem,
  //           Itemcant: Itemcant,
  //           Itemcprapu: Itemcprapu,
  //           Itemobs: Itemobs,
  //           Operacioncod: Operacioncod,
  //           Idempresa: Idempresa,
  //           Fechreg: now.toISOString(),
  //           Fechact: now.toISOString(),
  //           Codusureg: Idusuario,
  //           Codusuact: Idusuario,
  //       };
  //       console.log("jsonentrada: ",jsonData)
  //       const token = localStorage.getItem('token-dincors');
  //       try{
  //         const response = await fetch(`${url}/${tabla}/${tabla}Create`, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json; charset=UTF-8',
  //             'Authorization': `Bearer ${token}`
  //           },
  //           body: JSON.stringify(jsonData),
  //         });
  //         if(response.ok){                
  //           const data = await response.json();
  //           console.log("entrada: ",data)
  //           if(data.res){
  //               setToastBackground('#198754');
  //               setToastMessage(data.msg);
  //               setShowToast(true);
  //               CloseAgregarModal();
  //               setData((prevOrden) => [data.datos, ...prevOrden]);
  //               setPage(0);//Ocultar el botón Primero
  //               setCount(0);//Ocultar el botón Siguiente
  //               FnLimpiarDatos();
  //           }else{
  //               setToastMessage(data.msg);
  //               setShowToast(true);
  //               setToastBackground('#CC6868');
  //           }
  //         }else  {
  //             throw new Error(`${response.status} ${response.statusText}`);
  //         }
  //       }catch (error){
  //         setToastBackground('#CC6868');
  //         setToastMessage(error.message);
  //         setShowToast(true);
  //       }
  //   }else{
  //       mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
  //       setToastMessage(mensaje);
  //       setShowToast(true);
  //       setToastBackground('#CC6868');
  //   }
  // };
  // ====================== MODAL DETALLE ======================
  // Agrega un estado para controlar la visibilidad del modal
  const token2 = localStorage.getItem('token-dincors');
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  const [dataDetalle, setDataDetalle] = useState([]);
  const handleShowDetalle = (itemId) => {
    setShowDetalle(true);
    ListarDatosDetalle(url,tabla,itemId,token2)
    .then(function (data) {
        setDataDetalle(data);
    })
    .catch(function (error) {
    });
  };
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
        {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                  <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                  &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className='row mb-2'>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <label>Item</label>
                      <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="itemnom" onChange={handleChange} value={secondForm.itemnom}/>
                    </div>
                    <div className='col-12 custom-col'>
                      <label><small>Fecha Inicio</small></label>
                      <input type="date" name="fechaInicial" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleInitialDateChange(e.target.value)}/>
                    </div>
                    <div className='col-12 custom-col'>
                      <label><small>Fecha Fin</small></label>
                      <input type="date" name="fechaFinal" className='form-control w-100' style={{paddingRight:'0'}} onChange={(e) => handleFinalDateChange(e.target.value) }/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row '>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      {loadingSearch ? (
                        <>
                          <button className="btn btn-success din-btn-style w-100" disabled>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                          </button>
                        </>
                      ) : (
                        <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                        )}
                    </div>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                    </div>
                    {/* <div className='col-12 custom-col'>
                      <Link><button className="btn btn-primary din-btn-style w-100" onClick={() => handleShowAgregar()}><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Nuevo</button></Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='row d-flex justify-content-end'>
                    <div className='col-12 custom-col-pagination'>
                      <div className='m-1'>
                        <Pagination className='justify-content-end'>
                          {page > 10 && 
                            <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              PRIMERO
                            </Pagination.Prev>}
                          {count === 10 && 
                            <Pagination.Next m={1} onClick={nextPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              {loadingPag ? (
                                <>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </>
                              ) : (
                                'SIGUIENTE'
                              )}
                            </Pagination.Next>}
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className='col-12'>
                  <div className="table" style={{ marginTop: '5px'}} id="table_id">
                    <div className='tbody ' >
                      {loading ? (
                          <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                      ) : (
                        <table className="table table-striped table-bordered dt-responsive din-table-1" style={{ marginTop: '5px' }} >
                          <thead>
                            <tr>
                              <th style={{width:"3%"}}></th>
                              <th style={{width:"5%"}}>ID</th>
                              <th style={{width:"10%"}}>FECHA REGISTRO</th>
                              <th style={{width:"5%"}}>TIPO</th>
                              <th style={{width:"9%"}}>ALMACEN</th>
                              <th style={{width:"16%"}}>ITEM</th>
                              <th style={{width:"7%"}}>ENTRADA</th>
                              <th style={{width:"7%"}}>SALIDA</th>
                              <th style={{width:"7%"}}>EXISTENCIA</th>
                              <th style={{width:"7%"}}>P.U. ENTRADA</th>
                              <th style={{width:"7%"}}>P.U. SALIDA</th>
                              <th style={{width:"6%"}}>SALDO</th>
                              <th style={{width:"7%"}}>USUARIO</th>
                              <th style={{width:"4%"}}>CIERRE<br/>CAJA</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 ? (
                              data.map((item, index) => (
                                <tr key={index}>
                                  <td className='text-center align-middle'>
                                    <MyDropdown tabla={props.tabla} id={item[idprimary]} handleShowDetalle={handleShowDetalle}/>
                                  </td>
                                  <td className='text-center align-middle'>{item.idmovimiento}</td>
                                  <td className='align-middle'>{item.fechreg}</td>
                                  <td className='align-middle'>{item.movimientotipo}</td>
                                  <td className='align-middle'>{item.almacen}</td>
                                  <td className='align-middle'><span style={{color:'#2FAC68'}}>{item.itemnom}</span></td>
                                  <td className='text-center align-middle'>{item.itemcantentrada}</td>
                                  <td className='text-center align-middle'>{item.itemcantsalida}</td>
                                  <td className='text-center align-middle'>{item.existencia}</td>
                                  <td className='text-center align-middle'>{item.itemcpravc}</td>
                                  <td className='text-center align-middle'>{item.itemvtavv}</td>
                                  <td className='text-center align-middle'>{item.saldo}</td>
                                  <td className='text-center align-middle'>{item.usuario}</td>
                                  <td className='text-center align-middle'><span className={`text-black texto-small badge bg-warning`}>{item.idcajaapertura}</span></td>
                                </tr>
                                ))
                              ) : (
                              <tr>
                                <td className='text-center' colSpan={12}>Elija los filtros para encontrar los registros</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                      {/* <div className='tr row' style={{ borderTop: '1px solid #2FAC68'}} key={index} id={`tr_${item[idprimary]}`}>
                        <div className='td td-dropdown d-flex align-items-center justify-content-center' >
                          <MyDropdown tabla={props.tabla} id={item[idprimary]} handleShowDetalle={handleShowDetalle}/>
                        </div>
                        <div className='td td-datos' >
                          <div className='row'>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              ID: <span>{item.idmovimiento}</span><br/>  
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              Tipo: <span>{item.movimientotipo}</span><br/>  
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-2'>
                              Almacen: {item.almacen}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-2'>
                              Item: <span style={{color:'#2FAC68'}}>{item.itemnom}</span><br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              Cantidad: {item.itemcant}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              P.U. Compra: {item.itemcprapu}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              P.U. Venta: {item.itemvtapu}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              Valor Compra: {item.itemcpravc}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              Valor Venta: {item.itemvtavv}<br/> 
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-lg-1'>
                              Registro del Sistema: {item.fechreg}<br/> 
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          )}
          {/* Modal Agregar */}
          {/* <>
            <Modal show={ShowAgregar} onHide={CloseAgregarModal} size="lg">
              <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                  <Modal.Title>Editar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                    <InputComboBoxAdd2 campo="Almacen" name="Idalmacen" obligatorsio="*" colsm="12" colmd="12" url={url} onChange={setIdalmacen} idempresa={Idempresa}/> 
                    <InputComboBoxBusquedaItemAdd campo="Item" name="Iditem2" obligatorio="*" idempresa={Idempresa} colsm="12" colmd="12" url={url} onChange={(selectedValue) => {setIditem(selectedValue);}}/>
                    <InputTextAdd campo="Cantidad" name="Itemcant" obligatorio="*" colsm="12" colmd="6" onChange={setItemcant}/>               
                    <InputTextAdd campo="Precio Unitario" name="Itemcprapu" obligatorio="*" colsm="12" colmd="6" onChange={setItemcprapu}/>               
                    <InputTextAdd campo="Motivo" name="Itemobs" obligatorio="*" colsm="12" colmd="6" onChange={setItemobs}/>               
                    <InputTextAdd campo="Codigo Operacion" name="Operacioncod" obligatorio="*" colsm="12" colmd="6" onChange={setOperacioncod}/> 
                </div>
              </Modal.Body>
              <Modal.Footer>
                    <Button variant="secondary" onClick={CloseAgregarModal}>Cerrar</Button>
                    <Button variant="success" onClick={FnGuardarModal}>Guardar</Button>
              </Modal.Footer>
            </Modal>
          </> */}
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title >Detalle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="m-3">
                  {/* CARD BODY */}
                  <div className="mx-2">
                      <div className="row">
                      {detalle.map((header, headerIndex) => (
                        <div key={headerIndex} className="col-12">
                          <div className='row'>
                            <div className='col-5 col-sm-5 col-md-3' style={{ textAlign: 'right', fontWeight: '500' }}>
                              {header.name}:&nbsp;&nbsp;
                            </div>
                            <div className='col-7 col-sm-7 col-md-9'>
                              {dataDetalle[header.bdname]}
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
                  </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
              {/* <Link to={`/${tabla}Upd/${selectedIdDetalle}`}>
                <button className="btn btn-success din-btn-style">
                  <i className="fa-solid fa-pen"></i>&nbsp;&nbsp;Editar
                </button>
              </Link> */}
            </Modal.Footer>
          </Modal>
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}

// Define la función CustomDropdownToggle utilizando forwardRef
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});

// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShowDetalle} = props;
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
       <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
        <Dropdown.Item onClick={() => handleShowDetalle(props.id)}>
          <i className="fas fa-search"></i> Detalle
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
// Datos de detalle
async function ListarDatosDetalle(url, tabla, id, token2) {
    try{
      const response = await fetch(`${url}/${tabla}/${tabla}Detail/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token2}`
          },
          credentials: 'include',
      })
      if(response.ok) {
          const data = await response.json();
          if(data.res){
              // const datos = {
              //     ...data.datos,
              //     fechact: data.datos.fechact.replace('T', ' '),
              //     fechreg: data.datos.fechreg.replace('T', ' ')
              // };
              return data.datos;
          }else{
              throw new Error(data.msg);
          }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
    }catch (error){
        throw error;
    }
}

export {ListadoViewCrudMovimiento};