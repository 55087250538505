import { ListadoViewCrudStock } from "../../componentes/listado/ListadoViewCrudStock";
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import API_BASE_URL  from "../../config";

const nombre_controller = 'Stocks';            // <<<<<< CAMBIO
const idprimary = 'idstock';                   // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
// Rutas
const RutaHead = [
    { name: 'Stock', ruta: `/${nombre_controller}List`},   // <<<<<< CAMBIO
];
// Detalle
const Detalle = [
    { name: 'Codigo', bdname: 'idstock'},           // <<<<<< CAMBIO
    { name: 'Almacen', bdname: 'almacen'},          // <<<<<< CAMBIO
    { name: 'Item', bdname: 'itemnom' },            // <<<<<< CAMBIO
    { name: 'Stock', bdname: 'itemstock' },         // <<<<<< CAMBIO
    { name: 'P.U. Compra', bdname: 'itemcprapu' },  // <<<<<< CAMBIO
    { name: 'P.U. Venta', bdname: 'itemvtapu' },    // <<<<<< CAMBIO
    { name: 'Cantidad Entrada', bdname: 'itemcantentrada' }, // <<<<<< CAMBIO 
    { name: 'Cantidad Salida', bdname: 'itemcantsalida' },   // <<<<<< CAMBIO 
    { name: 'Inversion', bdname: 'iteminversion' },   // <<<<<< CAMBIO 
    { name: 'Usuario', bdname: 'usuario' },           // <<<<<< CAMBIO
    { name: 'Fecha Registro', bdname: 'fechreg' },    // <<<<<< CAMBIO
];
function StockList() {      // <<<<<< CAMBIO
    return (
        <>
            <HeadView/>
            <Sidebar />
            <div className="home-section">
                <RouterView RutaHead={RutaHead}></RouterView>
                <div>
                    <section>
                        <ListadoViewCrudStock url={url} tabla={nombre_controller} idprimary={idprimary} detalle={Detalle} />  
                    </section>
                </div>
                <FooterView/>
            </div>
        </>
    );
}
export default StockList;   // <<<<<< CAMBIO

