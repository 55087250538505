// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { InputText } from "../../componentes/agregar/InputText";
import { InputComboBoxTxtunidadmedida } from "../../componentes/agregar/item/InputComboBoxTxtunidadmedida";
//import { InputComboBoxTipo } from "../../componentes/agregar/item/InputComboBoxTipo";
import { InputComboBoxTxtestado } from "../../componentes/agregar/item/InputComboBoxTxtestado";
import { InputComboBoxCategoria } from "../../componentes/agregar/item/InputComboBoxCategoria";
import { InputComboBoxN2TextId } from "../../componentes/agregar/InputComboBoxN2TextId";
import { Link, useNavigate } from "react-router-dom";
//import { Link, json, useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";
const nombre_controller = 'Items';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Item', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Registro Item', ruta: `/${nombre_controller}Add` },  // <<<<<< CAMBIO
];
function ItemAdd() {
    const navigate = useNavigate();
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    const [Itemcodigo, setItemcodigo] = useState('');            //varchar       // <<<<<< CAMBIO
    const [Itemdesc, setItemdesc] = useState('');                //text   // <<<<<< CAMBIO
    const [Txtunidadmedida, setTxtunidadmedida] = useState('');  //int // <<<<<< CAMBIO
    const [Txtmediso, setTxtmediso] = useState('');              //varchar     // <<<<<< CAMBIO
    const [Itemcprapu, setItemcprapu] = useState('');     //decimal // <<<<<< CAMBIO
    const [Itemvtapu, setItemvtapu] = useState('');       //decimal // <<<<<< CAMBIO
    const [Txtestado, setTxtestado] = useState('');              //int // <<<<<< CAMBIO
    const [Idcategoria, setIdcategoria] = useState('');              //int // <<<<<< CAMBIO
    const [Idsubcategoria, setIdsubcategoria] = useState('');              //int // <<<<<< CAMBIO
    // eslint-disable-next-line no-unused-vars
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    // limpiar
    const ClearComboBox = () => {
        setIdsubcategoria(null)
    }
    const FnGuardar = async () => {
        let mensaje='';
        if(Itemcodigo.trim() !== '' && Itemdesc.trim() !== '' && Txtunidadmedida !=='' && Txtestado !== '' ){ // 
            const jsonData = {
                Itemcodigo: Itemcodigo,         // <<<<<< CAMBIO
                Itemdesc: Itemdesc,         // <<<<<< CAMBIOv
                Txtunidadmedida: Txtunidadmedida, // <<<<<< CAMBIO
                Txtmediso: Txtmediso,         // <<<<<< CAMBIO
                Itemcprapu: Itemcprapu,       // <<<<<< CAMBIO
                Itemvtapu: Itemvtapu,     // <<<<<< CAMBIO
                Txtestado: Txtestado,     // <<<<<< CAMBIO
                Idcategoria: Idcategoria,       // <<<<<< CAMBIO
                Idsubcategoria: Idsubcategoria, // <<<<<< CAMBIO
                DatosAlmacen: SelectedAlmacen,
                Idempresa: Idempresa,       // <<<<<< CAMBIO
                Codusureg: Idusuario,
                Codusuact: Idusuario,
            };
            try {
                const token = localStorage.getItem('token-dincors');
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){                
                    const data = await response.json();
                    if(data.res){
                        localStorage.setItem('toastMessage1', data.msg);
                        localStorage.setItem('toastBackground1', '#198754');
                        navigate(`/${nombre_controller}List`);
                    }else{
                        throw new Error(data.msg);
                    }
                }else  {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error){
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    // almacenes seleccionados
    const [DatosAlmacen, setDatosAlmacen] = useState([]);
    const [SelectedAlmacen, setSelectedAlmacen] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}/ComboBox/IdalmacenCombo/${Idempresa}`, {
                    method: 'POST',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                const data = await response.json();
                console.log(data)
                if(data.res==='200'){
                    setDatosAlmacen(data.datos);
                }
            } catch (error) {
                //console.log(error);
                throw error;
            }
        };
        fetchData();
    }, [Idempresa]);
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Datos Generales</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputComboBoxTxtestado campo="Estado" name="Txtestado" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtestado}/>  {/* <<<<<< CAMBIO */}
                                <InputText campo="Codigo" name="Itemcodigo" obligatorio="*" colsm="6" colmd="4" onChange={setItemcodigo}/>       {/* <<<<<< CAMBIO */}
                                <InputText campo="Descripcion" name="Itemdesc" obligatorio="*" colsm="6" colmd="4" onChange={setItemdesc}/>      {/* <<<<<< CAMBIO */}
                                {/* Combobox de Categoria */}
                                <InputComboBoxCategoria campo="Categoria" name="Idcategoria3" obligatorio="*" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIdcategoria(selectedValue);ClearComboBox();}} idempresa={Idempresa}/>   {/* <<<<<< CAMBIO */}
                                <InputComboBoxN2TextId campo="Subcategoria" name="Idsubcategoria" obligatorio="*" colsm="6" colmd="4" url={url} 
                                onChange={(selectedValue, selectedText) => {setIdsubcategoria(selectedValue);}} idnivel={Idcategoria}/>  {/* <<<<<< CAMBIO */}
                                {/* Combobox de Sub-Categoria */}                                
                                <InputComboBoxTxtunidadmedida campo="Unidad Medida" name="Txtunidadmedida" obligatorio="*" colsm="6" colmd="4" url={url}  
                                onChange={(Txtunidadmedida, Txtmediso) => {setTxtunidadmedida(Txtunidadmedida);setTxtmediso(Txtmediso);}} />
                                {/* <InputComboBoxTipo campo="Tipo" name="Idtipoitem" obligatorio="*" colsm="6" colmd="4" url={url} 
                                onChange={(Idtipoitem, Tiponombre) => {setIdtipoitem(Idtipoitem);setTiponombre(Tiponombre);}} /> 
                                <InputText campo="Stock Minimo" name="Stockmin" obligatorio="" colsm="6" colmd="4" onChange={setStockmin}/>  */}
                                <InputText campo="Precio Compra" name="Itemcprapu" obligatorio="" colsm="6" colmd="4" onChange={setItemcprapu}/>   {/* <<<<<< CAMBIO */}
                                <InputText campo="Precio Venta" name="Itemvtapu" obligatorio="" colsm="6" colmd="4" onChange={setItemvtapu}/>      {/* <<<<<< CAMBIO */}
                            </div>
                        </div>
                    </div>
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Almacenes disponibles</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {DatosAlmacen.map((dato) => (
                                    <div key={dato.id} className="col-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id={dato.id} value={dato.id} onChange={(e) => {
                                                    const valorSeleccionado = e.target.value;
                                                    if (e.target.checked) {
                                                        setSelectedAlmacen((prevSeleccionados) => [
                                                            ...prevSeleccionados,
                                                            valorSeleccionado,
                                                        ]);
                                                    } else {
                                                        setSelectedAlmacen((prevSeleccionados) =>
                                                            prevSeleccionados.filter((val) => val !== valorSeleccionado)
                                                        );
                                                    }
                                                }}
                                            />
                                            {/* <input className="form-check-input" type="checkbox" id={dato.id} value={dato.id}/> */}
                                            <label className="form-check-label" htmlFor={dato.id}>{dato.nombre}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    <Link onClick={FnGuardar} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                    </Link>
                                    <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                    </Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        </>
    );
}

export default ItemAdd;    /* <<<<<< CAMBIO */

