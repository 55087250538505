import { useState, useEffect } from "react";

function InputComboBoxIncidencia(props) {
    const [ListarDatos, setListaComboBox] = useState([]);
    useEffect(() => {
        const data = [
            { id: '1', nombre: 'Mantenimiento'},
            { id: '2', nombre: 'Perdidas o Daños'},
            { id: '3', nombre: 'Quejas de servicio'},
            { id: '4', nombre: 'Seguridad y Emergencias'},

        ];
        setListaComboBox(data);
    },[]);
    const handleSelectChange = (e) => {
        const TextValue = e.target.value;
        const dataValue = ListarDatos.find((dato) => dato.nombre === TextValue);
        props.onChange(dataValue ? dataValue.id : "", TextValue);
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <div className="row">
                <label htmlFor={`cbx${props.name}`} className="col-3 col-form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
                <div className='col-9'>
                    <select className="form-select" id={`cbx${props.name}`} onChange={handleSelectChange} value={props.value || ""}>
                        <option value="">- {props.campo} -</option>
                        {ListarDatos.map((dato) => (
                            <option key={dato.id} value={dato.nombre}>
                                {dato.nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export {InputComboBoxIncidencia};